export interface ProductFilterPanelProps {
    productPanel?: ProductPanelProps
}

export interface DropdownFieldProps {
    showField?: boolean,
    isAddAllOptions?: boolean,
    defaultValues?: number[],
    defaultValue?: number
}

export interface ProductPanelProps {
    agent?: DropdownFieldProps,
    financialYear?: DropdownFieldProps,
}

const defaultDropdownField: DropdownFieldProps = {
    showField: false,
    isAddAllOptions: false,
    defaultValue: null,
    defaultValues: null
}

// Default for ProductFilterPanelProps
export function defaultProductFilterPanelProps(): ProductFilterPanelProps {
    return {
        productPanel: {
            agent: { ...defaultDropdownField },
            financialYear: { ...defaultDropdownField }
        },
    };
}