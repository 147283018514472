import { RouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import BoxedSidebarLayout from 'src/layouts/BoxedSidebarLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';
import BottomNavigationLayout from 'src/layouts/BottomNavigationLayout';
import TopNavigationLayout from 'src/layouts/TopNavigationLayout';

import dashboardsRoutes from './dashboards';
import blocksRoutes from './blocks';
import applicationsRoutes from './applications';
import managementRoutes from './management';
import documentationRoutes from './documentation';
import accountRoutes from './account';
import baseRoutes from './base';
import agentsRoutes from './agents';
import suppliersRoutes from './suppliers';
import contactsRoutes from './contacts';
import contractsRoutes from './contracts';
import reportsRoutes from './reports';
import productsRoutes from './products';
import ems_dashboardsRoutes from './ems_dashboards';
import LoginBasic from 'src/content/pages/Auth/Login/Basic';
import ForgotPassword from 'src/ems/components/authentication/ForgotPassword';
import adminRoutes from './admin';

const router: RouteObject[] = [
  // {
  //   path: 'account',
  //   children: accountRoutes
  // },
  // {
  //   path: '',
  //   element: <BaseLayout />,
  //   children: baseRoutes
  // },

  // Documentation

  // {
  //   path: 'docs',
  //   element: <DocsLayout />,
  //   children: documentationRoutes
  // },

  // Boxed Sidebar Layout

  // {
  //   path: 'boxed-sidebar',
  //   element: (
  //     <Authenticated>
  //       <BoxedSidebarLayout />
  //     </Authenticated>
  //   ),
  //   children: [
  //     {
  //       path: '',
  //       element: <Navigate to="dashboards" replace />
  //     },
  //     {
  //       path: 'dashboards',
  //       children: dashboardsRoutes
  //     },
  //     {
  //       path: 'blocks',
  //       children: blocksRoutes
  //     },
  //     {
  //       path: 'applications',
  //       children: applicationsRoutes
  //     },
  //     {
  //       path: 'management',
  //       children: managementRoutes
  //     }
  //   ]
  // },

  // Accent Sidebar Layout
  {
    path: '',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    )
  },
  {
    path: '/forgot-password',
    element: (
      <ForgotPassword />
    )
  },
  {
    path: 'accent-sidebar',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="dashboards" replace />
      // },
      {
        path: 'ems_dashboards',
        children: ems_dashboardsRoutes
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'agent',
        children: agentsRoutes
      },
      {
        path: 'supplier',
        children: suppliersRoutes
      },
      {
        path: 'contact',
        children: contactsRoutes
      },
      {
        path: 'contracts',
        children: contractsRoutes
      },
      {
        path: 'reports',
        children: reportsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      },
      {
        path: 'products',
        children: productsRoutes
      },
      {
        path: 'admin',
        children: adminRoutes
      }
    ]
  }

  // Collapsed Sidebar Layout

  // {
  //   path: 'collapsed-sidebar',
  //   element: (
  //     <Authenticated>
  //       <CollapsedSidebarLayout />
  //     </Authenticated>
  //   ),
  //   children: [
  //     {
  //       path: '',
  //       element: <Navigate to="dashboards" replace />
  //     },
  //     {
  //       path: 'dashboards',
  //       children: dashboardsRoutes
  //     },
  //     {
  //       path: 'blocks',
  //       children: blocksRoutes
  //     },
  //     {
  //       path: 'applications',
  //       children: applicationsRoutes
  //     },
  //     {
  //       path: 'management',
  //       children: managementRoutes
  //     }
  //   ]
  // },

  // // Bottom Navigation Layout

  // {
  //   path: 'bottom-navigation',
  //   element: (
  //     <Authenticated>
  //       <BottomNavigationLayout />
  //     </Authenticated>
  //   ),
  //   children: [
  //     {
  //       path: '',
  //       element: <Navigate to="dashboards" replace />
  //     },
  //     {
  //       path: 'dashboards',
  //       children: dashboardsRoutes
  //     },
  //     {
  //       path: 'blocks',
  //       children: blocksRoutes
  //     },
  //     {
  //       path: 'applications',
  //       children: applicationsRoutes
  //     },
  //     {
  //       path: 'management',
  //       children: managementRoutes
  //     }
  //   ]
  // },

  // // Top Navigation Layout

  // {
  //   path: 'top-navigation',
  //   element: (
  //     <Authenticated>
  //       <TopNavigationLayout />
  //     </Authenticated>
  //   ),
  //   children: [
  //     {
  //       path: '',
  //       element: <Navigate to="dashboards" replace />
  //     },
  //     {
  //       path: 'dashboards',
  //       children: dashboardsRoutes
  //     },
  //     {
  //       path: 'blocks',
  //       children: blocksRoutes
  //     },
  //     {
  //       path: 'applications',
  //       children: applicationsRoutes
  //     },
  //     {
  //       path: 'management',
  //       children: managementRoutes
  //     }
  //   ]
  // },

  // // Accent Header Layout

  // {
  //   path: 'accent-header',
  //   element: (
  //     <Authenticated>
  //       <AccentHeaderLayout />
  //     </Authenticated>
  //   ),
  //   children: [
  //     {
  //       path: '',
  //       element: <Navigate to="dashboards" replace />
  //     },
  //     {
  //       path: 'dashboards',
  //       children: dashboardsRoutes
  //     },
  //     {
  //       path: 'blocks',
  //       children: blocksRoutes
  //     },
  //     {
  //       path: 'applications',
  //       children: applicationsRoutes
  //     },
  //     {
  //       path: 'management',
  //       children: managementRoutes
  //     }
  //   ]
  // },

  // // Extended Sidebar Layout

  // {
  //   path: 'extended-sidebar',
  //   element: (
  //     <Authenticated>
  //       <ExtendedSidebarLayout />
  //     </Authenticated>
  //   ),
  //   children: [
  //     {
  //       path: 'dashboards',
  //       children: dashboardsRoutes
  //     },
  //     {
  //       path: 'blocks',
  //       children: blocksRoutes
  //     },
  //     {
  //       path: 'applications',
  //       children: applicationsRoutes
  //     },
  //     {
  //       path: 'management',
  //       children: managementRoutes
  //     }
  //   ]
  // }
];

export default router;
