export enum DateFilter {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Last7Days = 'Last 7 Days',
  ThisWeek = 'This Week',
  LastWeek = 'Last Week',
  Last30Days = 'Last 30 Days',
  ThisMonth = 'This Month',
  LastMonth = 'Last Month',
  ThisYear = 'This Year',
  LastYear = 'Last Year',
  LastFinancialYear = 'Last Financial Year',
  ThisFinancialYear = 'This Financial Year',
  CustomRange = 'Custom Range',
}
