import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import Reference from 'src/ems/models/reference';
import ContactService from 'src/ems/services/contact.service';

interface BusinessManagerState {
  businessManagers: Reference[];
}

const initialState: BusinessManagerState = {
  businessManagers: []
};

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    getBusinessManagers(
      state: BusinessManagerState,
      action: PayloadAction<{ businessManagers: Reference[] }>
    ) {
      const { businessManagers } = action.payload;

      state.businessManagers = businessManagers;
    }
  }
});

export const reducer = slice.reducer;

export const getBusinessManagers =
  (signal?: any): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      let { data } = await ContactService.getBusinessManagers(signal);
      const businessManagers = Array.isArray(data) ? data : [];
      dispatch(slice.actions.getBusinessManagers({ businessManagers }));
    } catch (error) {
      if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
        console.error(error);
      }
    }
  };

export default slice;
