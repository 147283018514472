import axios from 'axios';
import { emsApiConfig } from 'src/config';

axios.interceptors.response.use(
  (response) => response.data,
  (error) => { 
    return Promise.reject(error);
  }
);

export default axios.create({
  baseURL: emsApiConfig.ems_api_url,
  headers: {
    'Content-type': 'application/json',
     //Authorization: `Bearer ${localStorage.getItem('accessToken')}`
  }
});


