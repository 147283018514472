import {
  DashboardFilterPanelProps,
  defaultDashboardFilterPanelProps
} from 'src/ems/components/common/DashboardFilterPanel/Models/DashboardFilterPanelProps';
import DataGridColumnDef from 'src/ems/models/grid_column_def';
import { Tooltip, Link, Chip } from '@mui/material';
import {
  formatNumberWithCommas,
  numberFormat,
  formatToPercentage,
  getLastWeekDay,
  toTitleCase,
  sortCurrencyValues
} from 'src/ems/services/common.service';
import DashboardParameters from 'src/ems/models/dashboard_models/dashboard_parameters';
import { convertArray, getStringPercentageFormula } from 'src/ems/services/report.general.service';
import dashboard_parameters from 'src/ems/models/dashboard_models/dashboard_parameters';
import { getPreference } from 'src/ems/services/contact.preference.service';
import { role } from 'src/ems/enums/roles.enums';
import { product_sales_report_columns } from 'src/ems/models/agent_report_models/product_sales_report_columns';

export function getDashboardFilterPanel (defaultValues: dashboard_parameters): DashboardFilterPanelProps {
  let preferenceData: dashboard_parameters = {};
  //const defaultValues: dashboard_parameters = await getPreference(role.RPT_GENERAL_PRODUCT_SALES, isDefault);

  if (defaultValues !== null) {
      preferenceData = defaultValues;
  }

  const reportFilterPanel: DashboardFilterPanelProps =
    defaultDashboardFilterPanelProps();

  const lastWeekStartDate = getLastWeekDay(false);
  const lastWeekEndDate = getLastWeekDay(true);

  reportFilterPanel.dashboardOptionPanel.network = {
    ...reportFilterPanel.dashboardOptionPanel.network,
    showField: true,
    isAddAllOptions: true,
    defaultValues: convertArray(preferenceData.networks)
  };
  reportFilterPanel.dashboardOptionPanel.state = {
    ...reportFilterPanel.dashboardOptionPanel.state,
    showField: true,
    isAddAllOptions: true,
    defaultValues: convertArray(preferenceData.states)
  };
  reportFilterPanel.dashboardOptionPanel.businessManager = {
    ...reportFilterPanel.dashboardOptionPanel.businessManager,
    showField: true,
    isAddAllOptions: true,
    defaultValues: convertArray(preferenceData.businessManager)
  };
  reportFilterPanel.dashboardOptionPanel.agent = {
    ...reportFilterPanel.dashboardOptionPanel.agent,
    showField: true,
    isAddAllOptions: true,
    defaultValues: convertArray(preferenceData.agents)
  };

  reportFilterPanel.dashboardOptionPanel.supplier = {
    ...reportFilterPanel.dashboardOptionPanel.supplier,
    showField: true,
    isAddAllOptions: true,
    defaultValues: convertArray(preferenceData.supplier)
  };

  reportFilterPanel.dashboardOptionPanel.product = {
    ...reportFilterPanel.dashboardOptionPanel.product,
    showField: true,
    isAddAllOptions: true,
    defaultValues: convertArray(preferenceData.products)
  };

  reportFilterPanel.dashboardOptionPanel.groupByType = {
    ...reportFilterPanel.dashboardOptionPanel.groupByType,
    showField: true,
    isAddAllOptions: false
  };

  reportFilterPanel.dashboardOptionPanel.showActiveSupplierCheck = true

  reportFilterPanel.datePanel.dateFrom = {
    ...reportFilterPanel.datePanel.dateFrom,
    showField: true,
    label: 'Date From',
    name: 'dateFrom',
    defaultValue: lastWeekStartDate
  };
  reportFilterPanel.datePanel.dateTo = {
    ...reportFilterPanel.datePanel.dateTo,
    showField: true,
    label: 'Date To',
    name: 'dateTo',
    defaultValue: lastWeekEndDate
  };
  reportFilterPanel.datePanel.dateFilter = { showField: true, defaultValue: preferenceData.dateFilter }

  return reportFilterPanel;
}

export function getReportMeasures(columnPrefrence: product_sales_report_columns) {
  let measures = [];

  measures.push({
      uniqueName: "supplierName",
      caption: `Supplier`,
      active: columnPrefrence.supplier_name
  });

  measures.push({
      uniqueName: "product",
      caption: `Product Name`,
      active: columnPrefrence.product_name
  });

  measures.push({
      uniqueName: "state",
      caption: `State`,
      active: columnPrefrence.state
  });

  measures.push({
    uniqueName: "agency",
    caption: `Agency`,
    active: columnPrefrence.agency
  });

  measures.push({
    uniqueName: "network",
    caption: `Network`,
    active: columnPrefrence.network
  });

  measures.push({
    uniqueName: "businessManager",
    caption: `Business Manager`,
    active: columnPrefrence.business_manager
  });

  measures.push({
      uniqueName: "salesDollarGrowth",
      format: "salesFormat",
      caption: `SALES GROWTH`,
      active: columnPrefrence.sales_growth
  });

  measures.push({
      uniqueName: "period",
      caption: `Period`,
      active: columnPrefrence.period
  });

  measures.push({
      uniqueName: "eligibleSales_CurrentYear",
      caption: `Eligible Sales`,
      active: columnPrefrence.eligible_sales
  });

  measures.push({
      uniqueName: "eligibleSales_PastYear",
      caption: `Sales (Previous Year)`,
      active: columnPrefrence.sales_previous_year
  });

  measures.push({
      uniqueName: "eligibleSales_GrowthInDollars",
      caption: `Sales (Growth in $)`,
      active: columnPrefrence.sales_growth_in_dollars
  });

  measures.push({
      uniqueName: "eligibleSalesGrowthInPercentage",
      formula: getStringPercentageFormula('eligibleSales_CurrentYear', 'eligibleSales_PastYear'),
      caption: `Sales (Growth in %)`,
      format:"percentFormat",
      active: columnPrefrence.sales_growth_in_percentage
  });

  measures.push({
    uniqueName: "reportedOn",
    caption: `Reported On`,
    active: columnPrefrence.reported_on
});

  return measures
}
