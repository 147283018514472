import React, { useCallback, useState } from 'react';
import { useEffect, useMemo } from 'react';
import { getDropDownReferences } from 'src/ems/slices/referenceDropDown';
import { getAgents } from 'src/ems/slices/agent';
import { useDispatch, useSelector } from 'src/store';
import { useForm } from 'react-hook-form';
import MultiSelectDropdown from 'src/ems/components/rhf-components/MultiSelectDropdown';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import MultiSelectDropDownOptionsProps from 'src/ems/models/multiSelectDropDownOptionsProps';
import { PrivateFaresPanelProps } from '../Models/PrivateFaresFilterPanelProps';
import AgentPrivateFaresService from 'src/ems/services/agent.privatefares.service';
import AirlineDetails from 'src/ems/models/airline_details';
import useRefMounted from 'src/hooks/useRefMounted';
import Input from 'src/ems/components/rhf-components/Input';
import { getAirlinesDetails } from 'src/ems/slices/airlines';
import { getCurrentUser } from 'src/ems/services/auth.service';

export const PrivateFaresPanel: React.FC<PrivateFaresPanelProps> = ({
  headerGroup,
  subGroup,
  agent,
  network,
  airline,
  fareType,
  accountCode,
  tourCode
}) => {
  const isMountedRef = useRefMounted();
  const dispatch = useDispatch(); 
  const { airlines: airlineList } = useSelector((state) => state.airlines);
  const { references } = useSelector((state) => state.referenceDropDown);
  const { agents } = useSelector((state) => state.agent);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(getDropDownReferences(signal));
    dispatch(getAgents(signal));
    dispatch(getAirlinesDetails());
    
    return () => {     
      controller.abort();
    };

  }, [dispatch]);

  const userInfo = getCurrentUser();

  const headerGroupIds = userInfo.primaryGroupSid;
  const groupIds =
    userInfo.groupSid === undefined
      ? references
          .filter(
            (a) =>
              a.parentReferenceId !== null &&
              userInfo.primaryGroupSid.includes(a.parentReferenceId.toString())
          )
          .map((a) => a.value)
      : userInfo.groupSid;
  const subGroupIds = references
    .filter(
      (a) =>
        a.parentReferenceId !== null &&
        groupIds.includes(a.parentReferenceId.toString())
    )
    .map((a) => a.value);

  const distinctNetworkIds = [...new Set(agents.map(obj => obj.networkId).filter(id => id !== null && id !== 0))];

  const subGroupOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter(
          (reference) =>
            subGroupIds.includes(reference.value) &&
            reference.type === 'SUB_GRP'
        )
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const headerGroupOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter(
          (reference) =>
            headerGroupIds.includes(reference.value.toString()) &&
            reference.type === 'HEADER_GRP'
        )
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const agentOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      agents
        .map((agent) => ({ label: agent.name, value: parseInt(agent.entityId, 10)}))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [agents]
  );

  // Getting Options from Reference
  const networkOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
      .filter(reference => distinctNetworkIds.includes(reference.value))
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );
  
  const fareTypeOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter(reference => reference.type == 'TypeofFare')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  if(airlineList === null) return null;

  const airlineOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
    airlineList
        .map((c) => ({ label: c.name + " (" + c.supplierCode + ")", value: c.entityId }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [airlineList]
  );

  return (
    <>
      {/* Agent Panel */}
      {(agent.showField || network.showField) && (
        <Card variant="outlined" sx={{ margin: '10px' }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} margin={1} marginBottom={3}>
              <Typography variant="h4">Private Fares Panel</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            
            {headerGroup.showField && headerGroupOptions !== null ? (
                <Grid item xs={12} md={4} sm={12} lg={3}>
                  <MultiSelectDropdown
                    name="headerGroup"
                    label="Header Group"
                    options={headerGroupOptions}
                    addAllOption={headerGroup.isAddAllOptions}
                    defaultValues={headerGroup.defaultValues}
                  />
                </Grid>
              ) : null}
              {subGroup.showField && subGroupOptions !== null ? (
                <Grid item xs={12} md={4} sm={12} lg={3}>
                  <MultiSelectDropdown
                    name="subGroup"
                    label="Sub Group"
                    options={subGroupOptions}
                    addAllOption={subGroup.isAddAllOptions}
                    defaultValues={subGroup.defaultValues}
                  />
                </Grid>
              ) : null}
            {network.showField && (<Grid item xs={12} md={4} sm={12} lg={3}>
              <MultiSelectDropdown
                name="network"
                label="Network"
                options={networkOptions}
                addAllOption={network.isAddAllOptions}
                defaultValues={network.defaultValues}
              />
            </Grid>)}

            {agent.showField && (<Grid item xs={12} md={4} sm={12} lg={3}>
              <MultiSelectDropdown
                name="agent"
                label="Agent"
                options={agentOptions}
                addAllOption={agent.isAddAllOptions}
                defaultValues={agent.defaultValues}
              />
            </Grid>)}

            {airline.showField && (<Grid item xs={12} md={4} sm={12} lg={3}>
              <MultiSelectDropdown
                name="airline"
                label="Airline"
                options={airlineOptions}
                addAllOption={airline.isAddAllOptions}
                defaultValues={airline.defaultValues}
              />
            </Grid>)}
            {fareType.showField && (<Grid item xs={12} md={4} sm={12} lg={3}>
              <MultiSelectDropdown
                name="fareType"
                label="Fare Type"
                options={fareTypeOptions}
                addAllOption={fareType.isAddAllOptions}
                defaultValues={fareType.defaultValues}
              />
            </Grid>)}  
            {accountCode.showField && (<Grid item xs={12} md={4} sm={12} lg={3}>
              <Input
                  name="accountCode"
                  //control={control}
                  label="Fare Account Code"
                  disabled={false}                  
              /> 
            </Grid>)}   
            {accountCode.showField && (<Grid item xs={12} md={4} sm={12} lg={3}>
              <Input
                  name="tourCode"
                  //control={control}
                  label="Tour Code"
                  disabled={false}                  
              /> 
            </Grid>)}                                              
          </Grid>
        </CardContent>
      </Card>
      )}
      
    </>
  );
};
