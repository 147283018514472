import ContactRefRole from 'src/ems/models/contact_models/contact_refrole';
import http from './http.service';
import { ContactRefRoleCategory } from 'src/ems/models/contact_models/contact_refrole_category';
import { ContactOption } from 'src/ems/models/contact_models/contact_options';

const apiEndpoint = '/contact/role';

export async function getRoles(signal?: any) {
  const response = await http.get<ContactRefRole[]>(
    apiEndpoint + '/roles', {
      signal: signal
    }
  );
  return response;
}

export async function getAssignedRoles(entityId: string) {
  const response = await http.get<ContactRefRoleCategory[]>(
    apiEndpoint + '/contactroles/' + entityId
  );
  return response;
}

export async function getContactOptions() {
  const response = await http.get<ContactOption[]>(
    apiEndpoint + '/contactoptions'
  );
  return response;
}

export async function updateRoles(data: number[], contactId: string) {
  await http.post(apiEndpoint + '/updateroles/' + contactId, data);
}

export default {
  getAssignedRoles,
  getContactOptions,
  updateRoles,
  getRoles
};
