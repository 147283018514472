import { useContext, useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import SidebarTopSection from './SidebarTopSection';

import {
  Box,
  Button,
  Drawer,
  Typography,
  styled,
  useTheme
} from '@mui/material';

import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/LogoSign';
import CustomPopupBox from 'src/ems/components/common/CustomPopupBox';
import VersionList from 'src/ems/models/version_list';
import { getActiveVersionDetail } from 'src/ems/services/admin.version.service';
import { useSnackbar } from 'notistack';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 7;
        height: 100%;
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2, 3)};
`
);

function Sidebar() {
  const { enqueueSnackbar } = useSnackbar();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [versionInfo, setVersionInfo] = useState<VersionList>(null);

  useEffect(() => {
    getActiveVersionValue();
  }, []);

  const getActiveVersionValue = async (): Promise<void> => {
    try {
      const response = await getActiveVersionDetail();
      if (response.status === 200) {
        setVersionInfo(response.data);
      } else {
        console.error(
          'Failed to update values. Unexpected response:',
          response
        );
        enqueueSnackbar(
          'Failed load version details. Please try again later.',
          { variant: 'error' }
        );
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        return;
      }
      console.error('Error while updating values w:', error);
      enqueueSnackbar('Failed load version details. Please try again later.', {
        variant: 'error'
      });
    }
  };

  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0
        }}
      >
        <Scrollbar>
          <TopSection>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 200,
                mt: 2,
                mb: 3
              }}
            >
              <Logo />
              {versionInfo !== null && (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'strong',
                    color: 'inherit',
                    '&:hover': {
                      textDecoration: 'underline',
                      backgroundColor: 'transparent'
                    }
                  }}
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  EMS Version {versionInfo.versionNo}
                </Button>
              )}
              {versionInfo !== null && (
                <CustomPopupBox
                  isOpen={isModalOpen}
                  richText={versionInfo.versionDetails}
                  onClose={() => setIsModalOpen(false)}
                />
              )}
            </Box>
            <SidebarTopSection />
          </TopSection>
          <SidebarMenu />
        </Scrollbar>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbar>
            <TopSection>
              <Box
                sx={{
                  width: 52,
                  ml: 1,
                  mt: 1,
                  mb: 3
                }}
              >
                <Logo />
              </Box>
              <SidebarTopSection />
            </TopSection>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
      {/* <CustomPopupBox  isOpen={isModalOpen} richText='This isthe test' onClose={() => setIsModalOpen(false)} /> */}
    </>
  );
}

export default Sidebar;
