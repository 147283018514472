import { DateFilter } from "src/ems/enums/date.filter.enums"
import { DatePanelProps, DateFieldProps, DateFilterFieldProps } from "../../ReportFilterPanel/Models/ReportFilterPanelProps"


export interface DashboardFilterPanelProps {
    dashboardOptionPanel?: DashboardOptionPanelProps,
    datePanel?: DatePanelProps
}

export interface DashboardOptionPanelProps {
    title?: string,
    agent?: DropdownFieldProps,
    network?: DropdownFieldProps,
    subGroup?: DropdownFieldProps,
    headerGroup?: DropdownFieldProps,
    state?: DropdownFieldProps,
    businessManager?: DropdownFieldProps,
    product?: DropdownFieldProps,
    supplier?: DropdownFieldProps,
    showActiveSupplierCheck?: boolean,
    showLandAndAirOption?: boolean,
    supplierType?: number,
    groupByType?: DropdownFieldProps
}

export interface DropdownFieldProps {
    showField?: boolean,
    isAddAllOptions?: boolean,
    defaultValues?: number[],
    defaultValue?: number
}

export interface ToggleFieldProps {
    showField?: boolean,
    defaultValue?: boolean
}


const defaultDropdownField: DropdownFieldProps = {
    showField: false,
    isAddAllOptions: false,
    defaultValue: null,
    defaultValues: null
}


const defaultToggleField: ToggleFieldProps = {
    showField: false,
    defaultValue: false
};

// Default for DateFieldProps
const defaultDateField: DateFieldProps = {
    showField: false,
    defaultValue: new Date(),
    label: 'Date',
    name: 'date'  
};

const defaultDateFilterField: DateFilterFieldProps = {
    showField: false,
    defaultValue: DateFilter.CustomRange,  
};

// Default for DashboardFilterPanelProps
export function defaultDashboardFilterPanelProps(): DashboardFilterPanelProps {
    return {
        dashboardOptionPanel: {
            agent: { ...defaultDropdownField },
            network: { ...defaultDropdownField },
            subGroup: { ...defaultDropdownField },
            headerGroup: { ...defaultDropdownField },
            state: { ...defaultDropdownField },
            businessManager: { ...defaultDropdownField },
            product: { ...defaultDropdownField },
            supplier: { ...defaultDropdownField },
            groupByType: { ...defaultDropdownField },
            showActiveSupplierCheck: false,
            showLandAndAirOption: false
        },
        datePanel: {
            dateFrom: { ...defaultDateField },
            dateTo: { ...defaultDateField },
            dateFilter: { ...defaultDateFilterField }
        }
    };
}