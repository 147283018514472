import React, { useState } from 'react';
import { useEffect, useMemo } from 'react';
import { getDropDownReferences } from 'src/ems/slices/referenceDropDown';
import { getAgents } from 'src/ems/slices/agent';
import { useDispatch, useSelector } from 'src/store';
import { useForm, FormProvider } from 'react-hook-form';
import MultiSelectDropdown from 'src/ems/components/rhf-components/MultiSelectDropdown';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import MultiSelectDropDownOptionsProps from 'src/ems/models/multiSelectDropDownOptionsProps';
import { AgentPanelProps } from '../Models/ReportFilterPanelProps';
import { getCurrentUser } from 'src/ems/services/auth.service';

export const AgentPanel: React.FC<AgentPanelProps> = ({
  agent,
  network,
  subGroup,
  headerGroup,
  state,
  status
}) => {
  const methods = useForm();
  const dispatch = useDispatch();

  const { references } = useSelector((state) => state.referenceDropDown);
  const { agents } = useSelector((state) => state.agent);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(getDropDownReferences(signal));
    dispatch(getAgents(signal));

    return () => {
      controller.abort();
    };
  }, [dispatch]);

  const [agentOptions, setAgentOptions] = useState<
    MultiSelectDropDownOptionsProps[]
  >([]);

  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const userInfo = getCurrentUser();

  const headerGroupIds = userInfo.primaryGroupSid;
  const groupIds =
    userInfo.groupSid === undefined
      ? references
          .filter(
            (a) =>
              a.parentReferenceId !== null &&
              userInfo.primaryGroupSid.includes(a.parentReferenceId.toString())
          )
          .map((a) => a.value)
      : userInfo.groupSid;
  const subGroupIds = references
    .filter(
      (a) =>
        a.parentReferenceId !== null &&
        groupIds.includes(a.parentReferenceId.toString())
    )
    .map((a) => a.value);

  const distinctNetworkIds = [
    ...new Set(
      agents.map((obj) => obj.networkId).filter((id) => id !== null && id !== 0)
    )
  ];
  const distinctStateIds = [
    ...new Set(
      agents.map((obj) => obj.stateId).filter((id) => id !== null && id !== 0)
    )
  ];

  // Agent Panel

  useMemo(() => {
    const newAgentOptions = agents
      .map((agent) => ({
        label: agent.name,
        value: parseInt(agent.entityId, 10)
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setAgentOptions(newAgentOptions);
  }, [agents]);

  const subGroupOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter(
          (reference) =>
            subGroupIds.includes(reference.value) &&
            reference.type === 'SUB_GRP'
        )
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  // Getting Options from Reference
  const networkOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter(
          (reference) =>
            groupIds.includes(reference.value.toString()) &&
            reference.type === 'GRP'
        )
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const headerGroupOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter(
          (reference) =>
            headerGroupIds.includes(reference.value.toString()) &&
            reference.type === 'HEADER_GRP'
        )
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const stateOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference) => distinctStateIds.includes(reference.value))
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const statusOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference) => reference.type == 'STS')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const handleNetworkChange = (data) => {
    let filteredAgents = agents;
    const networkIds = data.map((item) => item.value);
    setSelectedNetworks(networkIds);

    if (networkIds.length === 1 && networkIds[0] === 0) {
      filteredAgents = agents.filter(
        (agent) =>
          (selectedStates.length === 0 ||
            selectedStates[0] === 0 ||
            selectedStates.includes(agent.stateId)) &&
          (selectedStatus.length === 0 ||
            selectedStatus[0] === 0 ||
            selectedStatus.includes(agent.statusId))
      );
    } else {
      filteredAgents = agents.filter(
        (agent) =>
          networkIds.includes(agent.networkId) &&
          (selectedStates.length === 0 ||
            selectedStates[0] === 0 ||
            selectedStates.includes(agent.stateId)) &&
          (selectedStatus.length === 0 ||
            selectedStatus[0] === 0 ||
            selectedStatus.includes(agent.statusId))
      );
    }

    const newAgentOptions = filteredAgents
      .map((agent) => ({
        label: agent.name,
        value: parseInt(agent.entityId, 10)
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setAgentOptions(newAgentOptions);
  };

  const handleStateChange = (data) => {
    let filteredAgents = agents;
    const stateIds = data.map((item) => item.value);
    setSelectedStates(stateIds);

    if (stateIds.length === 1 && stateIds[0] === 0) {
      filteredAgents = agents.filter(
        (agent) =>
          (selectedNetworks.length === 0 ||
            selectedNetworks[0] === 0 ||
            selectedNetworks.includes(agent.networkId)) &&
          (selectedStatus.length === 0 ||
            selectedStatus[0] === 0 ||
            selectedStatus.includes(agent.statusId))
      );
    } else {
      filteredAgents = agents.filter(
        (agent) =>
          stateIds.includes(agent.stateId) &&
          (selectedNetworks.length === 0 ||
            selectedNetworks[0] === 0 ||
            selectedNetworks.includes(agent.networkId)) &&
          (selectedStatus.length === 0 ||
            selectedStatus[0] === 0 ||
            selectedStatus.includes(agent.statusId))
      );
    }

    const newAgentOptions = filteredAgents
      .map((agent) => ({
        label: agent.name,
        value: parseInt(agent.entityId, 10)
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setAgentOptions(newAgentOptions);
  };

  const handleStatusChange = (data) => {
    let filteredAgents = agents;
    const statusIds = data.map((item) => item.value);
    setSelectedStatus(statusIds);

    if (statusIds.length === 1 && statusIds[0] === 0) {
      filteredAgents = agents.filter(
        (agent) =>
          (selectedStates.length === 0 ||
            selectedStates[0] === 0 ||
            selectedStates.includes(agent.stateId)) &&
          (selectedNetworks.length === 0 ||
            selectedNetworks[0] === 0 ||
            selectedNetworks.includes(agent.networkId))
      );
    } else {
      filteredAgents = agents.filter(
        (agent) =>
          statusIds.includes(agent.statusId) &&
          (selectedStates.length === 0 ||
            selectedStates[0] === 0 ||
            selectedStates.includes(agent.stateId)) &&
          (selectedNetworks.length === 0 ||
            selectedNetworks[0] === 0 ||
            selectedNetworks.includes(agent.networkId))
      );
    }

    const newAgentOptions = filteredAgents
      .map((agent) => ({
        label: agent.name,
        value: parseInt(agent.entityId, 10)
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    const currentValue = methods.getValues('network');
    setAgentOptions(newAgentOptions);
  };

  return (
    <>
      {/* Agent Panel */}
      {(agent.showField ||
        network.showField ||
        state.showField ||
        status.showField) && (
        <Card variant="outlined" sx={{ margin: '10px' }}>
          <CardContent>
            <Grid container>
              <Grid item xs={12} margin={1} marginBottom={3}>
                <Typography variant="h4">Agent Panel</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {headerGroup.showField && headerGroupOptions !== null ? (
                <Grid item xs={12} md={3} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="headerGroup"
                    label="Header Group"
                    options={headerGroupOptions}
                    addAllOption={headerGroup.isAddAllOptions}
                    defaultValues={headerGroup.defaultValues}
                  />
                </Grid>
              ) : null}
              {networkOptions && network.showField ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="network"
                    label="Network"
                    options={networkOptions}
                    addAllOption={network.isAddAllOptions}
                    defaultValues={network.defaultValues}
                    handleChange={handleNetworkChange}
                  />
                </Grid>
              ) : null}
              {subGroup.showField && subGroupOptions !== null ? (
                <Grid item xs={12} md={3} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="subGroup"
                    label="Sub Group"
                    options={subGroupOptions}
                    addAllOption={subGroup.isAddAllOptions}
                    defaultValues={subGroup.defaultValues}
                  />
                </Grid>
              ) : null}
              {agentOptions && agent.showField ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="agent"
                    label="Agent"
                    options={agentOptions}
                    addAllOption={agent.isAddAllOptions}
                    defaultValues={agent.defaultValues}
                  />
                </Grid>
              ) : null}
              {stateOptions && state.showField ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="state"
                    label="State"
                    options={stateOptions}
                    addAllOption={state.isAddAllOptions}
                    defaultValues={state.defaultValues}
                    handleChange={handleStateChange}
                  />
                </Grid>
              ) : null}
              {statusOptions && status.showField ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="status"
                    label="Status"
                    options={statusOptions}
                    addAllOption={status.isAddAllOptions}
                    defaultValues={status.defaultValues}
                    handleChange={handleStatusChange}
                  />
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};
