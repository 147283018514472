export interface PrivateFaresFilterPanelProps {
    privateFaresPanel?: PrivateFaresPanelProps
}

export interface DropdownFieldProps {
    showField?: boolean,
    isAddAllOptions?: boolean,
    defaultValues?: number[],
    defaultValue?: number
}

export interface InputFieldProps {
    showField?: boolean,
    defaultValue?: string
}

export interface PrivateFaresPanelProps {
    headerGroup?: DropdownFieldProps,
    subGroup?: DropdownFieldProps,
    agent?: DropdownFieldProps,
    network?: DropdownFieldProps,
    airline?: DropdownFieldProps,
    fareType?: DropdownFieldProps,
    accountCode?: InputFieldProps,
    tourCode?: InputFieldProps
}

const defaultDropdownField: DropdownFieldProps = {
    showField: false,
    isAddAllOptions: false,
    defaultValue: null,
    defaultValues: null
}

const defaultInputField: InputFieldProps = {
    showField: false,
    defaultValue: null,
}

// Default for PrivateFaresFilterPanelProps
export function defaultPrivateFaresFilterPanelProps(): PrivateFaresFilterPanelProps {
    return {
        privateFaresPanel: {
            headerGroup: { ...defaultDropdownField },
            subGroup: { ...defaultDropdownField },
            agent: { ...defaultDropdownField },
            network: { ...defaultDropdownField },
            airline: { ...defaultDropdownField },
            fareType: { ...defaultDropdownField },
            accountCode: { ...defaultInputField },
            tourCode: { ...defaultInputField }
        },
    };
}