import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import ContractSupportDetails from 'src/ems/models/contract_models/settings/contract_support_details';
import ContractSupportService from 'src/ems/services/contract/settings/contract.support.service';

interface ConstractSupportState {
  contractSupport: ContractSupportDetails[];
}

const initialState: ConstractSupportState = {
  contractSupport: []
};

const slice = createSlice({
  name: 'contractSupport',
  initialState,
  reducers: {
    getContractSupportList(
      state: ConstractSupportState,
      action: PayloadAction<{ contractSupport: ContractSupportDetails[] }>
    ) {
      const { contractSupport } = action.payload;

      state.contractSupport = contractSupport;
    }
  }
});

export const reducer = slice.reducer;

export const getContractSupportList =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    let { data: contractSupport } =
      await ContractSupportService.getContractSupportList();

    dispatch(slice.actions.getContractSupportList({ contractSupport }));
  };

export default slice;
