import axios from 'axios';
import { emsApiConfig } from '../../config';

// Response interceptor setup
axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    return Promise.reject(error);
  }
);

// Creating an instance of axios
const axiosInstance = axios.create({
  baseURL: emsApiConfig.ems_report_api_url,
  headers: {
    'Content-type': 'application/json'
    // The Authorization header will be conditionally added in the request interceptor
  }
});

// Request interceptor to add the Authorization header if it's not present
axiosInstance.interceptors.request.use((config) => {
  // Check if the Authorization header is already set
  if (!config.headers['Authorization']) {
    const accessToken = localStorage.getItem('accessToken'); // Retrieve the access token from localStorage
    if (accessToken) {
      // If accessToken exists, add the Authorization header
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
  }
  return config;
}, (error) => {
  // Do something with request error
  return Promise.reject(error);
});

export default axiosInstance;
