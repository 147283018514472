import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Card, CardContent, Grid, Typography, Box } from '@mui/material';
import DateComponent from 'src/ems/components/rhf-components/Date';
import { DatePanelProps } from '../Models/ReportFilterPanelProps';
import DateFilterChip from 'src/ems/components/rhf-components/DateFilter';
import { DateFilter } from 'src/ems/enums/date.filter.enums';
import CustomDatePicker from 'src/ems/components/rhf-components/CustomDatePicker';

const calculateDateRange = (filter) => {
  const now = new Date();
  switch (filter) {
    case DateFilter.Today:
      return { dateFrom: formatDate(now), dateTo: formatDate(now) };
    case DateFilter.Yesterday:
      const yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);
      return { dateFrom: formatDate(yesterday), dateTo: formatDate(yesterday) };
    case DateFilter.Last7Days:
      const last7DaysStart = new Date(now);
      last7DaysStart.setDate(now.getDate() - 7);
      return { dateFrom: formatDate(last7DaysStart), dateTo: formatDate(now) };
    case DateFilter.ThisWeek:
      const dayOfWeek = now.getDay();
      const thisWeekStart = new Date(now);
      thisWeekStart.setDate(now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
      return { dateFrom: formatDate(thisWeekStart), dateTo: formatDate(now) };
    case DateFilter.LastWeek:
      const lastWeekStart = new Date(now);
      const lastWeekEnd = new Date(now);
      lastWeekStart.setDate(now.getDate() - 6 - now.getDay());
      lastWeekEnd.setDate(now.getDate() - now.getDay());
      return { dateFrom: formatDate(lastWeekStart), dateTo: formatDate(lastWeekEnd) };
    case DateFilter.Last30Days:
      const last30DaysStart = new Date(now);
      last30DaysStart.setDate(now.getDate() - 29);
      return { dateFrom: formatDate(last30DaysStart), dateTo: formatDate(now) };
    case DateFilter.ThisMonth:
      const thisMonthStart = new Date(now);
      thisMonthStart.setDate(1);
      return { dateFrom: formatDate(thisMonthStart), dateTo: formatDate(now) };
    case DateFilter.LastMonth:
      const lastMonthStart = new Date(now);
      lastMonthStart.setMonth(now.getMonth() - 1);
      lastMonthStart.setDate(1);
      const lastMonthEnd = new Date(lastMonthStart.getFullYear(), lastMonthStart.getMonth() + 1, 0);
      return { dateFrom: formatDate(lastMonthStart), dateTo: formatDate(lastMonthEnd) };
    case DateFilter.ThisYear:
      const thisYearStart = new Date(now);
      thisYearStart.setMonth(0);
      thisYearStart.setDate(1);
      return { dateFrom: formatDate(thisYearStart), dateTo: formatDate(now) };
    case DateFilter.LastYear:
      const lastYearStart = new Date(now);
      lastYearStart.setFullYear(now.getFullYear() - 1);
      lastYearStart.setMonth(0);
      lastYearStart.setDate(1);
      const lastYearEnd = new Date(lastYearStart.getFullYear(), 11, 31);
      return { dateFrom: formatDate(lastYearStart), dateTo: formatDate(lastYearEnd) };
    case DateFilter.ThisFinancialYear:
      const thisFinancialYearStart = new Date(now);
      if (thisFinancialYearStart.getMonth() < 6) {
        thisFinancialYearStart.setFullYear(now.getFullYear() - 1);
      }
      thisFinancialYearStart.setMonth(6);
      thisFinancialYearStart.setDate(1);
      return { dateFrom: formatDate(thisFinancialYearStart), dateTo: formatDate(now) };
    case DateFilter.LastFinancialYear:
      const lastFinancialYearStart = new Date(now);
      if (lastFinancialYearStart.getMonth() < 6) {
        lastFinancialYearStart.setFullYear(now.getFullYear() - 2);
      } else {
        lastFinancialYearStart.setFullYear(now.getFullYear() - 1);
      }
      lastFinancialYearStart.setMonth(6);
      lastFinancialYearStart.setDate(1);
      const lastFinancialYearEnd = new Date(lastFinancialYearStart.getFullYear() + 1, 5, 30);
      return { dateFrom: formatDate(lastFinancialYearStart), dateTo: formatDate(lastFinancialYearEnd) };
    default:
      return { dateFrom: formatDate(now), dateTo: formatDate(now) };
  }
};

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const DatePanel: React.FC<DatePanelProps> = ({
  dateFrom,
  dateTo,
  dateFilter = { showField: true, defaultValue: DateFilter.CustomRange },
}) => {
  const { control, setValue } = useFormContext();
  const selectedFilter = useWatch({
    control,
    name: 'dateFilter',
    defaultValue: dateFilter.defaultValue,
  });

  const [selectedDates, setSelectedDates] = useState(() => calculateDateRange(dateFilter.defaultValue));

  useEffect(() => {
    setSelectedDates(calculateDateRange(dateFilter.defaultValue));
  }, [dateFilter.defaultValue]);

  useEffect(() => {
    setSelectedDates(calculateDateRange(selectedFilter));
  }, [selectedFilter]);

  useEffect(() => {
    setValue(dateFrom.name, selectedDates.dateFrom);
    setValue(dateTo.name, selectedDates.dateTo);
  }, [selectedDates, setValue, dateFrom.name, dateTo.name]);

  return (
    <>
      {(dateFrom.showField || dateTo.showField || dateFilter.showField) && (
        <Card variant="outlined" sx={{ margin: '10px' }}>
          <CardContent>
            <Grid container>
              <Grid item xs={12} margin={1} marginBottom={3}>
                <Typography variant="h4">Date Range</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {dateFrom.showField && (
                <Grid item xs={12} md={4} sm={12} lg={3}>
                  <CustomDatePicker
                    name={dateFrom.name}
                    label={dateFrom.label}
                    defaultValue={selectedDates.dateFrom}
                  />
                </Grid>
              )}
              {dateTo.showField && (
                <Grid item xs={12} md={4} sm={12} lg={3}>
                  <CustomDatePicker
                    name={dateTo.name}
                    label={dateTo.label}
                    defaultValue={selectedDates.dateTo}
                  />
                </Grid>
              )}
              {dateFilter.showField && (
                <Grid item xs={12} md={4} sm={12} lg={3} mt={1}>
                  <Box display="flex" alignItems="center">
                    <DateFilterChip name="dateFilter" defaultValue={dateFilter.defaultValue} />
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};