import React, {useState} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Card, CardContent, Grid, Typography, Button, Accordion, AccordionSummary, AccordionDetails, AccordionActionsProps } from '@mui/material';
import { DashboardOptionPanel } from './Panels/DashboardOptionPanel';
import { DatePanel } from '../ReportFilterPanel/Panels/DatePanel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DashboardFilterPanelProps } from './Models/DashboardFilterPanelProps';
import { yupResolver } from '@hookform/resolvers/yup';
import { DashboardFilterPanelSchema } from './ValidationSchema/DashboardFilterPanelSchema';
import { SupplierTypeEnum } from 'src/ems/enums/general.enums';
import { role } from 'src/ems/enums/roles.enums';
import { checkUserHasRole } from 'src/ems/services/auth.service';
import CustomButtonDropdown from '../CustomButtonDropdown';
import SettingsIcon from '@mui/icons-material/Settings';
import RestoreIcon from '@mui/icons-material/Restore';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { DateFilter } from 'src/ems/enums/date.filter.enums';
import CustomSchedulerButton, { scheduleDetails } from '../CustomSchedulerButton/CustomSchedulerButton';
import { formatDateToString } from 'src/ems/services/common.service';

interface ReportOptionsProps {
  onSubmit: (data: any) => void,
  onQuickExport?: (data: any) => void,
  handelExpansion?: (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void,
  reportTitle?: string;
  dashboardPanelTitle?: string;
  isLoadingReport: boolean,
  isExpandPanel: boolean
  reportFilters: DashboardFilterPanelProps
  showActiveSupplierCheck?: boolean
  showLandAndAirOption?: boolean
  showSetScheduler?: boolean
  supplierType?: number,
  columnPrefrence?: any
  schedulerPreference?: scheduleDetails,
  onSaveUserPreference?: (data: any) => void
  onSetUserColumnPreference?: (data: any) => void
  onResetUserPreference?: () => void
  onSetDefaultPreference?: (data: any) => void
  onSetScheduler?: (data: any) => void
}

export const DashboardFilterPanel: React.FC<ReportOptionsProps> = ({
  onSubmit,
  onQuickExport,
  reportTitle,
  handelExpansion,
  isLoadingReport,
  isExpandPanel,
  reportFilters,
  dashboardPanelTitle = 'Dashboard Filters',
  showSetScheduler = true,
  supplierType = SupplierTypeEnum.AIRLINE,
  columnPrefrence = null,
  schedulerPreference= null,
  onSaveUserPreference,
  onSetUserColumnPreference,
  onResetUserPreference,
  onSetDefaultPreference,
  onSetScheduler
}) => {

  const { dashboardOptionPanel, datePanel} = reportFilters
  const [isAllowSetDefault] = useState(checkUserHasRole(role.SET_DEFAULT_PREFERENCE))
  const [isAllowSetScheduler] = useState(checkUserHasRole(role.RPT_SET_SCHEDULER) && showSetScheduler)

  const methods = useForm({
    defaultValues: {
      agents: dashboardOptionPanel.agent.defaultValues ?? [],
      states: dashboardOptionPanel.state.defaultValues ?? [],
      networks: dashboardOptionPanel.network.defaultValues ?? [],
      subGroups: dashboardOptionPanel.network.defaultValues ?? [],
      headerGroups: dashboardOptionPanel.network.defaultValues ?? [],
      businessManager: dashboardOptionPanel.businessManager.defaultValues ?? [],
      products: dashboardOptionPanel.product.defaultValues ?? [],
      supplier: dashboardOptionPanel.supplier.defaultValues ?? [],
      groupByType: dashboardOptionPanel.groupByType.defaultValues ?? null,
      dateTo: formatDateToString(datePanel.dateTo.defaultValue) ?? new Date(),
      dateFrom: formatDateToString(datePanel.dateFrom.defaultValue) ?? new Date(),
      dateFilter: datePanel.dateFilter.defaultValue ?? DateFilter.CustomRange
    },
    resolver: yupResolver(DashboardFilterPanelSchema(dashboardOptionPanel, datePanel))
  })

  const handleFormSubmit = (data: any) => {
    onSubmit(data);
  };

  const handleQuickExport = () => {
    const data = methods.getValues();
    onQuickExport(data);
  };

  const handleSavePreference = () => {
    const data = methods.getValues();
    onSaveUserPreference(data);
  };

  const handleScheduler = (data) => {
    onSetScheduler(data)
  }

  const handleReset = () => {
    onResetUserPreference();
  };

  const handleSetDefaultFilter = () => {
    const data = methods.getValues();
    onSetDefaultPreference(data);
  };

  const handleSaveDropdown = (dropdownData: { [key: string]: boolean }) => {
    onSetUserColumnPreference(dropdownData)
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleFormSubmit)} style={{ margin: '8px' }}>
          <Accordion sx={{ margin: '10px' }} expanded={isExpandPanel} onChange={(e, expanded) => { handelExpansion(e, expanded); }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h3" gutterBottom>
                {reportTitle}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ margin: 2 }}>
              <Card variant="outlined" style={{ margin: '10px' }}>
                <CardContent>
                <Grid container justifyContent="flex-end" spacing={1} padding={1}>
                    {isAllowSetDefault && <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<AdminPanelSettingsIcon />}
                        onClick={handleSetDefaultFilter}
                      >
                        Set Default Filter
                      </Button>
                    </Grid>}
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<RestoreIcon />}
                        onClick={handleReset}
                      >
                        Reset Filter
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<SettingsIcon />}
                        onClick={handleSavePreference}
                      >
                        Save Filter
                      </Button>
                    </Grid>
                    {columnPrefrence && <Grid item>
                      <CustomButtonDropdown
                        data={columnPrefrence}
                        onSave={handleSaveDropdown}
                        title='Select Report Fields'
                        buttonName='Save Columns'
                      />
                    </Grid>}
                    {isAllowSetScheduler && <Grid item>
                      <CustomSchedulerButton
                        onSave={handleScheduler}
                        title='Select Scheduler'
                        buttonName='Set Scheduler'
                        initialDetails={schedulerPreference}
                        onCancle={handleScheduler}
                      />
                    </Grid>}
                  </Grid>
                  {/* Option Panel */}
                  <DashboardOptionPanel
                    title={dashboardPanelTitle}
                    agent={dashboardOptionPanel.agent}
                    businessManager={dashboardOptionPanel.businessManager}
                    state={dashboardOptionPanel.state}
                    network={dashboardOptionPanel.network}
                    subGroup={dashboardOptionPanel.subGroup}
                    headerGroup={dashboardOptionPanel.headerGroup}
                    product={dashboardOptionPanel.product}
                    supplier={dashboardOptionPanel.supplier}
                    groupByType={dashboardOptionPanel.groupByType}
                    showActiveSupplierCheck={dashboardOptionPanel.showActiveSupplierCheck}
                    showLandAndAirOption={dashboardOptionPanel.showLandAndAirOption}
                    supplierType={supplierType}
                  />

                  {/* Date Panel */}
                  <DatePanel 
                    dateFrom={datePanel.dateFrom}
                    dateTo={datePanel.dateTo}
                    dateFilter={datePanel.dateFilter}
                  />

                  <div>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} md={12} sm={12} lg={12} justifyContent={"center"} display={"flex"}>
                        {onQuickExport && <Button sx={{ marginRight: 2 }} variant="contained" type="submit" disabled={isLoadingReport} onClick={handleQuickExport}>Quick Export</Button>}
                        <Button sx={{ marginRight: 2 }} variant="contained" type="submit" disabled={isLoadingReport}>View</Button>
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </AccordionDetails>
          </Accordion>
          {/* <button type="submit">Submit</button> */}
        </form>
      </FormProvider>
    </>
  );
};
