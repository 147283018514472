export interface edm_subscriber_list_report_columns {
  state: boolean;
  agent: boolean;
  agencyGroup: boolean;
  name: boolean;
  email: boolean;
  jobType: boolean;
}

export function defaultEDMSubscriberListReportColumns(): edm_subscriber_list_report_columns {
  return {
    state: true,
    agent: true,
    agencyGroup: true,
    name: true,
    email: true,
    jobType: true
  };
}
