import { User } from 'src/models/user';
import http from './http.service';
import http_report from './http.report.service';
import jwtDecode from 'jwt-decode';
import configFile from 'src/config.json';
import { emsApiConfig } from 'src/config';
import { AuthTypeEnum } from '../enums/general.enums';
import { updateEvent } from 'src/slices/calendar';
import { AuthState } from 'src/models/auth';

const apiEndpoint = '/ForgotPassword';

interface SendVerificationDTO {
  username: string;
}

interface VerifyCodeDTO {
  username: string;
  code: string;
}

interface ChangePasswordDTO {
  username: string;
  password: string;
}

export async function sendVerification(username: string) {
  const dto: SendVerificationDTO = { username };

  const response = await http.post(
    apiEndpoint + '/sendverification',
    dto
  );

  return response;
}

export async function codeVerification(username: string, code: string) {
  const dto: VerifyCodeDTO = { username, code };

  const response = await http.post(
    apiEndpoint + '/verifycode',
    dto
  );

  return response;
}

export async function changePassword(username: string, password: string) {
  const dto: ChangePasswordDTO = { username, password };

  const response = await http.post(
    apiEndpoint + '/changepassword',
    dto
  );

  return response;
}

export default {
  sendVerification,
  codeVerification,
  changePassword
};
