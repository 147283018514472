import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { getDropDownReferences } from 'src/ems/slices/referenceDropDown';
import { getBusinessManagers } from 'src/ems/slices/businessManager';
import { getAgents } from 'src/ems/slices/agent';
import { getSuppliers } from 'src/ems/slices/supplier';
import { getProducts } from 'src/ems/slices/supplierProducts';
import { useDispatch, useSelector } from 'src/store';
import { useFormContext } from 'react-hook-form';
import MultiSelectDropdown from 'src/ems/components/rhf-components/MultiSelectDropdown';
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import MultiSelectDropDownOptionsProps from 'src/ems/models/multiSelectDropDownOptionsProps';
import Dropdown from 'src/ems/components/rhf-components/Dropdown';
import { DashboardOptionPanelProps } from '../Models/DashboardFilterPanelProps';
import CustomCheckbox from 'src/ems/components/rhf-components/Checkbox';
import CustomRadioGroup from 'src/ems/components/rhf-components/CustomRadioGroup';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { StatusEnum, SupplierTypeEnum } from 'src/ems/enums/general.enums';
import { getCurrentUser } from 'src/ems/services/auth.service';

export const DashboardOptionPanel: React.FC<DashboardOptionPanelProps> = ({
  title = 'Dashboard Filters',
  agent,
  businessManager,
  state,
  network,
  subGroup,
  headerGroup,
  product,
  supplier,
  groupByType,
  showActiveSupplierCheck = false,
  showLandAndAirOption = false,
  supplierType = SupplierTypeEnum.AIRLINE
}) => {
  const { control } = useFormContext();

  const dispatch = useDispatch();

  const { references } = useSelector((state) => state.referenceDropDown);
  const { businessManagers } = useSelector((state) => state.businessManager);
  const { suppliers } = useSelector((state) => state.supplier);
  const { supplierProducts } = useSelector((state) => state.supplierProducts);
  const { agents } = useSelector((state) => state.agent);
  const [isShowActiveSupplier, setIsShowActiveSupplier] =
    useState<boolean>(true);
  const [selectedSupplierType, setSelectedSupplierType] =
    useState<number>(supplierType);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(getDropDownReferences(signal));
    dispatch(getSuppliers(signal));
    dispatch(getBusinessManagers(signal));
    dispatch(getAgents(signal));
    dispatch(getProducts(signal));

    return () => {
      controller.abort();
    };
  }, [dispatch]);

  const userInfo = getCurrentUser();

  const headerGroupIds = userInfo.primaryGroupSid;
  const groupIds =
    userInfo.groupSid === undefined
      ? references
          .filter(
            (a) =>
              a.parentReferenceId !== null &&
              userInfo.primaryGroupSid.includes(a.parentReferenceId.toString())
          )
          .map((a) => a.value)
      : userInfo.groupSid;
  const subGroupIds = references
    .filter(
      (a) =>
        a.parentReferenceId !== null &&
        groupIds.includes(a.parentReferenceId.toString())
    )
    .map((a) => a.value);

  const distinctNetworkIds = [
    ...new Set(
      agents.map((obj) => obj.networkId).filter((id) => id !== null && id !== 0)
    )
  ];
  const distinctStateIds = [
    ...new Set(
      agents.map((obj) => obj.stateId).filter((id) => id !== null && id !== 0)
    )
  ];
  const distinctBMIds = [
    ...new Set(
      agents
        .map((obj) => obj.bmContactId)
        .filter((id) => id !== null && id !== 0)
    )
  ];

  const [agentOptions, setAgentOptions] = useState<
    MultiSelectDropDownOptionsProps[]
  >([]);

  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);

  useMemo(() => {
    const newAgentOptions = agents
      .map((agent) => ({
        label: agent.name,
        value: parseInt(agent.entityId, 10)
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setAgentOptions(newAgentOptions);
  }, [agents]);

  const subGroupOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter(
          (reference) =>
            subGroupIds.includes(reference.value) &&
            reference.type === 'SUB_GRP'
        )
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const businessManagerOptions = useMemo(
    () =>
      businessManagers
        .filter((bm) => distinctBMIds.includes(bm.value))
        .map((bm) => ({ label: bm.label, value: bm.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [businessManagers]
  );

  const networkOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter(
          (reference) =>
            groupIds.includes(reference.value.toString()) &&
            reference.type === 'GRP'
        )
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const headerGroupOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter(
          (reference) =>
            headerGroupIds.includes(reference.value.toString()) &&
            reference.type === 'HEADER_GRP'
        )
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const groupByTypeOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference) => reference.type === 'PS_GROUP_BY')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const stateOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference) => distinctStateIds.includes(reference.value))
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const productsOptions = useMemo(
    () =>
      supplierProducts
        .map((prod) => ({ label: prod.label, value: prod.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  // const handleNetworkChange = (data) => {
  //   const selectedIds = data.map((item) => item.value);
  //   let newSubGroupOptions = []
  //   if (selectedIds.length === 1 && selectedIds[0] === 0) {
  //     newSubGroupOptions = references
  //     .filter((reference) => groupIds.includes(reference.parentReferenceId))
  //     .map((ref) => ({ label: ref.label, value: ref.value }))
  //     .sort((a, b) => a.label.localeCompare(b.label));
  //   } else {
  //     newSubGroupOptions = references
  //     .filter((reference) => selectedIds.includes(reference.parentReferenceId))
  //     .map((ref) => ({ label: ref.label, value: ref.value }))
  //     .sort((a, b) => a.label.localeCompare(b.label));
  //   }
  //   setSubGroupOptions(newSubGroupOptions);
  // };

  const handleStateChange = (data) => {
    let filteredAgents = agents;
    const stateIds = data.map((item) => item.value);
    setSelectedStates(stateIds);

    if (stateIds.length === 1 && stateIds[0] === 0) {
      filteredAgents = agents.filter(
        (agent) =>
          selectedNetworks.length === 0 ||
          selectedNetworks[0] === 0 ||
          selectedNetworks.includes(agent.networkId)
      );
    } else {
      filteredAgents = agents.filter(
        (agent) =>
          stateIds.includes(agent.stateId) &&
          (selectedNetworks.length === 0 ||
            selectedNetworks[0] === 0 ||
            selectedNetworks.includes(agent.networkId))
      );
    }

    const newAgentOptions = filteredAgents
      .map((agent) => ({
        label: agent.name,
        value: parseInt(agent.entityId, 10)
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setAgentOptions(newAgentOptions);
  };

  const supplierOptions = useMemo(
    () =>
      suppliers
        .filter(
          (supplier) =>
            // (Number(selectedSupplierType) === SupplierTypeEnum.AIRLINE
            //   ? supplier.supplierType === 'LAND'
            //   : supplier.showAsSupplierInPs == true) &&
            supplier.showAsSupplierInPs == true &&
            (isShowActiveSupplier
              ? supplier.statusId === StatusEnum.ACTIVE
              : supplier.statusId === StatusEnum.NOT_ACTIVE)
        )
        .map((supp) => ({ label: supp.label, value: Number(supp.entityId) }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [suppliers, selectedSupplierType, isShowActiveSupplier]
  );

  const supplierTypeOptions = [
    {
      value: 66,
      label: 'Air',
      icon: <FlightTakeoffIcon />
    },
    {
      value: 67,
      label: 'Land',
      icon: <LocationCityIcon />
    }
  ];

  const checkOptions = () => {
    if (network.showField || state.showField || businessManager.showField)
      return true;
    else return false;
  };

  return (
    <>
      {/* Option Panel */}
      {checkOptions() && (
        <Card variant="outlined" sx={{ margin: '10px' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} margin={1} marginBottom={3}>
                <Typography variant="h4">{title}</Typography>
              </Grid>
            </Grid>
            {agent.showField ? (
              <Grid container spacing={2} marginBottom={5}>
                {agent.showField && agentOptions !== null ? (
                  <Grid item xs={12} md={4} sm={12} lg={4}>
                    <MultiSelectDropdown
                      name="agents"
                      label="Agent"
                      options={agentOptions}
                      addAllOption={agent.isAddAllOptions}
                      defaultValues={agent.defaultValues}
                    />
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
            {supplier.showField || product.showField ? (
              <Grid container spacing={2} marginBottom={3}>
                {supplier.showField && supplierOptions !== null ? (
                  <Grid item xs={12} md={4} sm={12} lg={4}>
                    <MultiSelectDropdown
                      name="supplier"
                      label="Supplier"
                      options={supplierOptions}
                      addAllOption={supplier.isAddAllOptions}
                      defaultValues={supplier.defaultValues}
                    />
                    {showActiveSupplierCheck && (
                      <CustomCheckbox
                        name="isSupplierActive"
                        label="Active"
                        defaultValues={isShowActiveSupplier}
                        handleChange={(value) => {
                          setIsShowActiveSupplier(value);
                        }}
                      />
                    )}
                    {showLandAndAirOption && (
                      <CustomRadioGroup
                        sx={{ marginLeft: 5 }}
                        name="supplierType"
                        options={supplierTypeOptions}
                        defaultValue={selectedSupplierType}
                        handleChange={(value) => {
                          setSelectedSupplierType(value);
                        }}
                      />
                    )}
                  </Grid>
                ) : null}

                {product.showField && productsOptions !== null ? (
                  <Grid item xs={12} md={4} sm={12} lg={4}>
                    <MultiSelectDropdown
                      name="products"
                      label="Product"
                      options={productsOptions}
                      addAllOption={product.isAddAllOptions}
                      defaultValues={product.defaultValues}
                    />
                  </Grid>
                ) : null}

                <Divider sx={{ margin: 3 }} />
              </Grid>
            ) : null}

            <Grid container spacing={2}>
              {headerGroup.showField && headerGroupOptions !== null ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="headerGroups"
                    label="Header Group"
                    options={headerGroupOptions}
                    addAllOption={headerGroup.isAddAllOptions}
                    defaultValues={headerGroup.defaultValues}
                  />
                </Grid>
              ) : null}

              {network.showField && networkOptions !== null ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="networks"
                    label="Group"
                    options={networkOptions}
                    addAllOption={network.isAddAllOptions}
                    defaultValues={network.defaultValues}
                  />
                </Grid>
              ) : null}

              {subGroup.showField && subGroupOptions !== null ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="subGroups"
                    label="Sub Group"
                    options={subGroupOptions}
                    addAllOption={subGroup.isAddAllOptions}
                    defaultValues={subGroup.defaultValues}
                  />
                </Grid>
              ) : null}

              {state.showField && stateOptions !== null ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="states"
                    label="State"
                    options={stateOptions}
                    addAllOption={state.isAddAllOptions}
                    defaultValues={state.defaultValues}
                    handleChange={handleStateChange}
                  />
                </Grid>
              ) : null}

              {businessManager.showField && businessManagerOptions !== null ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="businessManager"
                    label="Business Manager"
                    options={businessManagerOptions}
                    addAllOption={businessManager.isAddAllOptions}
                    defaultValues={businessManager.defaultValues}
                  />
                </Grid>
              ) : null}
            </Grid>

            {groupByType.showField ? (
              <Grid container spacing={2} marginTop={5}>
                {groupByType.showField && groupByTypeOptions !== null ? (
                  <Grid item xs={12} md={4} sm={12} lg={4}>
                    <Dropdown
                      name="groupByType"
                      label="Group By"
                      options={groupByTypeOptions}
                      addAllOption={groupByType.isAddAllOptions}
                      defaultValues={groupByType.defaultValue}
                    />
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
          </CardContent>
        </Card>
      )}
    </>
  );
};
