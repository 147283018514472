import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import SupplierService from 'src/ems/services/supplier.service';
import EntitySummary from 'src/ems/models/entity_summary';

interface SupplierState {
  suppliers: EntitySummary[];
}

const initialState: SupplierState = {
  suppliers: []
};

const slice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    getSuppliers(
      state: SupplierState,
      action: PayloadAction<{ suppliers: EntitySummary[] }>
    ) {
      const { suppliers } = action.payload;

      state.suppliers = suppliers;
    }
  }
});

export const reducer = slice.reducer;

export const getSuppliers =
  (signal?: any): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      let { data } = await SupplierService.getSuppliers(signal);
      const suppliers = Array.isArray(data) ? data : [];
      dispatch(slice.actions.getSuppliers({ suppliers }));
    } catch (error) {
      if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
        console.error(error);
      }
    }
  };

export default slice;
