import React, { useState } from 'react';
import {
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  Container
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { makeStyles } from '@mui/styles';
import Logo from 'src/components/LogoSign';

const useStyles = makeStyles((theme) => ({
    textFieldError: {
      animation: '$shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
      border: '2px solid red',
    },
    '@keyframes shake': {
      '10%, 90%': {
        transform: 'translate3d(-1px, 0, 0)',
      },
      '20%, 80%': {
        transform: 'translate3d(2px, 0, 0)',
      },
      '30%, 50%, 70%': {
        transform: 'translate3d(-4px, 0, 0)',
      },
      '40%, 60%': {
        transform: 'translate3d(4px, 0, 0)',
      },
    },
    errorText: {
      color: 'red',
      marginTop: '8px',
    },
  }));

interface TwoFactorAuthProps {
  // Add any props here if needed
  qrCode?: string;
  manualCode?: string;
  userId?: string;
  username?: string
}

const TwoFactorAuthenticatorSetup: React.FC<TwoFactorAuthProps> = ({
  manualCode,
  qrCode,
  userId,
  username
}) => {
  const classes = useStyles();
  const { verify } = useAuth() as any;
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.toUpperCase(); // Convert to uppercase
    if (input.length <= 6) { // Limit to 6 characters
      setOtp(input);
    }
    if (error) setError(''); // Reset error on input change
  };

  const handleEnableTwoFactor = async () => {
    try {
      await verify(otp, userId, username);
    } catch (error) {
      setError('Invalid code. Please try again.'); // Set custom or dynamic error message here
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleEnableTwoFactor();
    }
  };

  return (
    <>
    <Logo />
    <Container
      maxWidth="sm"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper elevation={3} style={{ padding: '2rem', width: '100%' }}>
        <Typography variant="h5" style={{ textAlign: 'center' }}>
          Two-factor Authentication
        </Typography>
        <Grid container spacing={3} style={{ marginTop: '1rem' }}>
          <Grid item xs={12}>
            <Typography>1. Scan QR Code</Typography>
            <Typography>
              Scan the below QR code using a one-time passcode generator
              application (such as Google Authenticator or Authy).
            </Typography>
            <img
              src={qrCode}
              alt="QR Code"
              style={{
                margin: '10px 0',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            />
            <Typography>
              If you cannot scan, please enter the following code manually:
            </Typography>
            <Typography style={{ fontWeight: 'bold' }}>{manualCode}</Typography>

          </Grid>
          <Grid item xs={12}>
            <Typography>2. Confirm OTP Code</Typography>
            <Typography>
              Please confirm that your one-time passcode generator application
              is working by entering a generated code below.
            </Typography>
            <TextField
              label="One Time Passcode (From Authentication App)"
              value={otp}
              onChange={handleOtpChange}
              onKeyDown={handleKeyDown}
              margin="normal"
              fullWidth
              error={Boolean(error)}
              className={error ? classes.textFieldError : ''}
            />
            {error && <Typography className={classes.errorText}>{error}</Typography>}
            <Button
              variant="contained"
              color="primary"
              onClick={handleEnableTwoFactor}
              style={{
                marginTop: '1rem',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              Confirm and Enable Two-factor
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
    </>
  );
};

export default TwoFactorAuthenticatorSetup;
