import React from 'react';
import { useEffect, useMemo } from 'react';
import { getDropDownReferences } from 'src/ems/slices/referenceDropDown';
import { getAgents } from 'src/ems/slices/agent';
import { useDispatch, useSelector } from 'src/store';
import { useForm } from 'react-hook-form';
import MultiSelectDropdown from 'src/ems/components/rhf-components/MultiSelectDropdown';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import MultiSelectDropDownOptionsProps from 'src/ems/models/multiSelectDropDownOptionsProps';
import { ProductPanelProps } from '../Models/ProductFilterPanelProps';
import { getProductLandSalesFinancialPeriod } from 'src/ems/slices/productLandSalesFinancialPeriod';

export const ProductPanel: React.FC<ProductPanelProps> = ({
  agent,
  financialYear
}) => {
  const methods = useForm();
  const dispatch = useDispatch();

  const { productLandSalesFinancialPeriod: financialPeriods } = useSelector((state) => state.productLandSalesFinancialPeriod);
  const { agents } = useSelector((state) => state.agent);

  useEffect(() => {
    dispatch(getProductLandSalesFinancialPeriod());
  }, [dispatch]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(getDropDownReferences(signal));
    dispatch(getAgents(signal));

    return () => {     
      controller.abort();
    };

  }, [dispatch]);

  // Product Panel
  const agentOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      agents
        .map((agent) => ({ label: agent.name, value: parseInt(agent.entityId, 10)}))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [agents]
  );

  // Getting Options from financial periods
  const financialPeriodOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      financialPeriods
        .map((period) => ({ label: period.description, value: period.contractPeriodId }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [financialPeriods]
  );
  
  return (
    <>
      {/* Agent Panel */}
      {(agent.showField || financialYear.showField) && (
        <Card variant="outlined" sx={{ margin: '10px' }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} margin={1} marginBottom={3}>
              <Typography variant="h4">Product Panel</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {agent.showField && (<Grid item xs={12} md={4} sm={12} lg={3}>
              <MultiSelectDropdown
                name="agent"
                label="Agent"
                options={agentOptions}
                addAllOption={agent.isAddAllOptions}
                defaultValues={agent.defaultValues}
              />
            </Grid>)}
            {financialYear.showField && (<Grid item xs={12} md={4} sm={12} lg={3}>
              <MultiSelectDropdown
                name="financialYear"
                label="Financial Year"
                options={financialPeriodOptions}
                addAllOption={financialYear.isAddAllOptions}
                defaultValues={financialYear.defaultValues}
              />
            </Grid>)}
          </Grid>
        </CardContent>
      </Card>
      )}
      
    </>
  );
};
