import { format } from 'date-fns';
import moment from 'moment';
import numeral from 'numeral';
import { useLocation } from 'react-router-dom';
import { DateFilter } from '../enums/date.filter.enums';

export const getCurrentRootPath = (): string => {
  const location = useLocation();
  return `/${location.pathname.split('/')[1]}`;
};

export const handleValue = (value = 0): string => {
  const amount = value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return amount;
};

export const dateToString = (date): string => {
  return format(Date.parse(date), 'yyyy-MM-dd');
};

export const numberWithCommas = (number) => {
  if (number !== null && number !== undefined) {
    return number
      .toString()
      .replace(/,/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return 0;
  }
};

export const numberFormat = (value) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(value);

export const thousandDecimal = (value) => new Intl.NumberFormat().format(value);

export const agentStatus = (id): string => {
  switch (id) {
    case 1:
      return 'Active';

    case 42:
      return 'NotActive';

    case 43:
      return 'Suspended';

    case 44:
      return 'New';

    case 96:
      return 'Deleted';

    case 199:
      return 'Prospect';

    case 208:
      return 'ActiveSelfTicketing';

    case 209:
      return 'Closed';

    case 449:
      return 'Departing';

    default:
      return null;
  }
};

export const pmtStatus = (id): string => {
  switch (id) {
    case 1:
      return 'INCOMPLETE';

    case 2:
      return 'UPLOADED';

    case 3:
      return 'SUBMITTEDFORAPPROVAL';

    case 4:
      return 'PAYABLE';

    case 5:
      return 'PARTIALLYSUBMITTED';

    case 6:
      return 'INTENDTOPAY';

    case 7:
      return 'PAID';

    case 8:
      return 'TO_BE_ADJUSTED';
    case 9:
      return 'SUBMITTED_FOR_ADJUSTMENT_APPROVAL';
    case 10:
      return 'ADJUSTMENT_PAYABLE';
    case 11:
      return 'ADJUSTMENT_PARTIALLY_SUBMITTED';
    case 12:
      return 'INTEND_TO_PAY_ADJUSTMENT';
    case 13:
      return 'PAID_ADJUSTMENT';
    default:
      return null;
  }
};

export const textColor = (id) => {
  if (id === 1) {
    return 'red';
  } else if (id === 2) {
    return 'skyblue';
  } else if (id === 3) {
    return 'orange';
  } else if (id === 4) {
    return 'dodgerblue';
  } else if (id === 5) {
    return 'coral';
  } else if (id === 6) {
    return 'brown';
  } else if (id === 7) {
    return 'darkgreen';
  } else if (id === 8) {
    return 'skyblue';
  } else if (id === 9) {
    return 'orange';
  } else if (id === 10) {
    return 'dodgerblue';
  } else if (id === 11) {
    return 'coral';
  } else if (id === 12) {
    return 'brown';
  } else if (id === 13) {
    return '';
  } else {
    return '';
  }
};

export const columnHide = (column, data): boolean => {
  if (data.length === 0) return false;
  const from = moment(data[0].dateFrom).format('MM');
  const to = moment(data[0].dateTo).format('MM');
  return column >= from && column <= to;
};

export const formatNumberToK = (number: number): string => {
  if (number >= 1000) {
    const roundedNumber = Math.round(number / 1000);
    return `${roundedNumber}K`;
  } else {
    return number.toString();
  }
}

export const formatNumberWithCommas = (number, isDecimal = true) => {
  // Check if the input is a valid number
  if (isNaN(number)) {
    return 'Invalid input';
  }

  // Round up the number if isDecimal is false
  if (!isDecimal) {
    number = Math.ceil(number);
  }

  // Convert the number to a string
  let numberString = number.toString();

  // Split the string into integer and decimal parts
  const parts = numberString.split('.');

  // Add commas to the integer part
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Join the integer and decimal parts back together
  const formattedNumber = isDecimal ? parts.join('.') : parts[0];

  return formattedNumber;
};

export const formatToPercentage = (number, isDecimal = true) => {
  if (isNaN(number)) {
    return 'Invalid number';
  }

  // Round the number based on the isDecimal flag
  const roundedNumber = isDecimal
    ? parseFloat(number.toFixed(2))
    : Math.round(number);

  const formattedPercentage = `${roundedNumber}%`;
  return formattedPercentage;
};

export const getLastWeekDay = (isLastDayOfWeek) => {
  const today = new Date();
  const currentDayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

  const daysToAdd = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1; // Calculate days to subtract

  const lastMonday = new Date(today);
  lastMonday.setDate(today.getDate() - daysToAdd - 7); // Subtract days to get to the previous Monday

  const lastSunday = new Date(lastMonday);
  lastSunday.setDate(lastMonday.getDate() + 6); // Add 6 days to get to the previous Sunday

  return isLastDayOfWeek ? lastSunday : lastMonday;
};

export const isDateString = (value: string): boolean => {
  return !isNaN(Date.parse(value)) && value.indexOf('-') > 0;
};

// Helper function to format a Date object to YYYY-MM-DD format
export const formatDate = (date: Date): string => {
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  let year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const convertDateFormat = (inputDateStr) => {
  // Check if the input is null or undefined
  if (inputDateStr === null || inputDateStr === undefined) {
    return 'N/A';
  }

  // Parse the input date string
  const dateObj = new Date(inputDateStr);

  // Validate the date object. Invalid date objects result in 'Invalid Date'.
  if (isNaN(dateObj.getTime())) {
    return 'N/A';
  }

  // Extract the day, month, and year
  const day = dateObj.getDate().toString().padStart(2, '0'); // Ensure two digits
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits, January is 0!
  const year = dateObj.getFullYear();

  // Format and return the date in dd/mm/yyyy format
  return `${day}/${month}/${year}`;
};

export const numberRounding = (number, isDecimal = false) => {
  // Check if the input is a valid number
  if (isNaN(number)) {
    return 'Invalid input';
  }

  // Round up the number if isDecimal is false
  const roundedNumber = isDecimal ? number : Math.ceil(number);

  // Format the number as currency with commas
  const formattedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: isDecimal ? 2 : 0,
    maximumFractionDigits: isDecimal ? 2 : 0
  }).format(roundedNumber);

  return formattedValue;
};

export const generateFileName = (title, dateFrom, dateTo) => {
  // Format date strings (assuming dateFrom and dateTo are JavaScript Date objects)
  const formattedDateFrom = dateFrom.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
  const formattedDateTo = dateTo.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });

  // Combine title and formatted date range
  const fileName = `${title} ${formattedDateFrom} - ${formattedDateTo}`;

  // Remove invalid characters from the file name (e.g., replace spaces with underscores)
  const sanitizedFileName = fileName.replace(/[\s\/\\]/g, '_');

  return sanitizedFileName;
};

export const toTitleCase = (str) => {
  return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
};

export const numberRoundingNoFormat = (number, isDecimal = false) => {
  // Check if the input is a valid number
  if (isNaN(number)) {
    return 'Invalid input';
  }

  // Round the number based on the isDecimal flag
  const roundedNumber = isDecimal
    ? parseFloat(number.toFixed(2))
    : Math.round(number);

  return roundedNumber;
};

export const generateDateRangeString = (dateFrom, dateTo) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  // Parse date strings to Date objects if necessary
  const fromDate = new Date(dateFrom);
  const toDate = new Date(dateTo);

  // Combine formatted date range with a hyphen
  const dateRangeString = `${
    fromDate.getDate() +
    ' ' +
    monthNames[fromDate.getMonth()] +
    ' ' +
    fromDate.getFullYear()
  } – ${
    toDate.getDate() +
    ' ' +
    monthNames[toDate.getMonth()] +
    ' ' +
    toDate.getFullYear()
  }`;

  return dateRangeString;
};

export const sortCurrencyValues = (value1, value2) => {
  const num1 = parseFloat(value1.replace(/[^0-9.-]+/g, ''));
  const num2 = parseFloat(value2.replace(/[^0-9.-]+/g, ''));

  return num1 - num2;
};

export const getFinancialDate = () => {
  let today = new Date();
    let financialYearStart;
    
    // Check if today's month and day are greater than or equal to July 1st
    if (today.getMonth() >= 6 && today.getDate() >= 1) {
        financialYearStart = new Date(today.getFullYear(), 6, 1); // July 1st of the current year
    } else {
        financialYearStart = new Date(today.getFullYear() - 1, 6, 1); // July 1st of the previous year
    }

    return financialYearStart;
}

export const formatDateToString = (date) => {
  if (date == null || date === '') {
    return null; // or you can return an empty string or any other default value
  }

  if (typeof date === 'string') {
    return date;
  }

  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export function calculateDateRange(filter): any {
  const now = new Date();
  switch (filter) {
    case DateFilter.Today:
      return { dateFrom: formatDate(now), dateTo: formatDate(now) };
    case DateFilter.Yesterday:
      const yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);
      return { dateFrom: formatDate(yesterday), dateTo: formatDate(yesterday) };
    case DateFilter.Last7Days:
      const last7DaysStart = new Date(now);
      last7DaysStart.setDate(now.getDate() - 7);
      return { dateFrom: formatDate(last7DaysStart), dateTo: formatDate(now) };
    case DateFilter.ThisWeek:
      const dayOfWeek = now.getDay();
      const thisWeekStart = new Date(now);
      thisWeekStart.setDate(now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
      return { dateFrom: formatDate(thisWeekStart), dateTo: formatDate(now) };
    case DateFilter.LastWeek:
      const lastWeekStart = new Date(now);
      const lastWeekEnd = new Date(now);
      lastWeekStart.setDate(now.getDate() - 6 - now.getDay());
      lastWeekEnd.setDate(now.getDate() - now.getDay());
      return { dateFrom: formatDate(lastWeekStart), dateTo: formatDate(lastWeekEnd) };
    case DateFilter.Last30Days:
      const last30DaysStart = new Date(now);
      last30DaysStart.setDate(now.getDate() - 29);
      return { dateFrom: formatDate(last30DaysStart), dateTo: formatDate(now) };
    case DateFilter.ThisMonth:
      const thisMonthStart = new Date(now);
      thisMonthStart.setDate(1);
      return { dateFrom: formatDate(thisMonthStart), dateTo: formatDate(now) };
    case DateFilter.LastMonth:
      const lastMonthStart = new Date(now);
      lastMonthStart.setMonth(now.getMonth() - 1);
      lastMonthStart.setDate(1);
      const lastMonthEnd = new Date(lastMonthStart.getFullYear(), lastMonthStart.getMonth() + 1, 0);
      return { dateFrom: formatDate(lastMonthStart), dateTo: formatDate(lastMonthEnd) };
    case DateFilter.ThisYear:
      const thisYearStart = new Date(now);
      thisYearStart.setMonth(0);
      thisYearStart.setDate(1);
      return { dateFrom: formatDate(thisYearStart), dateTo: formatDate(now) };
    case DateFilter.LastYear:
      const lastYearStart = new Date(now);
      lastYearStart.setFullYear(now.getFullYear() - 1);
      lastYearStart.setMonth(0);
      lastYearStart.setDate(1);
      const lastYearEnd = new Date(lastYearStart.getFullYear(), 11, 31);
      return { dateFrom: formatDate(lastYearStart), dateTo: formatDate(lastYearEnd) };
    case DateFilter.ThisFinancialYear:
      const thisFinancialYearStart = new Date(now);
      if (thisFinancialYearStart.getMonth() < 6) {
        thisFinancialYearStart.setFullYear(now.getFullYear() - 1);
      }
      thisFinancialYearStart.setMonth(6);
      thisFinancialYearStart.setDate(1);
      return { dateFrom: formatDate(thisFinancialYearStart), dateTo: formatDate(now) };
    case DateFilter.LastFinancialYear:
      const lastFinancialYearStart = new Date(now);
      if (lastFinancialYearStart.getMonth() < 6) {
        lastFinancialYearStart.setFullYear(now.getFullYear() - 2);
      } else {
        lastFinancialYearStart.setFullYear(now.getFullYear() - 1);
      }
      lastFinancialYearStart.setMonth(6);
      lastFinancialYearStart.setDate(1);
      const lastFinancialYearEnd = new Date(lastFinancialYearStart.getFullYear() + 1, 5, 30);
      return { dateFrom: formatDate(lastFinancialYearStart), dateTo: formatDate(lastFinancialYearEnd) };
    default:
      return { dateFrom: formatDate(now), dateTo: formatDate(now) };
  }
};


export default {
  getCurrentRootPath,
  handleValue,
  numberFormat,
  pmtStatus,
  textColor,
  agentStatus,
  columnHide,
  formatNumberWithCommas,
  formatToPercentage,
  getLastWeekDay,
  formatDate,
  isDateString,
  numberRounding,
  generateFileName,
  toTitleCase,
  numberRoundingNoFormat,
  generateDateRangeString,
  sortCurrencyValues,
  convertDateFormat,
  getFinancialDate,
  formatDateToString,
  calculateDateRange
};
