import { checkUserHasRole } from 'src/ems/services/auth.service';
import { role } from '../enums/roles.enums';

interface MenuPermession {
  // General
  isAuthorised_General: boolean;

  // Dashboard Roles
  isAuthorised_EMS_Dashboard: boolean;
  isAuthorised_Sales_Dashboard: boolean;
  isAuthorised_Management_Dashboard: boolean;
  isAuthorised_Product_Dashboard: boolean;

  //Agents Roles
  isAuthorised_Agent: boolean;
  isAuthorised_Agent_List: boolean;
  isAuthorised_Agent_Create: boolean;

  // Suppliers Roles
  isAuthorised_Supplier: boolean;
  isAuthorised_Supplier_List: boolean;
  isAuthorised_Supplier_Create: boolean;
  isAuthorised_Airline_Offer: boolean;
  isAuthorised_Airline_BSP: boolean;

  // Contact Roles
  isAuthorised_Contact: boolean;
  isAuthorised_Contact_List: boolean;
  isAuthorised_Contact_Create: boolean;

  // Land Contract Roles
  isAuthorised_LandContract_List: boolean;
  isAuthorised_LandContract_Create: boolean;
  isAuthorised_LandContract_Clone: boolean;
  isAuthorised_LandContract_Edit: boolean;
  isAuthorised_LandContract_Delete: boolean;

  isAuthorised_Contract_Settings: boolean;

  //Products
  isAuthorised_Products: boolean;

  // Land Roles
  isAuthorised_Land: boolean;
  isAuthorised_Land_Browse_Data_Files: boolean;
  isAuthorised_Land_File_Status: boolean;
  isAuthorised_Land_Load_Data_File: boolean;
  isAuthorised_Land_Land_Override: boolean;
  isAuthorised_Land_Override_Remittances: boolean;

  //Reports
  isAuthorised_Reports: boolean;

  //Ticketing
  isAuthorised_Ticketing: boolean;
  isAuthorised_Agency_Report: boolean;
  isAuthorised_Airline_Report: boolean;
  isAuthorised_General_Report: boolean;

  // Agent Report Roles
  isAuthorised_Agency_By_Airline_Annual: boolean;
  isAuthorised_Agency_By_Airline_Monthly: boolean;
  isAuthorised_Agency_By_Airline_Comm_Annual: boolean;
  isAuthorised_Agents_Sales_Existing_Annual: boolean;
  isAuthorised_Agents_Sales_Existing_Weekly: boolean;
  isAuthorised_Agents_New_Business: boolean;
  isAuthorised_Agency_Sales_Summary_Annual: boolean;
  isAuthorised_Agency_Sales_Summary_Monthly: boolean;
  isAuthorised_Agents_Ticket_Usage: boolean;
  isAuthorised_Agent_Vs_BSP: boolean;
  isAuthorised_Detailed_Ticket_Report: boolean;
  isAuthorised_Ticket_Sector_Report: boolean;

  // Airline Report Roles
  isAuthorised_Airline_By_Agency_Annual: boolean;
  isAuthorised_Airline_By_Agency_Monthly: boolean;
  isAuthorised_Airline_Sales_Existing_Weekly: boolean;
  isAuthorised_Airline_Sales_Summary_Annual: boolean;
  isAuthorised_Airline_Sales_Summary_Monthly: boolean;

  //General Reports
  isAuthorised_Pcc_List: boolean;
  isAuthorised_Agent_AuditTrail: boolean;
  isAuthorised_Agent_NetOutstanding: boolean;
  isAuthorised_Agent_Extended: boolean;
  isAuthorised_BDM_Weekly: boolean;
  isAuthorised_System_Extended: boolean;
  isAuthorised_Product_Sales: boolean;
  isAuthorised_Land_Overrides: boolean;
  isAuthorised_EDM_Subscriber_List: boolean;
  isAuthorised_Private_Fares: boolean;

  isAuthorised_Admin_Panel: boolean;
  isAuthorised_Admin_Version: boolean;
  isAuthorised_Admin_Reference: boolean;
  isAuthorised_Admin_Email_Builder_Template: boolean;
}

export const getUserMenu = (): MenuPermession => {
  let menu: MenuPermession = {
    // General
    isAuthorised_General: true,

    // Dashboard Roles
    isAuthorised_EMS_Dashboard: false,
    isAuthorised_Sales_Dashboard: checkUserHasRole(role.DBS_SALES),
    isAuthorised_Management_Dashboard: checkUserHasRole(role.DBS_MANAGEMENT),
    isAuthorised_Product_Dashboard: checkUserHasRole(role.DBS_PRODUCT),

    //Agents Roles
    isAuthorised_Agent:
      checkUserHasRole(role.VIEW_AGENT_LIST) ||
      checkUserHasRole(role.CREATE_NEW_AGENT),
    isAuthorised_Agent_List: checkUserHasRole(role.VIEW_AGENT_LIST),
    isAuthorised_Agent_Create: checkUserHasRole(role.CREATE_NEW_AGENT),

    // Suppliers Roles
    isAuthorised_Supplier:
      checkUserHasRole(role.VIEW_SUPPLIER_LIST) ||
      checkUserHasRole(role.CREATE_NEW_SUPPLIER),
    isAuthorised_Supplier_List: checkUserHasRole(role.VIEW_SUPPLIER_LIST),
    isAuthorised_Supplier_Create: checkUserHasRole(role.CREATE_NEW_SUPPLIER),
    isAuthorised_Airline_Offer: checkUserHasRole(role.VIEW_AIRLINE_OFFER),
    isAuthorised_Airline_BSP: checkUserHasRole(role.VIEW_AIRLINE_BSP),

    // Contact Roles
    isAuthorised_Contact:
      checkUserHasRole(role.VIEW_CONTACT_LIST) ||
      checkUserHasRole(role.CREATE_NEW_CONTACT),
    isAuthorised_Contact_List: checkUserHasRole(role.VIEW_CONTACT_LIST),
    isAuthorised_Contact_Create: checkUserHasRole(role.CREATE_NEW_CONTACT),

    // Contract Roles

    isAuthorised_LandContract_List: checkUserHasRole(role.LIST_LAND_CONTRACT),
    isAuthorised_LandContract_Create: checkUserHasRole(
      role.CREATE_LAND_CONTRACT
    ),
    isAuthorised_LandContract_Clone: checkUserHasRole(role.CLONE_LAND_CONTRACT),
    isAuthorised_LandContract_Edit: checkUserHasRole(role.EDIT_LAND_CONTRACT),
    isAuthorised_LandContract_Delete: checkUserHasRole(
      role.DELETE_LAND_CONTRACT
    ),

    isAuthorised_Contract_Settings: checkUserHasRole(
      role.LAND_CONTRACT_SETTINGS
    ),

    //Products
    isAuthorised_Products: checkUserHasRole(role.VIEW_PRODUCTSALES_DATA),

    // Land Roles
    isAuthorised_Land: true,
    isAuthorised_Land_Browse_Data_Files: true,
    isAuthorised_Land_File_Status: true,
    isAuthorised_Land_Load_Data_File: true,
    isAuthorised_Land_Land_Override: true,
    isAuthorised_Land_Override_Remittances: checkUserHasRole(
      role.SEND_LAND_PAYMENT_REMITTANCES
    ),

    //Reports
    isAuthorised_Reports: true,

    //Ticketing
    isAuthorised_Ticketing: true,
    isAuthorised_Agency_Report: true,
    isAuthorised_Airline_Report: true,
    isAuthorised_General_Report: true,

    // Agent Report Roles
    isAuthorised_Agency_By_Airline_Annual: checkUserHasRole(
      role.RPT_AGENCY_BY_AIRLINE_ANNUAL
    ),
    isAuthorised_Agency_By_Airline_Monthly: checkUserHasRole(
      role.RPT_AGENCY_BY_AIRLINE_MONTHLY
    ),
    isAuthorised_Agency_By_Airline_Comm_Annual: checkUserHasRole(
      role.RPT_AGENCY_BY_AIRLINE_COMMISSION
    ),
    isAuthorised_Agents_Sales_Existing_Annual: checkUserHasRole(
      role.RPT_AGENTS_SALES_EXISTING_ANNUAL
    ),
    isAuthorised_Agents_Sales_Existing_Weekly: checkUserHasRole(
      role.RPT_AGENTS_SALES_EXISTING_WEEKLY
    ),
    isAuthorised_Agents_New_Business: checkUserHasRole(
      role.RPT_AGENTS_NEW_BUSINESS
    ),
    isAuthorised_Agency_Sales_Summary_Annual: checkUserHasRole(
      role.RPT_AGENCY_SALES_SUMMARY_ANNUAL
    ),
    isAuthorised_Agency_Sales_Summary_Monthly: checkUserHasRole(
      role.RPT_AGENCY_SALES_SUMMARY_MONTHLY
    ),
    isAuthorised_Agents_Ticket_Usage: checkUserHasRole(
      role.RPT_AGENTS_TICKET_USAGE
    ),
    isAuthorised_Agent_Vs_BSP: checkUserHasRole(role.RPT_AGENT_VS_BSP),
    isAuthorised_Detailed_Ticket_Report: checkUserHasRole(
      role.RPT_DETAILED_TICKET_REPORT
    ),
    isAuthorised_Ticket_Sector_Report: checkUserHasRole(role.RPT_TICKET_SECTOR),

    // Airline Report Roles
    isAuthorised_Airline_By_Agency_Annual: checkUserHasRole(
      role.RPT_AIRLINE_AGENCY_ANNUAL_SALES
    ),
    isAuthorised_Airline_By_Agency_Monthly: checkUserHasRole(
      role.RPT_AIRLINE_AGENCY_MONTHLY_SALES
    ),
    isAuthorised_Airline_Sales_Existing_Weekly: checkUserHasRole(
      role.RPT_AIRLINE_SALES_EXISTING_WEEKLY
    ),
    isAuthorised_Airline_Sales_Summary_Annual: checkUserHasRole(
      role.RPT_AIRLINE_SALES_SUMMARY_ANNUAL
    ),
    isAuthorised_Airline_Sales_Summary_Monthly: checkUserHasRole(
      role.RPT_AIRLINE_SALES_SUMMARY_MONTHLY
    ),

    //General Reports
    isAuthorised_Agent_NetOutstanding: checkUserHasRole(
      role.RPT_AGENT_NET_OUTSTANDING
    ),
    isAuthorised_Agent_AuditTrail: checkUserHasRole(role.RPT_AGENT_AUDIT_TRAIL),
    isAuthorised_Pcc_List: checkUserHasRole(role.RPT_DETAILED_PCC),
    isAuthorised_Agent_Extended: checkUserHasRole(role.RPT_AGENCY_EXTENDED),
    isAuthorised_BDM_Weekly: checkUserHasRole(role.RPT_BDM_WEEKLY),
    isAuthorised_System_Extended: checkUserHasRole(
      role.RPT_AGENCY_SYSTEM_EXTENDED
    ),
    isAuthorised_Product_Sales: checkUserHasRole(
      role.RPT_GENERAL_PRODUCT_SALES
    ),
    isAuthorised_Land_Overrides: checkUserHasRole(role.DSB_LAND_OVERRIDE),
    isAuthorised_EDM_Subscriber_List: checkUserHasRole(
      role.RPT_EDM_SUBSCRIBER_LIST
    ),
    isAuthorised_Private_Fares: checkUserHasRole(role.RPT_PRIVATE_FARES),
    isAuthorised_Admin_Version: checkUserHasRole(role.ADMIN_VERSION),
    isAuthorised_Admin_Panel: true,
    isAuthorised_Admin_Reference: checkUserHasRole(role.VIEW_REFERENCE_LIST),
    isAuthorised_Admin_Email_Builder_Template: checkUserHasRole(
      role.VIEW_EMAIL_TEMPLATE_LIST
    )
  };

  //Check Dashboard
  menu.isAuthorised_EMS_Dashboard =
    menu.isAuthorised_Sales_Dashboard ||
    menu.isAuthorised_Product_Dashboard ||
    menu.isAuthorised_Management_Dashboard;

  //Check Agency Report
  menu.isAuthorised_Agency_Report =
    menu.isAuthorised_Agency_By_Airline_Annual ||
    menu.isAuthorised_Agency_By_Airline_Monthly ||
    menu.isAuthorised_Agency_By_Airline_Comm_Annual ||
    menu.isAuthorised_Agents_Sales_Existing_Annual ||
    menu.isAuthorised_Agents_Sales_Existing_Weekly ||
    menu.isAuthorised_Agents_New_Business ||
    menu.isAuthorised_Agency_Sales_Summary_Annual ||
    menu.isAuthorised_Agency_Sales_Summary_Monthly ||
    menu.isAuthorised_Agents_Ticket_Usage ||
    menu.isAuthorised_Agent_Vs_BSP ||
    menu.isAuthorised_Detailed_Ticket_Report ||
    menu.isAuthorised_Ticket_Sector_Report;

  menu.isAuthorised_Airline_Report =
    menu.isAuthorised_Airline_By_Agency_Annual ||
    menu.isAuthorised_Airline_By_Agency_Monthly ||
    menu.isAuthorised_Airline_Sales_Existing_Weekly ||
    menu.isAuthorised_Airline_Sales_Summary_Annual ||
    menu.isAuthorised_Airline_Sales_Summary_Monthly;

  menu.isAuthorised_General_Report =
    menu.isAuthorised_Agent_Extended ||
    menu.isAuthorised_BDM_Weekly ||
    menu.isAuthorised_System_Extended ||
    menu.isAuthorised_Product_Sales ||
    menu.isAuthorised_Land_Overrides ||
    menu.isAuthorised_EDM_Subscriber_List ||
    menu.isAuthorised_Private_Fares;

  //Check Ticketing
  menu.isAuthorised_Ticketing =
    menu.isAuthorised_Agency_Report || menu.isAuthorised_Airline_Report;

  //Check Reports
  menu.isAuthorised_Reports =
    menu.isAuthorised_Ticketing || menu.isAuthorised_General_Report;

  menu.isAuthorised_Admin_Panel =
    menu.isAuthorised_Admin_Version ||
    menu.isAuthorised_Admin_Reference ||
    menu.isAuthorised_Admin_Email_Builder_Template;
  return menu;
};

export default {
  getUserMenu
};




 