import React from 'react';
import {
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

interface CheckboxProps {
  name: string;
  label: string;
  defaultValues?: boolean;
  required?: boolean;
  disabled?: boolean;
  handleChange?: (value: boolean) => void;
  [key: string]: any;
}

export const CustomCheckbox: React.FC<CheckboxProps> = ({
  name,
  label,
  disabled = false,
  defaultValues = false,
  required = false,
  handleChange,
  ...rest
}) => {

  const { control, setValue, getValues } = useFormContext();

  // Use the default value based on the name prop if not provided
  const defaultValue = defaultValues === undefined ? getValues(name) || false : defaultValues;

  return (
    <Controller
      render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
        <FormControlLabel
          id={name}
          label={label}
          control={<Checkbox />}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.checked);
            if (handleChange) {
              handleChange(e.target.checked);
            }
          }}
          disabled={disabled}
          checked={value}
          {...rest}
        />
      )}
      defaultValue={defaultValue}
      control={control}
      name={name}
    />
  );
};
