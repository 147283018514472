import AgentPrivateFares from '../models/agent_models/agent_private_fares';
import AgentPrivateFaresDetails from '../models/agent_models/agent_private_fares_details';
import AirlineDetails from '../models/airline_details';
import EntityCountry from '../models/entity_country';
import http from './http.service';

const apiEndpoint = '/agent/privatefares';

export async function getEntityCountry(entityId: string) {
  const response = await http.get<EntityCountry[]>(
    apiEndpoint + '/getentitycountry/' + entityId
  );
  return response;
}

export async function getAgentPrivateFares(entityId: string) {
  const response = await http.get<AgentPrivateFares>(
    apiEndpoint + '/' + entityId
  );
  return response;
}

export async function deleteAgentPrivateFare(entityId: string) {
  await http.post(apiEndpoint + '/delete/' + entityId);
}

export async function addEntityCountry(data: EntityCountry) {
  await http.post(apiEndpoint + '/addEntityCountry', data);
}

export async function deleteEntityCountry(data: EntityCountry) {
  await http.post(apiEndpoint + '/deleteEntityCountry', data);
}

export async function getAirlines(signal?: any) {
  const response = await http.get<AirlineDetails[]>(
    apiEndpoint + '/getairlines',
    {
      signal: signal
    }
  );

  return response;
}

export async function addAgentPrivateFare(data: AgentPrivateFaresDetails) {
  await http.post(apiEndpoint + '/create', data);
}

export async function updateAgentPrivateFare(data: AgentPrivateFaresDetails) {
  await http.put(apiEndpoint + '/update', data);
}

export default {
  getAgentPrivateFares,
  getEntityCountry,
  deleteAgentPrivateFare,
  addEntityCountry,
  deleteEntityCountry,
  getAirlines,
  addAgentPrivateFare,
  updateAgentPrivateFare
};
