import React from 'react';
import { Dialog, IconButton } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CloseIcon from '@mui/icons-material/Close';

interface CustomPopupBoxProps {
  isOpen: boolean;
  onClose: () => void;
  richText: string;
}

const CustomPopupBox: React.FC<CustomPopupBoxProps> = ({ isOpen, onClose, richText }) => {

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="popup">
        <IconButton className="close-btn" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div className="rich-text">
          <ReactQuill
            value={richText}
            readOnly={true}
            theme="snow"
            modules={{ toolbar: false }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CustomPopupBox;
