import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Popover,
  TextField,
  Box,
  List,
  ListItem,
  Typography
} from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

interface CustomButtonDropdownProps {
  isSearchBar?: boolean;
  isSelectAll?: boolean;
  data: { [key: string]: boolean };
  onSave: (data: { [key: string]: boolean }) => void;
  onClose?: () => void;
  buttonName: string;
  title: string;
}

const CustomButtonDropdown: React.FC<CustomButtonDropdownProps> = ({
  isSearchBar = true,
  isSelectAll = true,
  data,
  onSave,
  onClose,
  buttonName,
  title
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [search, setSearch] = useState('');
  const [items, setItems] = useState(data);

  useEffect(() => {
    setItems(data);
  }, [data]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onClose) onClose();
  };

  const handleSave = () => {
    onSave(items);
    handleClose();
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newItems = Object.keys(items).reduce((acc, key) => {
      acc[key] = event.target.checked;
      return acc;
    }, {} as { [key: string]: boolean });
    setItems(newItems);
  };

  const handleItemChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setItems({ ...items, [name]: event.target.checked });
  };

  const filteredItems = Object.keys(items).filter(
    item => item.toUpperCase().includes(search.toUpperCase())
  );

  const allSelected = Object.values(items).every(Boolean);

  return (
    <div>
      <Button variant="outlined" startIcon={<ViewColumnIcon />} color='secondary' onClick={handleClick}>
        {buttonName}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { maxWidth: '500px', width: '100%', marginTop: '20px' }
        }}
      >
        <Box p={2}>
          {isSearchBar && (
            <TextField
              fullWidth
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
              margin="normal"
              autoFocus={false}
            />
          )}
          {isSelectAll && (
            <ListItem>
              <FormControlLabel
                control={<Checkbox checked={allSelected} onChange={handleSelectAll} />}
                label="SELECT ALL"
              />
            </ListItem>
          )}
          <List dense style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {filteredItems.map((item, index) => (
              <ListItem key={index}>
                <FormControlLabel
                  control={<Checkbox checked={items[item]} onChange={handleItemChange(item)} />}
                  label={item.toUpperCase()}
                />
              </ListItem>
            ))}
          </List>
          <Box textAlign="center" mt={2}>
            <Button onClick={handleSave} color="primary" variant="contained" style={{ marginRight: 8 }}>
              Save
            </Button>
            <Button onClick={handleClose} color="secondary" variant="contained">
              Close
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default CustomButtonDropdown;
