import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import ProductLandSalesOverrideService from 'src/ems/services/product.landoverride.service';
import ProductLandSalesOverrideAndStatus from '../models/product_land_sales_override_and_status';

interface ProductLandSalesOverrideAndStatusState {
  productLandSalesOverrideAndStatus: ProductLandSalesOverrideAndStatus;
}

const initialState: ProductLandSalesOverrideAndStatusState = {
  productLandSalesOverrideAndStatus: []
};

const slice = createSlice({
  name: 'productLandSalesOverrideAndStatus',
  initialState,
  reducers: {
    getProductLandSalesOverrideAndStatus(
      state: ProductLandSalesOverrideAndStatusState,
      action: PayloadAction<{
        productLandSalesOverrideAndStatus: ProductLandSalesOverrideAndStatus;
      }>
    ) {
      const { productLandSalesOverrideAndStatus } = action.payload;

      state.productLandSalesOverrideAndStatus =
        productLandSalesOverrideAndStatus;
    }
  }
});

export const reducer = slice.reducer;

export const getProductLandSalesOverrideAndStatus =
  (periodId: number): AppThunk =>
  async (dispatch): Promise<void> => {
    let { data } =
      await ProductLandSalesOverrideService.getProductLandSalesOverrideAndStatus(
        periodId
      );
    const productLandSalesOverrideAndStatus = Array.isArray(data) ? data : [];
    dispatch(
      slice.actions.getProductLandSalesOverrideAndStatus({
        productLandSalesOverrideAndStatus
      })
    );
  };

export default slice;
