import { FC, createContext, useEffect, useReducer } from 'react';
import authService, {
  checkUserHasRole,
  checkTokenExpiry
} from 'src/ems/services/auth.service';
import PropTypes from 'prop-types';
import { authReducer } from 'src/reducers/auth.reducer';
import { AuthState, AuthProviderProps } from 'src/models/auth';
import { useNavigate } from 'react-router-dom';
import { role } from 'src/ems/enums/roles.enums';

export interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  verify: (code: string, userId: string, username: string) => Promise<void>;
  logout: () => void;
  bypass: (email: string) => Promise<void>;
}

const initialAuthState: AuthState = {
  authTypeId: 0,
  isTwoFactorSetup: null,
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  verify: () => Promise.resolve(),
  bypass: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(authReducer, initialAuthState);
  const navigate = useNavigate();

  const checkTokenValidity = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    if (state.isAuthenticated && accessToken) {
      const isExpired = checkTokenExpiry(accessToken);
      if (isExpired) {
        authService.clearSession();
        let auth = authService.getAuthState(null, false, true, true);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            auth
          }
        });
        // Do not navigate immediately, defer until after rendering
        return true;
      }
    }
    return false;
  };

  // Call the token validity check every time the AuthContext is accessed
  const needsNavigation = checkTokenValidity();

  useEffect(() => {
    if (needsNavigation) {
      navigate('/');
    }

    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {
          let user = authService.getUserFromJWTToken(accessToken);
          let auth = authService.getAuthState(user, true, true, false);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              auth
            }
          });
        } else {
          let auth = authService.getAuthState(null, false, true, false);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              auth
            }
          });
        }
      } catch (err) {
        let auth = authService.getAuthState(null, false, false, false);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            auth
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    let auth = await authService.login(email, password);
    dispatch({
      type: 'LOGIN',
      payload: {
        auth
      }
    });

    if (auth.isAuthenticated) {
      let checkRole = checkUserHasRole(role.DBS_SALES);
      // if(checkRole)
      //   navigate('accent-sidebar/ems_dashboards/sales_dashboard');
      // else
      navigate('accent-sidebar/agent');
    }
  };

  const bypass = async (email: string): Promise<void> => {
    let auth = await authService.bypass(email);
    dispatch({
      type: 'LOGIN',
      payload: {
        auth
      }
    });

    if (auth.isAuthenticated) {
      let checkRole = checkUserHasRole(role.DBS_SALES);
      if (checkRole)
        // navigate('accent-sidebar/ems_dashboards/sales_dashboard');
        //else
        navigate('accent-sidebar/agent');
    }
  };

  const verify = async (
    code: string,
    userId: string,
    username: string
  ): Promise<void> => {
    let auth = await authService.verify(code, userId, username);
    dispatch({
      type: 'LOGIN',
      payload: {
        auth
      }
    });

    if (auth.isAuthenticated) {
      let checkRole = checkUserHasRole(role.DBS_SALES);
      // if(checkRole)
      //navigate('accent-sidebar/ems_dashboards/sales_dashboard');
      //else
      navigate('accent-sidebar/agent');
    }
  };

  const logout = async (): Promise<void> => {
    authService.setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        verify,
        bypass
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
