import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const List = Loader(
  lazy(() => import('src/ems/components/contracts/Land/List'))
);

const View = Loader(
  lazy(() => import('src/ems/components/contracts/Land/View'))
);

const Create = Loader(
  lazy(() => import('src/ems/components/contracts/Land/Create'))
);

const Support = Loader(
  lazy(() => import('src/ems/components/contracts/Settings/Support'))
);

const ContractYear = Loader(
  lazy(() => import('src/ems/components/contracts/Settings/ContractYear'))
);

const ContractYearPeriod = Loader(
  lazy(() => import('src/ems/components/contracts/Settings/ContractYearPeriod'))
);

const ContractPeriod = Loader(
  lazy(() => import('src/ems/components/contracts/Settings/ContractPeriod'))
);

const ContractSupplierProduct = Loader(
  lazy(
    () =>
      import('src/ems/components/contracts/Settings/ContractSupplierProduct')
  )
);

/* const contractsRoutes = [
  {
    path: '',
    element: <Navigate to="list" replace />
  },
  {
    path: 'list',

    element: <List />
  }
];
 */

const contractsRoutes = [
  {
    path: '',
    element: <Navigate to="land" replace />
  },
  {
    path: 'land',
    children: [
      {
        path: 'list',
        element: <List />
      },
      {
        path: 'view',
        children: [
          {
            path: '',
            element: <Navigate to="1" replace />
          },
          {
            path: ':contractId',
            element: <View />
          }
        ]
      },
      {
        path: 'Create',
        element: <Create />
      }
    ]
  },
  {
    path: 'settings',
    children: [
      {
        path: 'support',
        element: <Support />
      },
      {
        path: 'contractyear',
        element: <ContractYear />
      },
      {
        path: 'contractyearperiod',
        element: <ContractYearPeriod />
      },
      {
        path: 'contractperiod',
        element: <ContractPeriod />
      },
      {
        path: 'contractsupplierproduct',
        element: <ContractSupplierProduct />
      }
    ]
  }
];

export default contractsRoutes;
