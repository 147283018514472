import { createSlice } from '@reduxjs/toolkit';
import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import AgentService from 'src/ems/services/agent.service';
import EntitySummary from 'src/ems/models/entity_summary';

interface AgentState {
  agents: EntitySummary[];
}

const initialState: AgentState = {
  agents: []
};

const slice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    getAgents(
      state: AgentState,
      action: PayloadAction<{ agents: EntitySummary[] }>
    ) {
      const { agents } = action.payload;

      state.agents = agents;
    }
  }
});

export const reducer = slice.reducer;

export const getAgents =
  (signal?: any): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      let { data } = await AgentService.getAgents(signal);

      const agents = Array.isArray(data) ? data : [];

      dispatch(slice.actions.getAgents({ agents }));
    } catch (error) {
      if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
        console.error(error);
      }
    }
  };

export default slice;
