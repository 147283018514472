import React, { useEffect, useState } from 'react';
import {
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  Container,
  Slide,
  IconButton,
  Tooltip,
  InputAdornment
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from 'src/components/LogoSign';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  changePassword,
  codeVerification,
  sendVerification
} from 'src/ems/services/forgot.password.service';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  textFieldError: {
    animation: '$shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
    border: '2px solid red'
  },
  '@keyframes shake': {
    '10%, 90%': {
      transform: 'translate3d(-1px, 0, 0)'
    },
    '20%, 80%': {
      transform: 'translate3d(2px, 0, 0)'
    },
    '30%, 50%, 70%': {
      transform: 'translate3d(-4px, 0, 0)'
    },
    '40%, 60%': {
      transform: 'translate3d(4px, 0, 0)'
    }
  },
  errorText: {
    color: 'red',
    marginTop: '8px'
  },
  greenText: {
    color: 'green'
  },
  conditionLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  checkIcon: {
    marginRight: 1,
    color: 'black'
  }
}));

const ForgotPassword: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [screenTitle, setScreenTitle] = useState('Forgot Password');
  const [username, setUsername] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [screen, setScreen] = useState('UsernameScreen');
  const [conditionsMet, setConditionsMet] = useState({
    hasNumber: false,
    hasLetter: false,
    hasSpecialCharacter: false,
    hasUppercase: false,
    hasLowercase: false,
    hasValidLength: false,
    hasPasswordMatch: false
  });
  const [verificationError, setVerificationError] = useState('');
  const [isSendVerificationDisable, setIsSendVerificationDisable] = useState<boolean>(false);
  const [isVerifyDisable, setIsVerifyDisable] = useState<boolean>(false);
  const [isChangePasswordButtonDisabled, setIsChangePasswordButtonDisabled] = useState(true);

  useEffect(() => {
    // Check if all conditions are met
    const allConditionsMet = Object.values(conditionsMet).every(condition => condition);
    setIsChangePasswordButtonDisabled(!allConditionsMet);
  }, [conditionsMet]);

  const handleSend = async () => {
    try {
      setIsSendVerificationDisable(true);

      setVerificationError('');
      const response = await sendVerification(username);
      if (response.status === 200) {
        setScreen('VerifyScreen');
        setScreenTitle('Verify Code');
        setIsSendVerificationDisable(false);
      }
    } catch (error) {
      setIsSendVerificationDisable(false);
      if (error.response && error.response.data) {
        setVerificationError(error.response.data);
      } else {
        if (error.response && error.response.status === 400) {
          setVerificationError('Bad request. Please check your input.');
        } else {
          setVerificationError(
            'An error occurred while sending the verification.'
          );
        }
      }
    }
  };

  const handleVerify = async () => {
    try {
      setIsVerifyDisable(true);

      setVerificationError('');
      const response = await codeVerification(username, verifyCode);
      if (response.status === 200) {
        setScreenTitle('Change Password');
        setScreen('ChangePasswordScreen');
        setIsVerifyDisable(false);
      }
    } catch (error) {
      setIsVerifyDisable(false);
      if (error.response && error.response.data) {
        setVerificationError(error.response.data);
      } else {
        if (error.response && error.response.status === 400) {
          setVerificationError('Bad request. Please check your input.');
        } else {
          setVerificationError(
            'An error occurred while sending the verification.'
          );
        }
      }
    }
  };

  const handleChangePassword = async () => {
    try {
      setIsChangePasswordButtonDisabled(true);

      setVerificationError('');
      const response = await changePassword(username, newPassword);
      if (response.status === 200) {
        enqueueSnackbar('Password Changed Successfully!! Please Login Again', {
          variant: 'success'
        });
        navigate('/')
      }
    } catch (error) {
      setIsChangePasswordButtonDisabled(false);
      if (error.response && error.response.data) {
        setVerificationError(error.response.data);
      } else {
        if (error.response && error.response.status === 400) {
          setVerificationError('Bad request. Please check your input.');
        } else {
          setVerificationError(
            'An error occurred while sending the verification.'
          );
        }
      }
    }
  };

  const handleBack = () => {
    setScreenTitle('Forgot Password');
    setScreen('UsernameScreen');
  };

  const handleNewPasswordChange = (value: string) => {
    const hasNumber = /\d/.test(value);
    const hasLetter = /[a-zA-Z]/.test(value);
    const hasSpecialCharacter = /[^a-zA-Z0-9]/.test(value);
    const hasUppercase = /[A-Z]/.test(value);
    const hasLowercase = /[a-z]/.test(value);
    const hasValidLength = value.length >= 8 && value.length <= 36;
    const hasPasswordMatch = value === confirmPassword;

    setConditionsMet({
      hasNumber,
      hasLetter,
      hasSpecialCharacter,
      hasUppercase,
      hasLowercase,
      hasValidLength,
      hasPasswordMatch
    });
  };

  const handleConfirmPasswordChange = (value: string) => {
    const hasPasswordMatch = value === newPassword;

    setConditionsMet({
      ...conditionsMet,
      hasPasswordMatch
    });
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <>
      <Logo />
      <Container
        maxWidth="sm"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Paper elevation={3} style={{ padding: '2rem', width: '100%' }}>
          <Typography variant="h5" style={{ textAlign: 'center' }}>
            {screenTitle}
          </Typography>
          {screen === 'VerifyScreen' && (<Typography variant="h6" style={{ textAlign: 'left', fontWeight: 600, marginTop: 30 }}>
            Please check your registered email for code.
          </Typography>)}
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            {screen === 'UsernameScreen' && (
              <Slide direction="left" in={screen === 'UsernameScreen'}>
                <Grid container direction="column" alignItems="center">
                  <TextField
                    label="Enter Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    margin="normal"
                    fullWidth
                    error={Boolean(verificationError)} // Set error prop based on verificationError state
                    helperText={verificationError} // Display error message below the text field
                    InputProps={{
                      classes: {
                        error: classes.textFieldError // Apply custom styles for error state
                      }
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    disabled={isSendVerificationDisable} // Disable button if username is empty
                    style={{ marginTop: '1rem' }}
                  >
                    Send Verification
                  </Button>
                </Grid>
              </Slide>
            )}
            {screen === 'VerifyScreen' && (
              <Slide direction="right" in={screen === 'VerifyScreen'}>
                <Grid container direction="column" alignItems="center">
                  <TextField
                    label="Enter Verification Code"
                    value={verifyCode}
                    onChange={(e) => setVerifyCode(e.target.value.toUpperCase().slice(0, 6))}
                    margin="normal"
                    fullWidth
                    error={Boolean(verificationError)} // Set error prop based on verificationError state
                    helperText={verificationError} // Display error message below the text field
                    InputProps={{
                      classes: {
                        error: classes.textFieldError // Apply custom styles for error state
                      }
                    }}
                  />
                  <Grid
                    container
                    justifyContent="center"
                    style={{ marginTop: '1rem' }}
                  >
                    <Button
                      onClick={handleBack}
                      style={{ marginRight: '0.5rem' }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isVerifyDisable}
                      onClick={handleVerify}
                    >
                      Verify
                    </Button>
                  </Grid>
                </Grid>
              </Slide>
            )}
            {screen === 'ChangePasswordScreen' && (
              <Slide direction="right" in={screen === 'ChangePasswordScreen'}>
                <Grid container direction="column" alignItems="flex-start">
                  <TextField
                    label="New Password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      handleNewPasswordChange(e.target.value);
                    }}
                    type={newPasswordVisible ? 'text' : 'password'}
                    margin="normal"
                    error={Boolean(verificationError)} // Set error prop based on verificationError state
                    helperText={verificationError} // Display error message below the text field
                    fullWidth
                    InputProps={{
                      classes: {
                        error: classes.textFieldError // Apply custom styles for error state
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            title={
                              newPasswordVisible
                                ? 'Hide password'
                                : 'Show password'
                            }
                            placement="top"
                          >
                            <IconButton onClick={toggleNewPasswordVisibility}>
                              {newPasswordVisible ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      handleConfirmPasswordChange(e.target.value);
                    }}
                    type={confirmPasswordVisible ? 'text' : 'password'}
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            title={
                              confirmPasswordVisible
                                ? 'Hide password'
                                : 'Show password'
                            }
                            placement="top"
                          >
                            <IconButton
                              onClick={toggleConfirmPasswordVisibility}
                            >
                              {confirmPasswordVisible ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                  />
                  {Object.entries(conditionsMet).map(
                    ([condition, met], index) => {
                      let labelText = '';
                      if (condition === 'hasNumber')
                        labelText = 'At least one number';
                      else if (condition === 'hasLetter')
                        labelText = 'At least one letter';
                      else if (condition === 'hasSpecialCharacter')
                        labelText = 'At least one special character';
                      else if (condition === 'hasUppercase')
                        labelText = 'At least one uppercase character';
                      else if (condition === 'hasLowercase')
                        labelText = 'At least one lowercase character';
                      else if (condition === 'hasValidLength')
                        labelText = 'Length of the password between 8 and 36';
                      else if (condition === 'hasPasswordMatch')
                        labelText = 'Password match';

                      return (
                        <Grid
                          key={index}
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          {met ? (
                            <CheckCircleOutlineIcon
                              className={classes.greenText}
                            />
                          ) : (
                            <CheckCircleOutlineIcon />
                          )}
                          <Typography
                            className={
                              met ? classes.greenText : classes.checkIcon
                            }
                          >
                            {labelText}
                          </Typography>
                        </Grid>
                      );
                    }
                  )}
                  <Grid
                    container
                    justifyContent="center"
                    style={{ marginTop: '1rem' }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleChangePassword}
                      disabled={isChangePasswordButtonDisabled}
                    >
                      Change Password
                    </Button>
                  </Grid>
                </Grid>
              </Slide>
            )}
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default ForgotPassword;
