import { setHours, setMinutes, subDays, addDays } from 'date-fns';
import _ from 'lodash';
import { mock } from 'src/utils/axios';
import Reference from 'src/ems/models/reference';

let references: Reference[] = [
  {
    referenceId: 550,
    refText: 'Australia',
    refType: 'CTRY',
    displayText: null,
    cssText: null
  },
  {
    referenceId: 2,
    refText: 'China',
    refType: 'CTRY',
    displayText: null,
    cssText: null
  },
  {
    referenceId: 4,
    refText: 'Thailand',
    refType: 'CTRY',
    displayText: null,
    cssText: null
  }
];

mock.onGet('/api/reference/meetings').reply(200, { references });
