import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Button, Popover, Chip } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { DateFilter } from 'src/ems/enums/date.filter.enums';

interface DateFilterChipProps {
  name: string;
  defaultValue: DateFilter;
}

const DateFilterChip: React.FC<DateFilterChipProps> = ({ name, defaultValue }) => {
  const { control, setValue } = useFormContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectedFilter, setSelectedFilter] = React.useState<DateFilter>(defaultValue);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterSelection = (filter: DateFilter) => {
    setSelectedFilter(filter);
    setValue(name, filter);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover-filter' : undefined;

  const filterOptions = Object.values(DateFilter);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={() => (
        <>
          <Button onClick={handleClick} startIcon={<FilterListIcon />}>
            {selectedFilter}
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box padding={2}>
              {filterOptions.map(option => (
                <Chip
                  key={option}
                  label={option}
                  onClick={() => handleFilterSelection(option)}
                  style={{
                    margin: '5px',
                    cursor: 'pointer',
                    backgroundColor: selectedFilter === option ? 'green' : '#f0f0f0',
                    color: selectedFilter === option ? 'white' : 'black',
                  }}
                  variant="outlined"
                />
              ))}
            </Box>
          </Popover>
        </>
      )}
    />
  );
};

export default DateFilterChip;
