import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const BrowseLandDataFile = Loader(
  lazy(() => import('src/ems/components/products/land/sales/BrowseDataFile/List'))
);

const BrowseLandDataFileDetailView = Loader(
  lazy(() => import('src/ems/components/products/land/sales/BrowseDataFile/View'))
);

const FileStatus = Loader(
  lazy(() => import('src/ems/components/products/land/sales/FileStatus/View'))
);

const LoadDataFile = Loader(
  lazy(() => import('src/ems/components/products/land/sales/LoadDataFile/View/LoadDataFile'))
);

const CheckDataFile = Loader(
  lazy(() => import('src/ems/components/products/land/sales/LoadDataFile/View'))
);

const LandOverride = Loader(
  lazy(() => import('src/ems/components/products/land/sales/LandOverride/View'))
);

const LandOverrideRemittances = Loader(
  lazy(() => import('src/ems/components/products/land/sales/LandOverrideRemittances/LandOverrideRemittances'))
);

const LandOverrideViewBreakdown = Loader(
  lazy(() => import('src/ems/components/products/land/sales/LandOverride/View/ViewBreakdown'))
);

const LandOverrideViewCurrentBreakdown = Loader(
  lazy(() => import('src/ems/components/products/land/sales/LandOverride/View/ViewCurrentBreakdown'))
);

const LandOverrideViewCurrentPayFile = Loader(
  lazy(() => import('src/ems/components/products/land/sales/LandOverride/View/ViewPayFile'))
);


const LandOverrideViewCurrentBatchHistory = Loader(
  lazy(() => import('src/ems/components/products/land/sales/LandOverride/View/ViewBatchHistory'))
);


const LandOverrideViewCurrentBreakDownHistory = Loader(
  lazy(() => import('src/ems/components/products/land/sales/LandOverride/View/ViewBreakdownHistory'))
);


const productsRoutes = [
  {
    path: '',
    element: <Navigate to="land" replace />
  },
  {
    path: 'land/sales',
    children: [
      {
        path: 'browse-data-files/normal',
        element: <BrowseLandDataFile />
      },     
      {
        path: 'browse-data-files/adjustment',
        element: <BrowseLandDataFile />
      },         
      {
        path: 'view',
        children: [
          {
            path: '',
            element: <Navigate to="1" replace />
          },
          {
            path: ':fileImportId/normal',
            element: <BrowseLandDataFileDetailView />
          },
          {
            path: ':fileImportId/adjustment',
            element: <BrowseLandDataFileDetailView />
          }
        ]
      },      
      {
        path: 'file-status/view',
        element: <FileStatus />
      },
      {
        path: 'check-data-file',
        element: <CheckDataFile />
      },
      {
        path: 'load-data-file',
        element: <LoadDataFile />
      },      
      {
        path: 'land-override',
        element: <LandOverride />
      },
      {
        path: 'override-remittances',
        element: <LandOverrideRemittances />
      },      
      {
        path: 'view/breakdown',
        element: <LandOverrideViewBreakdown />
      },
      {
        path: 'view/currentbreakdown',
        element: <LandOverrideViewCurrentBreakdown />
      },
      {
        path: 'view/currentpayfile',
        element: <LandOverrideViewCurrentPayFile />
      },
      {
        path: 'view/batchhistory',
        element: <LandOverrideViewCurrentBatchHistory />
      },
      {
        path: 'view/breakdownhistory',
        element: <LandOverrideViewCurrentBreakDownHistory />
      }
    ]
  }
];

export default productsRoutes;
