export function getFlexmonsterConditions(conditionName?: string) {
    if(conditionName === null) return null

    if(conditionName == 'sales_format') {
        return [
            {
                formula: "#value == 0",
                format: {
                  backgroundColor: "#f0e68c",
                  color: "#000000"
                },
              },
              {
                formula: "#value < 0",
                format: {
                  backgroundColor: "#ffb6c1",
                  color: "#000000"
                },
              }
        ]
    }
}

export function getFlexmonsterFormats(formatName?: string[]) {
  return [
    {
      name: "",
      decimalPlaces: 2
    },
    {
      name: "decimalFormat",
      thousandsSeparator: ",",
      nullValue: "",
      decimalPlaces: 2
    },
    {
      name: "percentFormat",
      isPercent: true,
      decimalPlaces: 2,
      currencySymbol: "%",
      divideByZeroValue: "100.00%"
    },
    {
      name: "currency",
      thousandsSeparator: ",",
      nullValue: "",
      decimalPlaces: 0,
      currencySymbol: "$"
    },
    {
      name: 'passengerFormat',
      thousandsSeparator: ",",
      nullValue: "",
      decimalPlaces: -1
    },
    {
      name: 'yearFormat',
      thousandsSeparator: "",
      decimalSeparator: "",
      decimalPlaces: 0
    }
  ];
}

function exportHandler(params) {
  this.pivot.exportTo(params.type, params.config)
}

export function getFlexmonsterToolbar(toolbar: any, title: string, fileName: string) {
  var tabs = toolbar.getTabs();
    toolbar.getTabs = function() {
      delete tabs[0]; // Connect
      delete tabs[1]; // Open
      delete tabs[2]; // Save

      // Handel Export
      delete tabs[3].menu[1]; // Export to HTML

      // Export to CSV
      tabs[3].menu[2].handler = exportHandler;
      tabs[3].menu[2].args = {
        type: 'csv',
        config: {
          filename: fileName
        }
      }

      // Export to Excel
      tabs[3].menu[3].handler = exportHandler;
      tabs[3].menu[3].args = {
        type: 'excel',
        config: {
          filename: fileName
        }
      }

      delete tabs[3].menu[4]; // Export to Image

      // Export to Excel
      tabs[3].menu[5].handler = exportHandler;
      tabs[3].menu[5].args = {
        type: 'pdf',
        config: {
          filename: fileName
        }
      }

      delete tabs[4];
      delete tabs[7];
      delete tabs[8];
      delete tabs[9];
      delete tabs[10];


      return tabs;
    };


}