import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import ProductLandSalesService from 'src/ems/services/product.landsalesdata.service';
import ProductLandSalesSummary from 'src/ems/models/product_land_sales_summary';

interface ProductLandSalesDataState {
  productLandSalesData: ProductLandSalesSummary[];
}

const initialState: ProductLandSalesDataState = {
  productLandSalesData: []
};

const slice = createSlice({
  name: 'productLandSales',
  initialState,
  reducers: {
    getProductLandSalesData(
      state: ProductLandSalesDataState,
      action: PayloadAction<{ productLandSalesData: ProductLandSalesSummary[] }>
    ) {
      const { productLandSalesData } = action.payload;

      state.productLandSalesData = productLandSalesData;
    }
  }
});

export const reducer = slice.reducer;

export const getProductLandSalesData =
  (fileType: string): AppThunk =>
  async (dispatch): Promise<void> => {
    let { data } = await ProductLandSalesService.getProductLandSalesDataList(
      fileType
    );
    const productLandSalesData = Array.isArray(data) ? data : [];
    dispatch(slice.actions.getProductLandSalesData({ productLandSalesData }));
  };

export default slice;
