export interface private_fare_report_columns {
  agentName: boolean;
  fareName: boolean;
  supplierCode: boolean;
  fareAccountCode: boolean;
  tourCode: boolean;
  typeOfFareDetails: boolean;
  fareStatusDetails: boolean;
  markUp: boolean;
  fareBasis: boolean;
  dateAdded: boolean;
  dateExpire: boolean;
  margin_In_Dollar: boolean;
  margin_In_Percentage: boolean;
  ticketFee: boolean;
  commission: boolean;
}

export function defaultPrivateFareReportColumns(): private_fare_report_columns {
  return {
    agentName: true,
    fareName: true,
    supplierCode: true,
    fareAccountCode: true,
    tourCode: true,
    typeOfFareDetails: true,
    fareStatusDetails: true,
    markUp: true,
    fareBasis: true,
    dateAdded: true,
    dateExpire: true,
    margin_In_Dollar: true,
    margin_In_Percentage: true,
    ticketFee: true,
    commission: true
  };
}
