import React, { useState, useEffect } from 'react';
import {
  Button,
  Popover,
  Box,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography
} from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format, addDays, isAfter, parseISO } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { formatDateToString } from 'src/ems/services/common.service';

const hours = Array.from({ length: 24 }, (_, i) => new Date(0, 0, 0, i, 0, 0, 0));

enum Frequency {
  Daily = 1,
  Weekly = 2,
  Monthly = 3
}

export interface scheduleDetails {
  schedulerStartDate?: string | null;
  nextSchedulerDate?: string | null;
  time?: string;
  schedulerFrequency?: Frequency;
  IsLastSchedulerSuccess?: boolean;
  roleId?: number;
  preferenceTypeId?: number;
  isCancel?: boolean;
}

interface CustomSchedulerButtonProps {
  buttonName: string;
  title: string;
  onSave: (details: scheduleDetails) => void;
  onCancle: (details: scheduleDetails) => void;
  onClose?: () => void;
  initialDetails?: scheduleDetails | null;
}

// Helper function to format the time string
const formatTime = (date: Date): string => date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

const parseTimeString = (timeString: string): Date | null => {
  const [time, modifier] = timeString.split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  if (modifier === 'PM' && hours < 12) {
    hours += 12;
  }
  if (modifier === 'AM' && hours === 12) {
    hours = 0;
  }

  return new Date(0, 0, 0, hours, minutes, 0);
};

const CustomSchedulerButton: React.FC<CustomSchedulerButtonProps> = ({
  buttonName,
  title,
  onSave,
  onClose,
  onCancle,
  initialDetails = null
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [selectedDate, setSelectedDate] = useState<string | null>(
    initialDetails?.schedulerStartDate ? initialDetails.schedulerStartDate : formatDateToString(addDays(new Date(), 1))
  );
  const [selectedTime, setSelectedTime] = useState<string>(initialDetails?.time ?? formatTime(hours[0]));
  const [frequency, setFrequency] = useState<Frequency>(initialDetails?.schedulerFrequency ?? Frequency.Daily);
  const [nextSchedulerDate, setNextSchedulerDate] = useState<string | null>(
    initialDetails?.nextSchedulerDate ? initialDetails.nextSchedulerDate : null
  );

  useEffect(() => {
    if (initialDetails) {
      try {
        console.log('Setting initial values from initialDetails');
        setSelectedDate(initialDetails.schedulerStartDate ? initialDetails.schedulerStartDate : null);
        setSelectedTime(initialDetails.time ?? formatTime(hours[0]));
        setFrequency(initialDetails.schedulerFrequency ?? Frequency.Daily);
        setNextSchedulerDate(initialDetails.nextSchedulerDate ? initialDetails.nextSchedulerDate : null);
      } catch (error) {
        console.error('Error setting initial details: ', error);
      }
    }
  }, [initialDetails]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onClose) onClose();
  };

  const handleSave = () => {
    const details: scheduleDetails = {
      schedulerStartDate: selectedDate,
      time: selectedTime,
      schedulerFrequency: frequency,
      nextSchedulerDate: selectedDate,
      isCancel: false
    };
    onSave(details);
    setNextSchedulerDate(selectedDate); // Set nextSchedulerDate to schedulerStartDate
  };

  const handleCancle = () => {
    const details: scheduleDetails = {
      isCancel: true
    };
    onSave(details);
    setNextSchedulerDate(null);
    handleClose()
  };

  const open = Boolean(anchorEl);
  const isDateDisabled = (date: Date) => isAfter(new Date(), date); // Block past dates including today

  return (
    <div>
      <Button variant="outlined" startIcon={<AccessTimeFilledIcon />} color='secondary' onClick={handleClick}>
        {buttonName}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { maxWidth: '500px', width: '100%', marginTop: '20px' }
        }}
      >
        <Box p={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DatePicker
                  label="Start Date"
                  value={selectedDate ? parseISO(selectedDate) : null}
                  onChange={(newValue) => setSelectedDate(formatDateToString(newValue))}
                  shouldDisableDate={isDateDisabled}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth 
                      value={selectedDate ? format(parseISO(selectedDate), 'dd/MM/yyyy') : ''}
                      inputProps={{ readOnly: true }}
                    />
                  )}
                  inputFormat="dd/MM/yyyy"
                />
              </Grid>
              <Grid item xs={12}>
                <RadioGroup 
                  value={frequency ? frequency.toString() : Frequency.Daily.toString()}
                  onChange={(e) => setFrequency(Number(e.target.value) as Frequency)}
                >
                  <FormControlLabel value={Frequency.Daily.toString()} control={<Radio />} label="Daily" />
                  <FormControlLabel value={Frequency.Weekly.toString()} control={<Radio />} label="Weekly" />
                  <FormControlLabel value={Frequency.Monthly.toString()} control={<Radio />} label="Monthly" />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="div" gutterBottom>
                  <strong>Next Schedule Date: </strong>
                  <u>{nextSchedulerDate ? `${format(parseISO(nextSchedulerDate), 'dd/MM/yyyy')}` : 'Scheduler Not Set'}</u>
                </Typography>
              </Grid>
            </Grid>
          </LocalizationProvider>
          <Box textAlign="center" mt={2}>
            <Button onClick={handleSave} color="primary" variant="contained" style={{ marginRight: 8 }}>
              Save
            </Button>
            <Button onClick={handleClose} color="primary" variant="outlined" style={{ marginRight: 8 }}>
              Close
            </Button>
            <Button onClick={handleCancle} color="secondary" variant="contained">
              Cancel Scheduler
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default CustomSchedulerButton;
