import http_report from './http.report.service'
import httpService from './http.service';
import report_parameters from '../models/report_models/report_parameters';
import { reducer } from './../../slices/projects_board';
import dashboard_parameters from '../models/dashboard_models/dashboard_parameters';
import { formatDate, isDateString } from './common.service';

const apiEndpoint = '/dashboard';

export async function getSalesDashboard(data: dashboard_parameters) {
    const response = await http_report.post(
        apiEndpoint + '/SalesDashboard',
        data
    );

    return response.data
}

export async function getSalesDashboard_ems(data: dashboard_parameters) {
    const response = await httpService.post(
        apiEndpoint + '/SalesDashboard',
        data
    );

    return response.data
}


export async function getManagementDashboard(data: dashboard_parameters) {
    const response = await http_report.post(
        apiEndpoint + '/ManagementDashboard',
        data
    );

    return response.data
}

export async function getProductDashboard(data: dashboard_parameters) {
    const response = await httpService.post(
        apiEndpoint + '/ProductDashboard',
        data
    );

    return response.data
}

export function getDashboardParameters(data: any): dashboard_parameters {
    let parameters: dashboard_parameters = {};

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];

            if (Array.isArray(value) && value.every(item => item && typeof item.value === 'number')) {
                parameters[key] = value.some(item => item.value === 0) ? null : value.map((item: any) => item.value.toString());
            }
            else if(typeof value === 'number') {
                parameters[key] = value === 0 ? null : value;
            }
            else if (typeof value === 'string' && isDateString(value)) {
                // If the value is a date string, extract only the date part.
                let date = new Date(value);
                parameters[key] = formatDate(date);
            }
            else {
                parameters[key] = value;
            }
        }
    }

    return parameters;
}