import React, {
  useState,
  useEffect,
  useCallback,
  ReactElement,
  Ref,
  forwardRef,
  useRef
} from 'react';
import {
  Box,
  Divider,
  IconButton,
  List,
  CircularProgress,
  ListItem,
  Grid,
  InputBase,
  Tooltip,
  Typography,
  Card,
  Dialog,
  alpha,
  Slide,
  styled,
  useTheme,
  Table,
  TableRow,
  TableBody
} from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import Scrollbar from 'src/components/Scrollbar';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'src/store';
import TableCell from '@mui/material/TableCell';
import debounce from 'lodash/debounce';
import { TransitionProps } from '@mui/material/transitions';
import { getAgents } from 'src/ems/slices/agent';
import { getSuppliers } from 'src/ems/slices/supplier';
import { getContacts } from 'src/ems/slices/contact';
import CommonService from 'src/ems/services/common.service';

const wait = (time) => new Promise((res) => setTimeout(res, time));

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const SearchInputWrapper = styled(InputBase)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(2)};
    width: 100%;
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};
`
);

const ListButton = styled(Box)(
  ({ theme }) => `
      background-color: transparent;
      color:  ${theme.colors.alpha.black[100]};
      transition: ${theme.transitions.create(['all'])};
      border: ${theme.colors.alpha.black[10]} solid 1px;
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(1)};
      margin: ${theme.spacing(1, 0)};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div > .MuiSvgIcon-root {
        color:  ${theme.colors.alpha.black[50]};
        transition: ${theme.transitions.create(['all'])};
      }

      &:hover {
        background-color: ${alpha(theme.colors.primary.main, 0.08)};
        color:  ${theme.colors.primary.main};
        border-color: ${alpha(theme.colors.primary.main, 0.3)};

        & > div > .MuiSvgIcon-root {
          color:  ${theme.colors.primary.main};
        }
      }
`
);

function HeaderSearch() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { agents } = useSelector((state) => state.agent);
  const { suppliers } = useSelector((state) => state.supplier);
  const { contacts } = useSelector((state) => state.contact);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(getAgents(signal));
    dispatch(getSuppliers(signal));
    dispatch(getContacts(signal));

    return () => {
      controller.abort();
    };
  }, [dispatch]);

  const [searchValue, setSearchValue] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 25;
  const [searchResults, setSearchResults] = useState([]);
  const searchInputRef = useRef(null); // Create a ref for the search input

  const rootPath = CommonService.getCurrentRootPath();

  const debounceSearch = useCallback(
    debounce((value) => {
      // Filter data based on the search input
      /*       const resultAgents = agents.filter((result) =>
        result.name.toLowerCase().includes(value.toLowerCase())
      ); */

      const resultAgents = agents.filter(
        (result) =>
          (result.name &&
            result.name.toLowerCase().includes(value.toLowerCase())) ||
          (result.accountCode &&
            result.accountCode.toLowerCase().includes(value.toLowerCase()))
      );

      const resultSuppliers = suppliers.filter((result) =>
        result.name.toLowerCase().includes(value.toLowerCase())
      );

      const resultContacts = contacts.filter((result) =>
        result.name.toLowerCase().includes(value.toLowerCase())
      );

      // Combine and sort the results
      const combinedResults = [
        ...resultAgents.map((result) => ({
          Id: result.entityId,
          name: result.name,
          objectType: result.objectType,
          status: result.status,
          link: `/accent-sidebar/agent/view/${result.entityId}`,
          otherInfo: result.accountCode + ' - ' + result.network
        })),
        ...resultSuppliers.map((result) => ({
          Id: result.entityId,
          name: result.name,
          objectType: result.objectType,
          status: result.status,
          link: `/accent-sidebar/supplier/view/${result.entityId}`,
          otherInfo: result.supplierCode
        })),
        ...resultContacts.map((result) => ({
          Id: result.contactId,
          name: result.name,
          objectType: result.objectType,
          status: result.referenceStatus ? result.referenceStatus.refText : '',
          link: `/accent-sidebar/contact/view/${result.contactId}`,
          otherInfo: result.referenceJobType
        }))
      ];

      const sortedResults = combinedResults.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setSearchResults(sortedResults);
      setPage(1);
    }, 300),
    [agents, suppliers, contacts]
  );

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    debounceSearch(value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      submitSearch(event);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open && searchInputRef.current) {
      // Focus the input field after a small delay to ensure it's rendered
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 100); // Adjust the delay as neededs
    }
  }, [open]);

  const submitSearch = async (event) => {
    event.preventDefault();
    if (searchValue.trim() === '') {
      setSearchResults([]);
      return;
    }
    setSearchLoading(true);
    await wait(500);
    setSearchLoading(false);
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const filteredResults = searchResults.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <>
      <Tooltip arrow title="Search">
        <IconButtonWrapper color="primary" onClick={handleClickOpen}>
          <SearchTwoToneIcon />
        </IconButtonWrapper>
      </Tooltip>

      <DialogWrapper
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth
        scroll="paper"
        onClose={handleClose}
      >
        <Box>
          <form onSubmit={submitSearch}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} display="flex" alignItems="center">
                <SearchTwoToneIcon
                  sx={{
                    ml: 2,
                    color: theme.colors.secondary.main
                  }}
                />
                <SearchInputWrapper
                  value={searchValue}
                  onChange={handleSearchChange}
                  inputRef={searchInputRef} // Assign the ref to the input
                  autoFocus
                  placeholder="Search terms here..."
                  fullWidth
                />
              </Box>
              <Card
                sx={{
                  ml: 'auto',
                  mr: 2,
                  py: 0.5,
                  px: 1,
                  background: theme.colors.alpha.black[10]
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight="bold"
                >
                  esc
                </Typography>
              </Card>
            </Box>
          </form>
        </Box>
        <Divider />
        {!searchLoading && (
          <>
            {filteredResults.length === 0 && (
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  background: theme.colors.info.lighter,
                  color: theme.colors.info.main,
                  borderRadius: theme.general.borderRadius,
                  fontSize: theme.typography.pxToRem(13),
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  mx: 2,
                  my: 2
                }}
              >
                <ContactSupportTwoToneIcon
                  sx={{
                    mr: 0.8,
                    fontSize: theme.typography.pxToRem(18)
                  }}
                />
                No results found
              </Typography>
            )}
          </>
        )}
        {searchLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              my: 5
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {filteredResults.length > 0 && (
              <Box
                sx={{
                  height: 450
                }}
              >
                <Scrollbar>
                  <Table>
                    <TableBody>
                      {filteredResults.map((result) => (
                        <TableRow key={result.Id}>
                          <TableCell>
                            {/*                             <Link to={`${result.link}`} onClick={handleClose}>
                              {result.name}
                            </Link> */}
                            <Link
                              onClick={() => {
                                handleClose();
                                window.location.href = `${result.link}`;
                              }}
                              style={{ cursor: 'pointer' }}
                              to={''}
                            >
                              {result.name}
                            </Link>
                          </TableCell>
                          <TableCell>{result.objectType}</TableCell>
                          <TableCell>{result.status}</TableCell>
                          <TableCell>{result.otherInfo}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Scrollbar>
                {searchResults.length > page * itemsPerPage && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mt: 2
                    }}
                  >
                    <button onClick={handleLoadMore}>Load More</button>
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
      </DialogWrapper>
    </>
  );
}

export default HeaderSearch;
