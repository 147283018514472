import { FC, ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import Login from 'src/content/pages/Auth/Login/Cover';
import LoginBasic from 'src/content/pages/Auth/Login/Basic';
import { AuthTypeEnum } from 'src/ems/enums/general.enums';
import TwoFactorAuthenticatorSetup from 'src/ems/components/authentication/TwoFactorAuthenticatorSetup';
import CodeVerification from 'src/ems/components/authentication/CodeVerification';

interface AuthenticatedProps {
  children: ReactNode;
}

const Authenticated: FC<AuthenticatedProps> = (props: { children: any }) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    if(auth.isTwoFactorSetup && auth.authTypeId === AuthTypeEnum.AUTHENTICATOR) {
      return <TwoFactorAuthenticatorSetup 
        qrCode={auth.QRCode}
        manualCode={auth.manualCode}
        userId={auth.userId}
        username={auth.username}
      />
    }
    if(auth.isTwoFactorSetup !== null && !auth.isTwoFactorSetup && auth.authTypeId === AuthTypeEnum.AUTHENTICATOR) {
      return <CodeVerification isEmailVerification={false} userId={auth.userId} username={auth.username}/>
    }

    if(auth.authTypeId === AuthTypeEnum.EMAIL) {
      return <CodeVerification isEmailVerification={true} userId={auth.userId} username={auth.username} />
    }
    return <LoginBasic />;
  }

  // if(auth.isAuthenticated) {
  //   return <Navigate to="/accent-sidebar/ems_dashboards/sales_dashboard" />;
  // }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
