import {
  PrivateFaresFilterPanelProps,
  defaultPrivateFaresFilterPanelProps
} from 'src/ems/components/common/PrivateFaresFilterPanel/Models/PrivateFaresFilterPanelProps';
import {
  ReportFilterPanelProps,
  defaultReportFilterPanelProps
} from 'src/ems/components/common/ReportFilterPanel/Models/ReportFilterPanelProps';
import { role } from 'src/ems/enums/roles.enums';
import { private_fare_report_columns } from 'src/ems/models/agent_report_models/private_fare_report_columns';
import private_fare_parameters from 'src/ems/models/report_models/private_fare_parameters';
import { getPreference } from 'src/ems/services/contact.preference.service';
import { convertArray } from 'src/ems/services/report.general.service';

export function getReportFilterPanel (defaultValues: private_fare_parameters): PrivateFaresFilterPanelProps {
  let preferenceData: private_fare_parameters = {};
  //const defaultValues: private_fare_parameters = await getPreference(role.RPT_PRIVATE_FARES, isDefault);

  if (defaultValues !== null) {
      preferenceData = defaultValues;
  }
  
  const reportFilterPanel: PrivateFaresFilterPanelProps =
    defaultPrivateFaresFilterPanelProps();

  reportFilterPanel.privateFaresPanel.agent = {
    ...reportFilterPanel.privateFaresPanel.agent,
    showField: true,
    isAddAllOptions: true,
    defaultValues: convertArray(preferenceData.agent)

  };
  reportFilterPanel.privateFaresPanel.headerGroup = {
    ...reportFilterPanel.privateFaresPanel.headerGroup,
    showField: true,
    isAddAllOptions: true,
    defaultValues: convertArray(preferenceData.headerGroup)
  };
  reportFilterPanel.privateFaresPanel.subGroup = {
    ...reportFilterPanel.privateFaresPanel.subGroup,
    showField: true,
    isAddAllOptions: true,
    defaultValues: convertArray(preferenceData.subGroup)
  };
  reportFilterPanel.privateFaresPanel.network = {
    ...reportFilterPanel.privateFaresPanel.network,
    showField: true,
    isAddAllOptions: true,
    defaultValues: convertArray(preferenceData.network)
  };
  reportFilterPanel.privateFaresPanel.airline = {
    ...reportFilterPanel.privateFaresPanel.airline,
    showField: true,
    isAddAllOptions: true,
    defaultValues: convertArray(preferenceData.airline)
  };
  reportFilterPanel.privateFaresPanel.fareType = {
    ...reportFilterPanel.privateFaresPanel.fareType,
    showField: true,
    isAddAllOptions: true,
    defaultValues: convertArray(preferenceData.fareType)
  };
  reportFilterPanel.privateFaresPanel.accountCode = {
    ...reportFilterPanel.privateFaresPanel.accountCode,
    showField: true,
    defaultValue: preferenceData.fareAccountCode
  };
  reportFilterPanel.privateFaresPanel.tourCode = {
    ...reportFilterPanel.privateFaresPanel.tourCode,
    showField: true,
    defaultValue: preferenceData.tourCode
  };
  return reportFilterPanel;
}

export function getReportMeasures(columnPrefrence: private_fare_report_columns) {
  let measures = [];

  measures.push({
    uniqueName: 'fareName',
    caption: `Name`,
    active: columnPrefrence.fareName
  });

  measures.push({
    uniqueName: 'supplierCode',
    caption: `Code`,
    active: columnPrefrence.supplierCode
  });

  measures.push({
    uniqueName: 'agentName',
    caption: `Agent`,
    active: columnPrefrence.agentName
  });

  measures.push({
    uniqueName: 'fareAccountCode',
    caption: `ACC Code`,
    active: columnPrefrence.fareAccountCode
  });

  measures.push({
    uniqueName: 'tourCode',
    caption: `Tour Code`,
    active: columnPrefrence.tourCode
  });

  measures.push({
    uniqueName: 'typeOfFareDetails',
    caption: `Type`,
    active: columnPrefrence.typeOfFareDetails
  });

  measures.push({
    uniqueName: 'fareStatusDetails',
    caption: `Status`,
    active: columnPrefrence.fareStatusDetails
  });

  measures.push({
    uniqueName: 'dateAdded',
    caption: `Date Added`,
    active: columnPrefrence.dateAdded
  });

  measures.push({
    uniqueName: 'dateExpire',
    caption: `Date Expiry`,
    active: columnPrefrence.dateExpire
  });

  measures.push({
    uniqueName: 'margin_In_Dollar',
    caption: `Margin($)`,
    active: columnPrefrence.margin_In_Dollar
  });

  measures.push({
    uniqueName: 'margin_In_Percentage',
    caption: `Margin(%)`,
    active: columnPrefrence.margin_In_Percentage
  });

  measures.push({
    uniqueName: 'commission',
    caption: `Comm`,
    active: columnPrefrence.commission
  });

  measures.push({
    uniqueName: 'ticketFee',
    caption: `Tkt Fee`,
    active: columnPrefrence.ticketFee
  });

  return measures;
}
