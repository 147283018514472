import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Card, CardContent, Grid, Typography, Button, Accordion, AccordionSummary, AccordionDetails, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { AgentPanel } from './Panels/AgentPanel';
import { OptionPanel } from './Panels/OptionPanel';
import { DatePanel } from './Panels/DatePanel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import RestoreIcon from '@mui/icons-material/Restore';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { ReportFilterPanelProps } from './Models/ReportFilterPanelProps';
import CustomRadioGroup from 'src/ems/components/rhf-components/CustomRadioGroup';
import { checkUserHasRole } from 'src/ems/services/auth.service';
import { role } from 'src/ems/enums/roles.enums';
import CustomButtonDropdown from '../CustomButtonDropdown';
import CustomSchedulerButton from '../CustomSchedulerButton';
import { scheduleDetails } from 'src/ems/components/common/CustomSchedulerButton/CustomSchedulerButton';

interface ReportOptionsProps {
  onSubmit: (data: any) => void
  onQuickExport?: (data: any) => void
  handelExpansion?: (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void
  reportTitle?: string
  isLoadingReport: boolean
  isExpandPanel: boolean
  reportFilters: ReportFilterPanelProps
  isReportType?: boolean
  columnPrefrence?: any
  schedulerPreference?: scheduleDetails,
  isShowScheduler?: boolean,
  onSaveUserPreference?: (data: any) => void
  onSetUserColumnPreference?: (data: any) => void
  onResetUserPreference?: () => void
  onSetDefaultPreference?: (data: any) => void
  onSetScheduler?: (data: any) => void
}

export const ReportFilterPanel: React.FC<ReportOptionsProps> = ({
  onSubmit,
  onQuickExport,
  reportTitle,
  handelExpansion,
  isLoadingReport,
  isExpandPanel,
  reportFilters,
  isReportType = true,
  columnPrefrence = null,
  schedulerPreference= null,
  isShowScheduler= true,
  onSaveUserPreference,
  onSetUserColumnPreference,
  onResetUserPreference,
  onSetDefaultPreference,
  onSetScheduler
}) => {
  const methods = useForm()
  const [isAllowSetDefault] = useState(checkUserHasRole(role.SET_DEFAULT_PREFERENCE))
  const [isAllowSetScheduler] = useState(checkUserHasRole(role.RPT_SET_SCHEDULER) && isShowScheduler)

  const { agentPanel, optionPanel, datePanel } = reportFilters

  const reportTypeOptions = [
    {
      value: 'classic',
      label: 'Classic',
      tooltip: 'Try Classic if you want to group the report'
    },
    {
      value: 'flat',
      label: 'Flat',
      tooltip: 'Try Flat if you get error : to large dataset'
    }
  ]

  const handleFormSubmit = (data: any) => {
      onSubmit(data);
  };

  const handleQuickExport = () => {
    const data = methods.getValues();
    onQuickExport(data);
  };

  const handleSavePreference = () => {
    const data = methods.getValues();
    onSaveUserPreference(data);
  };

  const handleScheduler = (data) => {
    onSetScheduler(data)
  }

  const handleReset = () => {
    onResetUserPreference();
  };

  const handleSetDefaultFilter = () => {
    const data = methods.getValues();
    onSetDefaultPreference(data);
  };

  const handleSaveDropdown = (dropdownData: { [key: string]: boolean }) => {
    onSetUserColumnPreference(dropdownData)
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleFormSubmit)} style={{ margin: '8px' }}>
          <Accordion sx={{ margin: '10px' }} expanded={isExpandPanel} onChange={(e, expanded) => { handelExpansion(e, expanded); }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h3" gutterBottom>
                {reportTitle}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ margin: 2 }}>
              <Card variant="outlined" style={{ margin: '10px', position: 'relative' }}>
                <CardContent>
                  <Grid container justifyContent="flex-end" spacing={1} padding={1}>
                    {isAllowSetDefault && <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<AdminPanelSettingsIcon />}
                        onClick={handleSetDefaultFilter}
                      >
                        Set Default Filter
                      </Button>
                    </Grid>}
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<RestoreIcon />}
                        onClick={handleReset}
                      >
                        Reset Filter
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<SettingsIcon />}
                        onClick={handleSavePreference}
                      >
                        Save Filter
                      </Button>
                    </Grid>
                    {columnPrefrence && <Grid item>
                      <CustomButtonDropdown
                        data={columnPrefrence}
                        onSave={handleSaveDropdown}
                        title='Select Report Fields'
                        buttonName='Save Columns'
                      />
                    </Grid>}
                    {isAllowSetScheduler && <Grid item>
                      <CustomSchedulerButton
                        onSave={handleScheduler}
                        title='Select Scheduler'
                        buttonName='Set Scheduler'
                        initialDetails={schedulerPreference}
                        onCancle={handleScheduler}
                      />
                    </Grid>}
                  </Grid>
                  {/* Agent Panel */}
                  <AgentPanel
                    agent={agentPanel.agent}
                    network={agentPanel.network}
                    subGroup={agentPanel.subGroup}
                    headerGroup={agentPanel.headerGroup}
                    state={agentPanel.state}
                    status={agentPanel.status}
                  />

                  {/* Option Panel */}
                  <OptionPanel
                    businessManager={optionPanel.businessManager}
                    salesType={optionPanel.salesType}
                    airline={optionPanel.airline}
                    departureOrigin={optionPanel.departureOrigin}
                    destinationAirport={optionPanel.destinationAirport}
                    originAirport={optionPanel.originAirport}
                    destinationCountry={optionPanel.destinationCountry}
                    destinationRegion={optionPanel.destinationRegion}
                    journeyType={optionPanel.journeyType}
                    originCountry={optionPanel.originCountry}
                    paxType={optionPanel.paxType}
                    ticketApplication={optionPanel.ticketApplication}
                    travelClass={optionPanel.travelClass}
                    travelType={optionPanel.travelType}
                    revenueType={optionPanel.revenueType}
                    yoyType={optionPanel.yoyType}
                    includeEDMandWholeSaleTickets={optionPanel.includeEDMandWholeSaleTickets}
                    includeMixedCarriers={optionPanel.includeMixedCarriers}
                    includeReissueTickets={optionPanel.includeReissueTickets}
                    includeMargin={optionPanel.includeMargin}
                    includePax={optionPanel.includePax}
                    includePaymentReport={optionPanel.includePaymentReport}
                    includeShowYoy={optionPanel.includeShowYoy}
                    includeShowWow={optionPanel.includeShowWow}
                    showFareBasis={optionPanel.showFareBasis}
                  />

                  {/* Date Panel */}
                  <DatePanel
                    dateFrom={datePanel.dateFrom}
                    dateTo={datePanel.dateTo}
                    dateFilter={datePanel.dateFilter}
                  />

                  <div>
                    {isReportType === true ?
                      (<Grid marginBottom={5} marginTop={3} container justifyContent="center" >
                        <Grid item xs={12} md={12} sm={12} lg={12} justifyContent={"center"} display={"flex"}>
                          <CustomRadioGroup
                            name='reportType'
                            options={reportTypeOptions}
                            defaultValue={'classic'}
                            size='medium'
                          />
                        </Grid>
                      </Grid>) : null}
                    <Grid container justifyContent="center">
                      <Grid item xs={12} md={12} sm={12} lg={12} justifyContent={"center"} display={"flex"}>
                        {onQuickExport && <Button sx={{ marginRight: 2 }} variant="contained" type="submit" disabled={isLoadingReport} onClick={handleQuickExport}>Quick Export</Button>}
                        <Button sx={{ marginRight: 2 }} variant="contained" type="submit" disabled={isLoadingReport}>View</Button>
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </AccordionDetails>
          </Accordion>
        </form>
      </FormProvider>
    </>
  );
};
