import http_report from './http.report.service';
import httpService from './http.service';
import report_parameters from '../models/report_models/report_parameters';
import { reducer } from './../../slices/projects_board';
import { getCurrentUser } from './auth.service';
import dashboard_parameters from '../models/dashboard_models/dashboard_parameters';
import system_extended_parameters from '../models/report_models/system_extended_parameters';
import { SystemExtendedReportTypeEnum } from '../enums/general.enums';
import land_override_parameters from '../models/report_models/land_override_parameters';
import private_fare_parameters from '../models/report_models/private_fare_parameters';

const apiEndpoint = '/report';

export async function getDetailTicketReport(data: report_parameters) {
  try {
    const response = await http_report.post(
      apiEndpoint + '/DetailTicketReport', data
    );

    return(response.data)
  } catch (error) {
    console.error('Error in getDetailTicketReport:', error);
    throw error;
  }
}

// export async function getDetailTicketReportInclFile(data: report_parameters) {
//   try {
//     const response = await http_report.post(
//       apiEndpoint + '/DetailTicketReportInclFile', data, {
//         responseType: 'blob'
//       }
//     );

//     let responsePart = await parseMultipartResponse(response);

//     return(responsePart)
//   } catch (error) {
//     console.error('Error in getDetailTicketReport:', error);
//     throw error;
//   }
// }

export async function getAgencyByAirlineMonthlySalesReport(
  data: report_parameters
) {
  const response = await http_report.post(
    apiEndpoint + '/AgencyByAirlineMonthlySalesReport',
    data
  );

  return response.data;
}

export async function getAgencyByAirlineAnnualSalesReport(
  data: report_parameters
) {
  const response = await http_report.post(
    apiEndpoint + '/AgencyByAirlineAnnualSalesReport',
    data
  );

  return response.data;
}

export async function getAgencyByAirlineCommissionAnnualReport(
  data: report_parameters
) {
  const response = await http_report.post(
    apiEndpoint + '/AgencyByAirlineCommissionAnnualReport',
    data
  );

  return response.data;
}

export async function getAgentsSalesExistingAnnualReport(
  data: report_parameters
) {
  const response = await http_report.post(
    apiEndpoint + '/AgentsSalesExistingAnnualReport',
    data
  );

  return response.data;
}

export async function getAgentsSalesExistingWeeklyReport(
  data: report_parameters
) {
  const response = await http_report.post(
    apiEndpoint + '/AgentsSalesExistingWeeklyReport',
    data
  );

  return response.data;
}

export async function getAgentNewBusinessReport(data: report_parameters) {
  const response = await http_report.post(
    apiEndpoint + '/AgentNewBusinessReport',
    data
  );

  return response.data;
}

export async function getAgencySalesSummaryAnnualReport(
  data: report_parameters
) {
  const response = await http_report.post(
    apiEndpoint + '/AgencySalesSummaryAnnualReport',
    data
  );

  return response.data;
}

export async function getAgencySalesSummaryMonthlyReport(
  data: report_parameters
) {
  const response = await http_report.post(
    apiEndpoint + '/AgencySalesSummaryMonthlyReport',
    data
  );

  return response.data;
}

export async function getAirlineByAgencySalesAnnualReport(
  data: report_parameters
) {
  const response = await http_report.post(
    apiEndpoint + '/AirlineByAgencySalesAnnualReport',
    data
  );

  return response.data;
}

export async function getAirlineByAgencyMonthlySalesReport(
  data: report_parameters
) {
  const response = await http_report.post(
    apiEndpoint + '/AirlineByAgencyMonthlySalesReport',
    data
  );

  return response.data;
}

export async function getAirlineSalesExistingWeeklyReport(
  data: report_parameters
) {
  const response = await http_report.post(
    apiEndpoint + '/AirlineSalesExistingWeeklyReport',
    data
  );

  return response.data;
}

export async function getAirlineAnnualSalesSummaryReport(
  data: report_parameters
) {
  const response = await http_report.post(
    apiEndpoint + '/AirlineAnnualSalesSummaryReport',
    data
  );

  return response.data;
}

export async function getAirlineMonthlySalesSummaryReport(
  data: report_parameters
) {
  const response = await http_report.post(
    apiEndpoint + '/AirlineMonthlySalesSummaryReport',
    data
  );

  return response.data;
}

export async function getTicketSectorReport(data: report_parameters) {
  const response = await http_report.post(
    apiEndpoint + '/TicketSectorReport',
    data
  );

  return response.data;
}

export async function getAgentVsBSPReport(data: report_parameters) {
  const response = await http_report.post(
    apiEndpoint + '/AgentVsBSPReport',
    data
  );

  return response.data;
}

export async function getBdmWeeklyReport(data: report_parameters) {
  const response = await httpService.post(
    apiEndpoint + '/BdmWeeklyReport',
    data
  );

  return response.data;
}

export async function getAgentExtendedReport(data: report_parameters) {
  const response = await httpService.post(
    apiEndpoint + '/AgentExtendedReport',
    data
  );

  return response.data;
}

export async function getAgentNetOutstandingReport(data: report_parameters) {
  const response = await httpService.post(
    apiEndpoint + '/AgentNetOutstandingReport',
    data
  );

  return response.data;
}

export async function getProductSalesReport(data: dashboard_parameters) {
  const response = await httpService.post(
    apiEndpoint + '/ProductSalesReport',
    data
  );

  return response.data;
}

export async function getLandOverridesReport(data: land_override_parameters) {
  const response = await httpService.post(
    apiEndpoint + '/LandOverridesReport',
    data
  );

  return response.data;
}

export async function getEdmSubscriberListReport(data: report_parameters) {
  const response = await httpService.post(
    apiEndpoint + '/GetEDMSubscriberListReport',
    data
  );

  return response.data;
}

export async function getPrivateFaresReport(data: report_parameters) {
  const response = await httpService.post(
    apiEndpoint + '/GetPrivateFaresReport',
    data
  );
  return response.data;
}

export const downloadReport = async (data: report_parameters, reportType: number) => {
  const body = {
    parameters: data,
    reportType: reportType
  }

  const response = await http_report.post(apiEndpoint + '/quickexport', body, {
      responseType: 'blob',
  });
  
  return response.data;
};

export const downloadReportEMS = async (data: report_parameters, dashParameters: dashboard_parameters, privateFareParameters: private_fare_parameters, landOverrideParamters: land_override_parameters, reportType: number) => {
  const body = {
    parameters: data,
    dashboardParameters: dashParameters,
    privateFareParameters: privateFareParameters,
    landOverrideParamters: landOverrideParamters,
    reportType: reportType
  }

  const response = await httpService.post(apiEndpoint + '/quickexport', body, {
      responseType: 'blob',
  });
  
  return response.data;
};

export async function getSystemExtendedAccreditation(
  data: system_extended_parameters
) {
  let enddpoint = '';

  if (data.reportType === SystemExtendedReportTypeEnum.NDC) {
    enddpoint = '/SystemExtendedNDCReport';
  } else if (data.reportType === SystemExtendedReportTypeEnum.PCC) {
    enddpoint = '/SystemExtendedPCCReport';
  } else {
    enddpoint = '/SystemExtendedAccreditationReport';
  }

  const response = await httpService.post(apiEndpoint + enddpoint, data);

  return response.data;
}

export function getReportParameters(data: any): report_parameters {
  let parameters: report_parameters = {};

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];

      if (
        Array.isArray(value) &&
        value.every((item) => item && typeof item.value === 'number')
      ) {
        parameters[key] = value.some((item) => item.value === 0)
          ? null
          : value.map((item: any) => item.value.toString());
      } else if (typeof value === 'number') {
        parameters[key] = value === 0 ? null : value;
      } else if (typeof value === 'string' && isDateString(value)) {
        // If the value is a date string, extract only the date part.
        let date = new Date(value);
        parameters[key] = formatDate(date);
      } else {
        parameters[key] = value;
      }
    }
  }

  return parameters;
}



// Helper function to check if a string is a date
function isDateString(value: string): boolean {
  return !isNaN(Date.parse(value)) && value.indexOf('-') > 0;
}

// Helper function to format a Date object to YYYY-MM-DD format
function formatDate(date: Date): string {
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  let year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

// async function parseMultipartResponse(response) {
//   try {
//     const contentType = response.headers['content-type'];

//     if (contentType.startsWith('multipart/form-data')) {
//       const boundary = contentType.split('boundary=')[1].replace(/"/g, '');

//       const text = await response.data.text();

//       const parts = parseMultipart(text, boundary);

//       const resultPart = parts.find(part => part.headers['content-type'].includes('application/json'));
//       const excelPart = parts.find(part => part.headers['content-type'].includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'));

//       if (!resultPart) {
//         throw new Error('JSON part not found');
//       }
//       if (!excelPart) {
//         throw new Error('Excel file part not found');
//       }

//       const result = JSON.parse(resultPart.body);
//       const excelBlob = new Blob([excelPart.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

//       return { data: result, file: excelBlob };
//     } else {
//       console.error('Unexpected content type:', contentType);
//       throw new Error('Unexpected response type');
//     }
//   } catch (error) {
//     console.error('Error in parseMultipartResponse:', error);
//     throw error;
//   }
// }

// function parseMultipart(text, boundary) {
//   const boundaryMarker = `--${boundary}`;
//   const parts = text.split(boundaryMarker).filter(part => part.trim() !== '' && part.trim() !== '--');

//   return parts.map(part => {
//     const [headers, ...content] = part.split('\r\n\r\n');
//     const headerLines = headers.split('\r\n');
//     const headersObj = {};

//     headerLines.forEach(line => {
//       const [key, value] = line.split(': ');
//       headersObj[key.toLowerCase()] = value;
//     });

//     const bodyContent = content.join('\r\n\r\n').trim();
//     return {
//       headers: headersObj,
//       body: bodyContent
//     };
//   });
// }

export default {
  getDetailTicketReport,
  getAgencyByAirlineMonthlySalesReport,
  getAgencyByAirlineAnnualSalesReport,
  getAgencyByAirlineCommissionAnnualReport,
  getAgentsSalesExistingAnnualReport,
  getAgentsSalesExistingWeeklyReport,
  getAgentNewBusinessReport,
  getAgencySalesSummaryAnnualReport,
  getAgencySalesSummaryMonthlyReport,
  getAirlineByAgencySalesAnnualReport,
  getAirlineByAgencyMonthlySalesReport,
  getAirlineSalesExistingWeeklyReport,
  getAirlineAnnualSalesSummaryReport,
  getAirlineMonthlySalesSummaryReport,
  getAgentVsBSPReport,
  getBdmWeeklyReport,
  getAgentExtendedReport,
  getAgentNetOutstandingReport,
  getTicketSectorReport,
  getProductSalesReport,
  getSystemExtendedAccreditation,
  getReportParameters,
  getLandOverridesReport,
  getEdmSubscriberListReport,
  getPrivateFaresReport,
  downloadReport,
  downloadReportEMS
};
