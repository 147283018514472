import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import EntityService from 'src/ems/services/entity.service';
import EntitySummary from 'src/ems/models/entity_summary';

interface EntityState {
  entities: EntitySummary[];
}

const initialState: EntityState = {
  entities: []
};

const slice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    getEntities(
      state: EntityState,
      action: PayloadAction<{ entities: EntitySummary[] }>
    ) {
      const { entities } = action.payload;

      state.entities = entities;
    }
  }
});

export const reducer = slice.reducer;

export const getEntities =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    let { data } = await EntityService.getEntities();

    const entities = Array.isArray(data) ? data : [];
    dispatch(slice.actions.getEntities({ entities }));
  };

export default slice;
