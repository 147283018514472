import {
  ReportFilterPanelProps,
  defaultReportFilterPanelProps
} from 'src/ems/components/common/ReportFilterPanel/Models/ReportFilterPanelProps';
import { role } from 'src/ems/enums/roles.enums';
import report_parameters from 'src/ems/models/report_models/report_parameters';
import { getPreference } from 'src/ems/services/contact.preference.service';
import { applyCommonProperties } from 'src/ems/services/report.general.service';

export function getReportFilterPanel (defaultValues: report_parameters): ReportFilterPanelProps {
  let preferenceData: report_parameters = {};
  // const defaultValues: report_parameters = await getPreference(role.RPT_DETAILED_TICKET_REPORT, isDefault);

  if (defaultValues !== null) {
      preferenceData = defaultValues;
  }

  const reportFilterPanel: ReportFilterPanelProps = defaultReportFilterPanelProps();

  applyCommonProperties(reportFilterPanel.agentPanel, 'agent', preferenceData.agent);
  applyCommonProperties(reportFilterPanel.agentPanel, 'network', preferenceData.network);
  applyCommonProperties(reportFilterPanel.agentPanel, 'state', preferenceData.state);
  applyCommonProperties(reportFilterPanel.agentPanel, 'headerGroup', preferenceData.headerGroup);
  applyCommonProperties(reportFilterPanel.agentPanel, 'subGroup', preferenceData.subGroup);

  return reportFilterPanel;
}

export function getReportMeasures() {
  let measures = [];

  measures.push({
    uniqueName: 'state',
    caption: `State`
  });

  measures.push({
    uniqueName: 'agent',
    caption: `Agent`
  });

  measures.push({
    uniqueName: 'agencyGroup',
    caption: `Agency Group`
  });

  measures.push({
    uniqueName: 'name',
    caption: `Name`
  });

  measures.push({
    uniqueName: 'email',
    caption: `Email`
  });
  measures.push({
    uniqueName: 'jobType',
    caption: `JobType`
  });

  return measures;
}
