import React, { useState } from 'react';
import {
  FormControl,
  FormHelperText,
  TextField,
  Autocomplete
} from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import FormDropDownProps from 'src/ems/models/form_dropdown_props';

interface DropdownProps {
  name: string;
  label: string;
  options: FormDropDownProps[];
  addAllOption?: boolean;
  defaultValues?: number | null;
  fullWidth?: boolean;
  onOpen?: () => void;
  disabled?: boolean;
  onChange?: (e: any) => void;
  [key: string]: any;
}

export const Dropdown: React.FC<DropdownProps> = ({
  name,
  label,
  options,
  defaultValues = null,
  addAllOption = false,
  fullWidth = true,
  onOpen,
  disabled = false,
  onChange: customOnChange,
  ...rest
}) => {
  const { control } = useFormContext();
  const [inputValue, setInputValue] = useState('');

  // Ensure options are defined and not null
  const modifiedOptions = options ? [...options] : [];
  if (addAllOption && modifiedOptions.length > 0) {
    const modifiedType = modifiedOptions[0]?.type || '';
    modifiedOptions.unshift({
      value: 0,
      label: 'All ' + label,
      type: modifiedType
    });
  }

  const isOptionValid = (input: string) => {
    return modifiedOptions.some(option => option.label === input);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValues}
      render={({
        field: { onChange, value, ref },
        fieldState: { error }
      }) => (
        <FormControl fullWidth={fullWidth} error={!!error}>
          <Autocomplete
            id={name}
            options={modifiedOptions}
            getOptionLabel={(option) => {
              // Safely handle undefined options
              if (typeof option === 'string') return option;
              return option?.label || ''; // Return empty string if option is undefined
            }}
            freeSolo
            disabled={disabled}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={(event, newValue) => {
              if (newValue === null) {
                onChange(null);
                setInputValue(''); // Clear the input field
                if (customOnChange) customOnChange(null);
              } else if (typeof newValue === 'string') {
                onChange(newValue);
                if (customOnChange) customOnChange(newValue);
              } else {
                onChange(newValue?.value); // Safely access value
                setInputValue(newValue?.label || '');
                if (customOnChange) customOnChange(newValue?.value);
              }
            }}
            onBlur={() => {
              // When focus shifts away (onBlur), clear if invalid
              if (!isOptionValid(inputValue)) {
                onChange(null);
                setInputValue(''); // Clear the input value
                if (customOnChange) customOnChange(null);
              }
            }}
            value={
                modifiedOptions.find((option) => option.value === value) || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                inputRef={ref}
                disabled={disabled}
                {...rest}
              />
            )}
            onFocus={() => onOpen && onOpen()}
          />
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
