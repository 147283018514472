import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import SupplierService from 'src/ems/services/supplier.service';
import { SupplierProducts } from '../models/supplier_models/supplier_products';

interface SupplierProductsState {
  supplierProducts: SupplierProducts[];
}

const initialState: SupplierProductsState = {
  supplierProducts: []
};

const slice = createSlice({
  name: 'supplierProducts',
  initialState,
  reducers: {
    getProducts(
      state: SupplierProductsState,
      action: PayloadAction<{ supplierProducts: SupplierProducts[] }>
    ) {
      const { supplierProducts } = action.payload;

      state.supplierProducts = supplierProducts;
    }
  }
});

export const reducer = slice.reducer;

export const getProducts =
  (signal: any): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      let { data } = await SupplierService.getDropDownListSupplierProducts(
        signal
      );
      const supplierProducts = Array.isArray(data) ? data : [];
      dispatch(slice.actions.getProducts({ supplierProducts }));
    } catch (error) {
      if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
        console.error(error);
      }
    }
  };

export default slice;
