import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Applications
const List = Loader(lazy(() => import('src/ems/components/suppliers/List')));
const View = Loader(lazy(() => import('src/ems/components/suppliers/View')));
const Create = Loader(
  lazy(() => import('src/ems/components/suppliers/Create'))
);
const AirlineOffer = Loader(
  lazy(() => import('src/ems/components/suppliers/AirlineOffer'))
);
const AirlineBSP = Loader(
  lazy(() => import('src/ems/components/suppliers/AirlineBsp'))
);
const suppliersRoutes = [
  {
    path: '',
    element: <Navigate to="list" replace />
  },
  {
    path: 'list',

    element: <List />
  },
  {
    path: 'view',
    children: [
      {
        path: '',
        element: <Navigate to="1" replace />
      },
      {
        path: ':entityId',
        element: <View />
      }
    ]
  },
  {
    path: 'Create',
    element: <Create />
  },
  {
    path: 'AirlineOffer',
    element: <AirlineOffer />
  },
  {
    path: 'AirlineBSP',
    element: <AirlineBSP />
  }
];

export default suppliersRoutes;
