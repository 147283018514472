import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import type ContactSummary from 'src/ems/models/contact_models/contact_summary';
import ContactService from 'src/ems/services/contact.service';

interface ContactState {
  contacts: ContactSummary[];
}

const initialState: ContactState = {
  contacts: []
};

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    getContacts(
      state: ContactState,
      action: PayloadAction<{ contacts: ContactSummary[] }>
    ) {
      const { contacts } = action.payload;

      state.contacts = contacts;
    }
  }
});

export const reducer = slice.reducer;

export const getContacts =
  (signal?: any): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      let { data } = await ContactService.getContacts(signal);

      const contacts = Array.isArray(data) ? data : [];
      dispatch(slice.actions.getContacts({ contacts }));
    } catch (error) {
      if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
        console.error(error);
      }
    }
  };

export default slice;
