import LandContractSummary from 'src/ems/models/contract_models/land/land_contract_summary';
import http from 'src/ems/services/http.service';

const apiEndpoint = '/contract/land';

/* export async function getContracts() {
  const response = await http.get<ContractSummary[]>(apiEndpoint + '/list');
  return response;
} */

export async function getLandContracts(signal?: any) {
  const response = await http.get<LandContractSummary[]>(
    apiEndpoint + '/list',
    {
      signal: signal
    }
  );
  return response;
}

export async function deleteContract(id: string) {
  await http.delete(apiEndpoint + '/delete/' + id);
}

export default {
  getLandContracts,
  deleteContract
};
