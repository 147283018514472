import * as yup from 'yup';
import { DashboardOptionPanelProps } from '../Models/DashboardFilterPanelProps';
import { DatePanelProps } from '../../ReportFilterPanel/Models/ReportFilterPanelProps';
import { DatePanel } from './../../ReportFilterPanel/Panels/DatePanel';

const DashboardFilterPanelSchema = (options: DashboardOptionPanelProps, datePanelOptions: DatePanelProps) => {
  let schema: { [key: string]: any } = {};

  const dateSchema = yup
    .string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in yyyy-MM-dd format')
    .transform((value, originalValue) => (originalValue ? originalValue : null))
    .test('date-format', 'Invalid date format. Use yyyy-MM-dd', (value) => {
      if (!value) {
        return true; // Skip validation if value is null or undefined
      }
      const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
      const isValid = dateFormatRegex.test(value);
      return isValid;
    });

  if (options.businessManager.showField && options.businessManager) {
    schema = {
      ...schema,
      businessManager: yup
        .array()
        .ensure()
        .min(1, 'Select at least 1 option')
        .of(yup.object().shape({})),
    };
  }

  if (options.agent.showField && options.agent) {
    schema = {
      ...schema,
      agents: yup
        .array()
        .of(yup.object().shape({}))
        .min(1, 'Select at least 1 option')
    };
  }

  if (options.network.showField && options.network) {
    schema = {
      ...schema,
      networks: yup
        .array()
        .of(yup.object().shape({}))
        .min(1, 'Select at least 1 option')
    };
  }

  if (options.state.showField && options.state) {
    schema = {
      ...schema,
      states: yup
        .array()
        .of(yup.object().shape({}))
        .min(1, 'Select at least 1 option')
    };
  }

  if (datePanelOptions.dateFrom.showField) {
    schema = {
      ...schema,
      dateFrom: dateSchema,
    };
  }

  if (datePanelOptions.dateTo.showField) {
    schema = {
      ...schema,
      dateTo: dateSchema
    .test('dateTo-validity', 'Date To must be greater than or equal to Date From', function (dateTo) {
        const dateFrom = this.resolve(yup.ref('dateFrom')) as string;
        if (!dateTo || dateTo === '' || !dateFrom || dateFrom === '') {
          return true; // Skip validation if either dateTo or dateFrom is null, undefined, or blank
        }

        const dateFromDate = new Date(dateFrom);
        const dateToDate = new Date(dateTo as string);

        return dateToDate >= dateFromDate;
      }),
    };
  }

  return yup.object().shape(schema);
};

export { DashboardFilterPanelSchema };
