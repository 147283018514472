import Reference from 'src/ems/models/reference';
import http from './http.service';
import FormDropDownProps from 'src/ems/models/form_dropdown_props';
import ReferenceDetails from 'src/ems/models/reference';

const apiEndpoint = '/reference';

export async function getReferences(signal?: any) {
  const response = await http.get<Reference[]>(apiEndpoint + '/list', {
    signal: signal
  });
  return response;
}

export async function getDropDownReferences(signal?: any) {
  const response = await http.get<FormDropDownProps[]>(
    apiEndpoint + '/ddreferencelist',
    {
      signal: signal
    }
  );
  return response;
}

export function getStatusOptions(references: FormDropDownProps[]) {
  return references.filter((reference) => reference.type === 'STS');
}

export function getCountryOptions(references: FormDropDownProps[]) {
  return references.filter((reference) => reference.type === 'COU');
}

export function getHeaderGroupOptions(references: FormDropDownProps[]) {
  return references.filter((reference) => reference.type === 'HEADER_GRP');
}
export function getStateOptions(references: FormDropDownProps[]) {
  return references.filter((reference) => reference.type === 'STA');
}

export function getNetworkOptions(references: FormDropDownProps[]) {
  return references.filter((reference) => reference.type === 'GRP');
}

export function getSubGroupOptions(references: FormDropDownProps[]) {
  return references.filter((reference) => reference.type === 'SUB_GRP');
}

export async function getReferenceTypes(signal?: any) {
  const response = await http.get(apiEndpoint + '/referencetypelist', {
    signal: signal
  });
  return response;
}

export async function getReferencesByType(selectedType, signal?: any) {
  const response = await http.get<Reference[]>(
    apiEndpoint + '/list/' + selectedType,
    {
      signal: signal
    }
  );
  return response;
}

export async function addReference(data: ReferenceDetails) {
  await http.post(apiEndpoint + '/create', data);
}

export async function updateReference(data: ReferenceDetails) {
  await http.put(apiEndpoint + '/update', data);
}

export async function deleteReference(id: string) {
  await http.post(apiEndpoint + '/delete/' + id);
}

export default {
  getCountryOptions,
  getReferences,
  getDropDownReferences,
  getHeaderGroupOptions,
  getSubGroupOptions,
  getStatusOptions,
  getStateOptions,
  getNetworkOptions,
  getReferenceTypes,
  getReferencesByType,
  addReference,
  updateReference,
  deleteReference
};
