import ContractSupportDetails from 'src/ems/models/contract_models/settings/contract_support_details';
import http from 'src/ems/services/http.service';

const apiEndpoint = '/contract/settings';

export async function getContractSupportList(type: string = '') {
  const response = await http.get<ContractSupportDetails[]>(
    apiEndpoint + '/list/' + type
  );
  return response;
}

export async function addContractSupport(data: ContractSupportDetails) {
  await http.post(apiEndpoint + '/create', data);
}

export async function updateContractSupport(data: ContractSupportDetails) {
  await http.put(apiEndpoint + '/update', data);
}

export async function deleteContractSupport(id: string) {
  await http.post(apiEndpoint + '/delete/' + id);
}

export async function getContractSupportTypesList(signal?: any) {
  const response = await http.get(apiEndpoint + '/typelist', {
    signal: signal
  });
  return response;
}

export default {
  getContractSupportList,
  addContractSupport,
  updateContractSupport,
  deleteContractSupport,
  getContractSupportTypesList
};
