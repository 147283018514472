import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import MultiSelectDropDownOptionsProps from 'src/ems/models/multiSelectDropDownOptionsProps';

interface CustomMultiSelectDropdownProps {
  name: string;
  label: string;
  options: MultiSelectDropDownOptionsProps[];
  addAllOption?: boolean;
  defaultValues?: number[];
  handleChange?: (...event: any[]) => void;
}

export const MultiSelectDropdown: React.FC<CustomMultiSelectDropdownProps> = ({
  name,
  label,
  options,
  addAllOption = true,
  defaultValues = null,
  handleChange = null
}) => {
  const { control, setValue } = useFormContext();
  const [isSelectAll, setIsSelectAll] = useState<boolean>(addAllOption);

  let modifiedOptions = options;
  if (addAllOption) {
    let optionLabel = label;
    if (label.endsWith('y')) {
      optionLabel = label.slice(0, -1) + 'ies';
    } else if (!label.endsWith('s')) {
      optionLabel += 's';
    }
    modifiedOptions = [{ value: 0, label: `All ${optionLabel}` }, ...options];
  }

  const handleAllOption = (newValue) => {
    if (isSelectAll) {
      if (newValue.some((item) => item.value !== 0)) {
        newValue = newValue.filter((item) => item.value !== 0);
        setIsSelectAll(false);
      }
    } else {
      if (newValue.some((item) => item.value === 0)) {
        newValue = newValue.filter((item) => item.value === 0);
        setIsSelectAll(true);
      }
    }

    return newValue;
  };

  useEffect(() => {
    let defaultValue = defaultValues
      ? modifiedOptions.filter((a) => defaultValues.includes(a.value))
      : addAllOption
      ? modifiedOptions.filter((a) => a.value === 0)
      : [];

    if (defaultValue.some((a) => a.value === 0)) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }

    setValue(name, defaultValue);
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValues || []}
      render={({ field: { value, onChange }, fieldState }) => (
        <Autocomplete
          multiple
          disableCloseOnSelect
          filterSelectedOptions
          id={name}
          options={modifiedOptions}
          value={value}
          onChange={(event, newValue, reason, details) => {
            if (handleChange === null) {
              newValue = addAllOption ? handleAllOption(newValue) : newValue;
              onChange(newValue);
            } else {
              newValue = addAllOption ? handleAllOption(newValue) : newValue;
              onChange(newValue);
              handleChange(newValue, reason, details);
            }
          }}
          isOptionEqualToValue={(options, values) => {
            if (values.value !== undefined)
              return values.value === options.value;
            else return values === options.value;
          }}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            );
          }}
        />
      )}
    />
  );
};
