import type { FC } from 'react';
import { useEffect, useRef } from 'react'; // Import useEffect and useRef
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Link,
  CircularProgress
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { authSchema } from 'src/validations/schema/auth';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';


const LoginJWT: FC = () => {
  const { login } = useAuth() as any;
  const { t }: { t: any } = useTranslation();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const formOptions = { resolver: yupResolver(authSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, setError, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  // Use useRef to create a reference to the username input field
  const usernameRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  // useEffect hook to focus on the username field when the component mounts
  useEffect(() => {
    if(auth.isSessionEnded) {
      enqueueSnackbar('Session expired. Please login again', { variant: 'error' });
    }
    // Check if the reference exists and focus on it
    if (usernameRef.current) {
      usernameRef.current.focus();
    }
  }, []); // Empty dependency array ensures the effect runs only once after mount

  const onForgotPassword = () => {
    navigate('/forgot-password')
  }

  const onSubmit = async ({ username, password }) => {
    try {
      await login(username, password);
    } catch (error) {
      console.error('Error => ', error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An unexpected error occurred. Please try again later.';

      setError('apiError', { message: errorMessage });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        inputRef={usernameRef} // Assign the ref to the username field
        sx={{ mb: 2 }}
        label="Username"
        fullWidth
        error={!!errors['username']}
        helperText={errors['username'] ? errors['username'].message : ''}
        {...register('username')}
      />
      <TextField
        sx={{ mb: 2 }}
        label="Password"
        type="password"
        fullWidth
        error={!!errors['password']}
        helperText={errors['password'] ? errors['password'].message : ''}
        {...register('password')}
      />
      <Box
        alignItems="center"
        display={{ xs: 'block', md: 'flex' }}
        justifyContent="space-between"
      >
        <Link onClick={onForgotPassword}>
          <b>{t('Forgot password?')}</b>
        </Link>
      </Box>

      <Button
        sx={{
          mt: 3
        }}
        color="primary"
        startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
        disabled={isSubmitting}
        type="submit"
        fullWidth
        size="large"
        variant="contained"
      >
        {t('Sign in')}
      </Button>
      {errors.apiError && (
        <FormHelperText error={!!errors['apiError']}>
          {errors['apiError'] ? errors['apiError'].message : ''}
        </FormHelperText>
      )}
    </form>
  );
};

export default LoginJWT;
