import React, { useEffect, useState } from 'react';
import ReportTicketingService from 'src/ems/services/report.ticketing.service';
import 'flexmonster/flexmonster.css';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useSnackbar } from 'notistack';
import { getReportFilterPanel, getReportMeasures } from './services/Service';
import { CustomFlexmonsterReport } from 'src/ems/components/common/Flexmonster/CustomFlexmonsterReport';
import LandOverrideParameters from 'src/ems/models/report_models/land_override_parameters';
import ReportFilterPanel from 'src/ems/components/common/ReportFilterPanel';
import { ReportFilterPanelProps } from 'src/ems/components/common/ReportFilterPanel/Models/ReportFilterPanelProps';
import PrivateFaresFilterPanel from 'src/ems/components/common/PrivateFaresFilterPanel';
import { PrivateFaresFilterPanelProps } from 'src/ems/components/common/PrivateFaresFilterPanel/Models/PrivateFaresFilterPanelProps';
import private_fare_parameters from 'src/ems/models/report_models/private_fare_parameters';
import { getColumnPreference, getPreference, saveColumnPreference, savePreference, saveScheduler } from 'src/ems/services/contact.preference.service';
import { ContactPreference } from 'src/ems/enums/contact.preference.enums';
import { defaultPrivateFareReportColumns, private_fare_report_columns } from 'src/ems/models/agent_report_models/private_fare_report_columns';
import { role } from 'src/ems/enums/roles.enums';
import { scheduleDetails } from 'src/ems/components/common/CustomSchedulerButton/CustomSchedulerButton';
import { downloadExcelFile } from 'src/ems/services/report.general.service';

export const PrivateFares: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
  const [isExpandPanel, setIsExpandPanel] = useState<boolean>(true);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [data, setData] = useState(null);
  const [title] = useState<string>('Private Fares');
  const [reportExportFileName, setReportExportFileName] =
    useState<string>('Private Fares');
    const [measures, setMeasures] = useState(null);
  const [reportFilterPanelProps, setReportFilterPanelProps] = useState<PrivateFaresFilterPanelProps>(null);
  const [isDefault, setIsDefault] = useState<boolean>(false)
  const [preferedColumns, setPreferedColumns] = useState<private_fare_report_columns>(defaultPrivateFareReportColumns())
  const [schedulerDetails, setSchedulerDetails] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      if (reportFilterPanelProps === null) {
        await getPreference(role.RPT_PRIVATE_FARES, isDefault).then((response) => {
          const reportFilterPanelData = getReportFilterPanel(response.parameters);
          setReportFilterPanelProps(reportFilterPanelData);
          if (response.columns !== null) 
          {
            setPreferedColumns(response.columns)
          }
          if (response.scheduler !== null) 
          {
            setSchedulerDetails(response.scheduler)
          }
        });
      }
    };
  
    fetchData();
  }, [reportFilterPanelProps]);

  useEffect(() => {
    const newMeasures = getReportMeasures(preferedColumns)
    setMeasures(newMeasures)
  }, [preferedColumns]);
  
  const customizeCell = (cell, data) => {
    if (
      data.measure &&
      data.type !== 'header' &&
      data.measure.uniqueName === 'note'
    ) {
      cell.text = `<span class="tooltipNote" title="${data.label}">${data.label}</span>`;
    }
  };

  const handleExpansion = (event, value) => {
    setIsExpandPanel(value);
  };

  const handleResetUserPreference = async () => {
    try {
        setIsDefault(true)
        setReportFilterPanelProps(null)
    } catch (error) {
        console.error('Failed to rest preferences:', error);
        enqueueSnackbar('Failed to rest your preferences!', { variant: 'error' });
    }
  };

  const handleSetDefaultPreference = async (data: private_fare_parameters) => {
    setData(null);
    const parameters: private_fare_parameters = ReportTicketingService.getReportParameters(data);

    try {
        await savePreference(role.RPT_PRIVATE_FARES, ContactPreference.PRIVATE_FARE_PARAMETERS, parameters, true);
        enqueueSnackbar('Default preferences saved successfully!', { variant: 'success' });
    } catch (error) {
        console.error('Failed to save default preferences:', error);
        enqueueSnackbar('Failed to save your default preferences!', { variant: 'error' });
    }
  };

  const handleSaveUserPreference = async (data: private_fare_parameters) => {
    setData(null);
    const parameters: private_fare_parameters = ReportTicketingService.getReportParameters(data);

    try {
        await savePreference(role.RPT_PRIVATE_FARES, ContactPreference.PRIVATE_FARE_PARAMETERS, parameters, false);
        enqueueSnackbar('Preferences saved successfully!', { variant: 'success' });
    } catch (error) {
        console.error('Failed to save preferences:', error);
        enqueueSnackbar('Failed to save your preferences!', { variant: 'error' });
    }
  };

  const handleSaveColumnUserPreference = async (data) => {
    try {
        await saveColumnPreference(role.RPT_PRIVATE_FARES, ContactPreference.PRIVATE_FARE_PARAMETERS, data, false);
        setPreferedColumns(data)
        enqueueSnackbar('Column Preferences saved successfully!', { variant: 'success' });
    } catch (error) {
        console.error('Failed to save column preferences:', error);
        enqueueSnackbar('Failed to save your column preferences!', { variant: 'error' });
    }
  };

  const handleSetScheduler = async (data) => {
    try {
      let body: scheduleDetails = {
        ...data, 
        roleId: role.RPT_PRIVATE_FARES, 
        preferenceTypeId: ContactPreference.PRIVATE_FARE_PARAMETERS
      }

      await saveScheduler(body);
      // setPreferedColumns(data);
      enqueueSnackbar('Scheduler set successfully!', { variant: 'success' });
    } catch (error) {
      console.error('Failed to set scheduler:', error);
      enqueueSnackbar('Failed to set scheduler!', { variant: 'error' });
    }
  };

  const handleReportSubmit = (data: private_fare_parameters) => {
    setData(null);
    setIsLoadingReport(true);
    setIsExpandPanel(false);

    const parameters: private_fare_parameters =
      ReportTicketingService.getReportParameters(data);

    ReportTicketingService.getPrivateFaresReport(parameters)
      .then((a) => {
        enqueueSnackbar('Report Loaded Successfully!!', {
          variant: 'success'
        });
        setData(a);
        setReportExportFileName(title);
        setIsLoadingReport(false);
        setRefreshKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        console.log('Error -> ', error);
        enqueueSnackbar(error, {
          variant: 'error'
        });
        setIsLoadingReport(false);
        setIsExpandPanel(true);
      });
  };

  const handleQuickExport = async (data: private_fare_parameters) => {

    let dateFrom = new Date(data.dateFrom).toLocaleDateString('en-AU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    let dateTo = new Date(data.dateTo).toLocaleDateString('en-AU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

    setData(null);
    setIsLoadingReport(true);
    setIsExpandPanel(false);
    
    const parameters: private_fare_parameters = ReportTicketingService.getReportParameters(data);
    let reportFileName = title + ' - ' + dateFrom + ' to ' + dateTo;

    //Start downloadReport in the background
    ReportTicketingService.downloadReportEMS(null, null, null, parameters,role.RPT_PRIVATE_FARES)
      .then((downloadResponse) => {
        downloadExcelFile(downloadResponse, reportFileName);
        setIsLoadingReport(false);
        setIsExpandPanel(true);
      })
      .catch((error) => {
        console.error('Failed to download report:', error);
        enqueueSnackbar('Failed to download the report!', { variant: 'error' });
        setIsLoadingReport(false);
        setIsExpandPanel(true);
      });
  };


  return (
    <>
      {reportFilterPanelProps !== null && <div>
        <PrivateFaresFilterPanel
          onSubmit={handleReportSubmit}
          onQuickExport={handleQuickExport}
          reportTitle={title}
          isLoadingReport={isLoadingReport}
          isExpandPanel={isExpandPanel}
          reportFilters={reportFilterPanelProps}
          columnPrefrence={preferedColumns}
          schedulerPreference={schedulerDetails}
          isShowScheduler={false}
          handelExpansion={handleExpansion}
          onSaveUserPreference={handleSaveUserPreference}
          onResetUserPreference={handleResetUserPreference}
          onSetDefaultPreference={handleSetDefaultPreference}
          onSetUserColumnPreference={handleSaveColumnUserPreference}
          onSetScheduler={handleSetScheduler}
        />
      </div>}
      {isLoadingReport ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '0 20px'
          }}
        >
          <LinearProgress sx={{ width: '100%' }} />
        </Box>
      ) : null}
      <div style={{ margin: '15px' }}>
        {data ? (
          <CustomFlexmonsterReport
            title={title}
            data={data}
            refreshKey={refreshKey}
            measures={measures}
            fileName={reportExportFileName}
            reportType={'flat'}
            customizeCell={customizeCell}
            showGrandTotal={false}
          />
        ) : null}
      </div>
    </>
  );
};
