
import http from './http.service';
import ProductLandSalesFinancialPeriod from 'src/ems/models/product_land_sales_financial_period';
import ProductLandSalesPaymentBreakDown from 'src/ems/models/product_land_sales_payment_break_down';
import ProductLandSalesPaymentMiniStatus from 'src/ems/models/product_land_sales_payment_mini_status';
import ProductLandSalesPaymentMiniPeriod from '../models/product_land_sales_payment_mini_period';
import ProductLandSalesPaymentCurrentBreakDown from '../models/product_land_sales_payment_current_break_down';
import ProductLandSalesPaymentCurrentPayFile from '../models/product_land_sales_payment_current_pay_file';
import ProductLandSalesOverrideAndStatus from '../models/product_land_sales_override_and_status';

const apiEndpoint = '/product/land/Override';

export async function getProductLandSalesFinancialPeriodList() {
  const response = await http.get<ProductLandSalesFinancialPeriod[]>(apiEndpoint + '/financialperiods');
  return response;
}

export async function getProductLandSalesOverrideAndStatus(periodId) {
  const response = await http.get<ProductLandSalesOverrideAndStatus>(apiEndpoint + '/getLandOverrideAndStatus/' + periodId);
  return response;
}

export async function getSupplierStatus(params) {
  const response = await http.get<ProductLandSalesPaymentMiniStatus>(apiEndpoint + '/supplierStatus', {
    params: {...params}
});
  return response;
}

export async function getProductLandSalesPaymentBreakDownList(params) {
  const response = await http.get<ProductLandSalesPaymentBreakDown[]>(apiEndpoint + '/landpaymentbreakdown', {
    params: {...params}
});
  return response;
}

export async function update(data) {
  await http.put(apiEndpoint + '/saveeditedlandpmtamount', data );
}

export async function submitApproval(data, userId) {
  await http.put(apiEndpoint + '/savelandpaymentfiguresforapproval/' + userId , data);
}

export async function markAsPaid(data, userId) {
  await http.put(apiEndpoint + '/markaspaid/' + userId , data);
}

export async function cancelSubmission(params) {
  await http.post(apiEndpoint + '/cancellandpaymentfiguresforapproval', params);
}

export async function getLandOverrideQuarterPeriods(periodId) {
  const response = await http.get<ProductLandSalesPaymentMiniPeriod>(apiEndpoint + '/getLandOverridePeriod/' + periodId);
  return response;
}

export async function getProductLandSalesPaymentCurrentBreakDownList(params) {
  const response = await http.get<ProductLandSalesPaymentCurrentBreakDown>(apiEndpoint + '/currentquarterpaymentbreakdown', {
    params: {...params}
  });
  return response;
}

export async function includeExcludeAgentCurrentQuarter(params) {
  const response = await http.put(apiEndpoint + '/includeexcludeagentcurrentquarter', params);
  return response;
}

export async function getProductLandSalesPaymentPayFileList(params) {
  const response = await http.get<ProductLandSalesPaymentCurrentPayFile>(apiEndpoint + '/currentquarterpayfile', {
    params: {...params}
  });
  return response;
}

export async function processPayFile(params) {
  const response = await http.get(apiEndpoint + '/processcurrentquarterpayment', {
    params: {...params}
  });
  return response;
}

export async function getProductLandSalesPayBatchHistoryList(params) {
  const response = await http.get<ProductLandSalesPaymentCurrentPayFile>(apiEndpoint + '/currentquarterpaybatchhistory', {
    params: {...params}
  });
  return response;
}

export async function getProductLandSalesPayBreakdownHistoryList(params) {
  const response = await http.get<ProductLandSalesPaymentCurrentPayFile>(apiEndpoint + '/quarterpaymentbreakdownhistory', {
    params: {...params}
  });
  return response;
}

export async function getApproveLandPaymentBreakDown(params) {
  const response = await http.get(apiEndpoint + '/approvelandpaymentbreakdown', {
    params: {...params}
  });
  return response;
}

export async function getRequestForLandOverrideApproval(params) {
  const response = await http.get(apiEndpoint + '/requestforlandoverrideapproval', {
    params: {...params}
  });
  return response;
}

export async function intendToPay(params) {
  const response = await http.get(apiEndpoint + '/intenttopay', {
    params: {...params}
  });
  return response;
}

export default {
  getProductLandSalesFinancialPeriodList,
  getProductLandSalesOverrideAndStatus,
  getProductLandSalesPaymentBreakDownList,
  getSupplierStatus,
  update,
  submitApproval,
  cancelSubmission,
  getLandOverrideQuarterPeriods,
  getProductLandSalesPaymentCurrentBreakDownList,
  includeExcludeAgentCurrentQuarter,
  getProductLandSalesPaymentPayFileList,
  processPayFile,
  getProductLandSalesPayBatchHistoryList,
  getProductLandSalesPayBreakdownHistoryList,
  getApproveLandPaymentBreakDown,
  getRequestForLandOverrideApproval,
  intendToPay,
  markAsPaid
  };
  