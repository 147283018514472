import React, { useState } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { format, set, isValid } from 'date-fns';

interface CustomDatePickerProps {
  name: string;
  label: string;
  disabled?: boolean;
  defaultValue?: Date | string | null;
  minDate?: any;
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  name,
  label,
  disabled = false,
  defaultValue = null,
  minDate = null
}) => {
  const { control, formState: { errors } } = useFormContext();
  const [internalError, setInternalError] = useState<boolean>(false); // State to track internal error

  const parseDefaultValue = (defaultValue: Date | string | null): Date | null => {
    if (!defaultValue) return null;
    if (defaultValue instanceof Date) return defaultValue;
    if (typeof defaultValue === 'string' && isValid(new Date(defaultValue))) {
      return new Date(defaultValue);
    }
    return null;
  };

  const formatDateValue = (value: Date | null): string | null => {
    if (!value) return null;
    return format(value, 'dd/MM/yyyy');
  };
  
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <DatePicker
          label={label}
          inputFormat="dd/MM/yyyy"
          minDate={minDate}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder="Select date..."
              error={!!errors[name] || internalError} // Apply error styling from react-hook-form or internal error
              helperText={errors[name] ? errors[name].message : (internalError ? 'Invalid Date' : '')} // Display error message
            />
          )}
          onChange={(date) => {
            if (!date) {
              field.onChange(null);
              setInternalError(false); // Reset internal error state on empty date
            } else if (date instanceof Date && !isNaN(date.getTime())) {
              const dateWithoutTime = set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
              const formattedDate = format(dateWithoutTime, 'yyyy-MM-dd');
              field.onChange(formattedDate);
              setInternalError(false); // Reset internal error state on valid date
            } else {
              setInternalError(true); // Set internal error state on invalid date
            }
          }}
          value={field.value ? new Date(field.value) : null}
          onError={(error) => {
            if (error !== null) {
              field.onChange(null);
              setInternalError(true); // Set internal error state on validation error
            }
          }}
          disableMaskedInput
        />
      )}
    />
  );
};
