export interface product_sales_report_columns {
  supplier_name: boolean;
  product_name: boolean;
  state: boolean;
  agency: boolean;
  network: boolean;
  business_manager: boolean;
  sales_growth: boolean;
  period: boolean;
  eligible_sales: boolean;
  sales_previous_year: boolean;
  sales_growth_in_dollars: boolean;
  sales_growth_in_percentage: boolean;
  reported_on: boolean;
}

export function defaultProductSalesReportColumns(): product_sales_report_columns {
  return { 
    supplier_name: true,
    product_name: true,
    state: true,
    agency: true,
    network: true,
    business_manager: true,
    sales_growth: true,
    period: true,
    eligible_sales: true,
    sales_previous_year: true,
    sales_growth_in_dollars: true,
    sales_growth_in_percentage: true,
    reported_on: true
  }
};
