import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import Reference from 'src/ems/models/reference';
import ContactService from 'src/ems/services/contact.service';

interface LandProductManagerState {
  landProductManagers: Reference[];
}

const initialState: LandProductManagerState = {
  landProductManagers: []
};

const slice = createSlice({
  name: 'landProductManager',
  initialState,
  reducers: {
    getLandProductManagers(
      state: LandProductManagerState,
      action: PayloadAction<{ landProductManagers: Reference[] }>
    ) {
      const { landProductManagers } = action.payload;

      state.landProductManagers = landProductManagers;
    }
  }
});

export const reducer = slice.reducer;

export const getLandProductManagers =
  (signal?: any): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      let { data } = await ContactService.getLandProductManagers(signal);
      const landProductManagers = Array.isArray(data) ? data : [];
      dispatch(slice.actions.getLandProductManagers({ landProductManagers }));
    } catch (error) {
      if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
        console.error(error);
      }
    }
  };

export default slice;
