import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import AirlineDetails from '../models/airline_details';
import AgentPrivateFaresService from 'src/ems/services/agent.privatefares.service';

interface AirlinesState {
  airlines: AirlineDetails[];
}

const initialState: AirlinesState = {
  airlines: []
};

const slice = createSlice({
  name: 'airlines',
  initialState,
  reducers: {
    getAirlinesDetails(
      state: AirlinesState,
      action: PayloadAction<{ airlines: AirlineDetails[] }>
    ) {
      const { airlines } = action.payload;

      state.airlines = airlines;
    }
  }
});

export const reducer = slice.reducer;

export const getAirlinesDetails =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    let { data } = await AgentPrivateFaresService.getAirlines();

    const airlines = Array.isArray(data) ? data : [];

    dispatch(slice.actions.getAirlinesDetails({ airlines }));
  };

export default slice;
