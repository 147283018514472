import { DateFilter } from "src/ems/enums/date.filter.enums"

export interface ReportFilterPanelProps {
    agentPanel?: AgentPanelProps,
    optionPanel?: OptionPanelProps,
    datePanel?: DatePanelProps
}

export interface OptionPanelProps {
    businessManager?: DropdownFieldProps,
    salesType?: DropdownFieldProps,
    ticketApplication?: DropdownFieldProps,
    departureOrigin?: DropdownFieldProps,
    airline?: DropdownFieldProps,
    destinationAirport?: DropdownFieldProps,
    originAirport?: DropdownFieldProps,
    destinationRegion?: DropdownFieldProps,
    destinationCountry?: DropdownFieldProps,
    originCountry?: DropdownFieldProps,
    journeyType?: DropdownFieldProps,
    travelClass?: DropdownFieldProps,
    paxType?: DropdownFieldProps,
    travelType?: DropdownFieldProps,
    revenueType?: DropdownFieldProps,
    yoyType?: DropdownFieldProps,
    includeMixedCarriers?: ToggleFieldProps,
    includeEDMandWholeSaleTickets?: ToggleFieldProps,
    includeReissueTickets?: ToggleFieldProps,
    includePax?: ToggleFieldProps,
    includeShowYoy?: ToggleFieldProps,
    includeShowWow?: ToggleFieldProps,
    includePaymentReport?: ToggleFieldProps,
    includeMargin?: ToggleFieldProps,
    showFareBasis?: ToggleFieldProps
}

export interface AgentPanelProps {
    network?: DropdownFieldProps,
    subGroup?: DropdownFieldProps,
    headerGroup?: DropdownFieldProps,
    agent?: DropdownFieldProps,
    state?: DropdownFieldProps,
    status?: DropdownFieldProps
}

export interface DatePanelProps {
    dateFrom?: DateFieldProps,
    dateTo?: DateFieldProps,
    dateFilter?: DateFilterFieldProps
}

export interface DatePanel {
    network?: DropdownFieldProps,
    agent?: DropdownFieldProps,
    state?: DropdownFieldProps,
    status?: DropdownFieldProps
}

export interface DropdownFieldProps {
    showField?: boolean,
    isAddAllOptions?: boolean,
    defaultValues?: number[],
    defaultValue?: number
}

export interface ToggleFieldProps {
    showField?: boolean,
    defaultValue?: boolean
}

export interface DateFieldProps {
    showField?: boolean,
    defaultValue?: Date,
    label: string,
    name: string
}

export interface DateFilterFieldProps {
    showField?: boolean,
    defaultValue?: DateFilter
}


const defaultDropdownField: DropdownFieldProps = {
    showField: false,
    isAddAllOptions: false,
    defaultValue: null,
    defaultValues: null
}


const defaultToggleField: ToggleFieldProps = {
    showField: false,
    defaultValue: false
};

// Default for DateFieldProps
const defaultDateField: DateFieldProps = {
    showField: false,
    defaultValue: new Date(),
    label: 'Date',
    name: 'date'  
};

const defaultDateFilterField: DateFilterFieldProps = {
    showField: false,
    defaultValue: DateFilter.CustomRange,  
};

// Default for ReportFilterPanelProps
export function defaultReportFilterPanelProps(): ReportFilterPanelProps {
    return {
        agentPanel: {
            network: { ...defaultDropdownField },
            subGroup: { ...defaultDropdownField },
            headerGroup: { ...defaultDropdownField },
            agent: { ...defaultDropdownField },
            state: { ...defaultDropdownField },
            status: { ...defaultDropdownField }
        },
        optionPanel: {
            businessManager: { ...defaultDropdownField },
            salesType: { ...defaultDropdownField },
            ticketApplication: { ...defaultDropdownField },
            departureOrigin: { ...defaultDropdownField },
            airline: { ...defaultDropdownField },
            destinationAirport: { ...defaultDropdownField },
            originAirport: { ...defaultDropdownField },
            destinationRegion: { ...defaultDropdownField },
            destinationCountry: { ...defaultDropdownField },
            originCountry: { ...defaultDropdownField },
            journeyType: { ...defaultDropdownField },
            travelClass: { ...defaultDropdownField },
            revenueType: { ...defaultDropdownField },
            yoyType: { ...defaultDropdownField },
            paxType: { ...defaultDropdownField },
            travelType: { ...defaultDropdownField },
            includeMixedCarriers: { ...defaultToggleField },
            includeEDMandWholeSaleTickets: { ...defaultToggleField },
            includeReissueTickets: { ...defaultToggleField },
            includePax: { ...defaultToggleField },
            includeShowYoy: { ...defaultToggleField },
            includeShowWow: { ...defaultToggleField },
            includePaymentReport: { ...defaultToggleField },
            includeMargin: { ...defaultToggleField },
            showFareBasis: { ...defaultToggleField }
        },
        datePanel: {
            dateFrom: { ...defaultDateField },
            dateTo: { ...defaultDateField },
            dateFilter: { ...defaultDateFilterField }
        }
    };
}