import SupplierDetails from 'src/ems/models/supplier_models/supplier_details';
import EntitySummary from 'src/ems/models/entity_summary';
import http from './http.service';
import ContractYear from 'src/ems/models/contract_year';
import { SupplierProducts } from 'src/ems/models/supplier_models/supplier_products';
import ProductLandSalesContactPeriod from 'src/ems/models/product_land_sales_contract_period';
import MiniFlag from 'src/ems/models/mini_flag';
import ProductLandSalesFileImportData from 'src/ems/models/product_land_sales_file_import_data';
import SupplierContactDetails from 'src/ems/models/supplier_models/supplier_contact_details';

const apiEndpoint = '/supplier';

export async function create(data: SupplierDetails) {
  await http.post(apiEndpoint + '/create', data);
}

export async function update(data: SupplierDetails) {
  await http.post(apiEndpoint + '/update', data);
}

export async function getContactLinkList(entityId: number, signal?: any) {
  const response = await http.get<SupplierContactDetails[]>(
    apiEndpoint + '/contactlinklist/' + entityId,
    {
      signal: signal
    }
  );
  return response;
}

export async function getSuppliers(signal?: any) {
  const response = await http.get<EntitySummary[]>(apiEndpoint + '/list', {
    signal: signal
  });
  return response;
}

export async function getSupplierDetail(entityId: string) {
  const response = await http.get<SupplierDetails[]>(
    apiEndpoint + '/detail/' + entityId
  );
  return response;
}

export async function getContractPeriodForSupplier(entityId: number) {
  const response = await http.get<ContractYear[]>(
    apiEndpoint + '/contractperiod/' + entityId
  );
  return response;
}

export async function getContractPeriod(signal?: any) {
  const response = await http.get<ProductLandSalesContactPeriod[]>(
    apiEndpoint + '/contractperiod',
    {
      signal: signal
    }
  );
  return response;
}

export async function getSupplierProducts(entityId: number) {
  const response = await http.get<SupplierProducts[]>(
    apiEndpoint + '/supplierproducts/' + entityId
  );
  return response;
}

export async function getDropDownListSupplierProducts(signal?: any) {
  const response = await http.get<SupplierProducts[]>(
    apiEndpoint + '/supplierproducts',
    {
      signal: signal
    }
  );
  return response;
}

export async function getIncludeExclude(
  entityId: number,
  productId: number,
  periodId: number
) {
  const response = await http.get<MiniFlag[]>(
    '/product/land/data/inlcudeexclude/' +
      entityId +
      '/' +
      productId +
      '/' +
      periodId
  );
  return response;
}

export async function getReporting(
  entityId: number,
  productId: number,
  periodId: number
) {
  const response = await http.get<string>(
    '/product/land/data/salesreporting/' +
      entityId +
      '/' +
      productId +
      '/' +
      periodId
  );
  return response;
}

export async function loadDataFile(
  periodId: number,
  productId: number,
  dateFrom: Date,
  dateTo: Date,
  fileImportData: ProductLandSalesFileImportData[],
  fileName: string,
  isAdjustment: boolean,
  userId,
  salesPeriod: number,
  entityId: number
) {
  let params = {
    contractPeriodId: periodId?.toString(),
    contractProductId: productId?.toString(),
    dateFrom: dateFrom,
    dateTo: dateTo,
    fileImportData: fileImportData,
    fileName: fileName,
    IsAdjustmentFlag: isAdjustment,
    contactId: userId,
    salesPeriodId: salesPeriod?.toString(),
    supplierId: entityId?.toString()
  };
  const response = await http.post<string>(
    '/product/land/data/loaddatafile',
    params
  );
  return response;
}

export const initialiseSupplier = (isCreate?): SupplierDetails => {
  const supplierDetails: SupplierDetails = {
    entityId: 0,
    name: '',
    entityTypeId: 2,
    relatedName: '',
    shortName: '',
    addressOfficeAddress: {
      line1: '',
      suburb: '',
      postcode: '',
      referenceCountryId: '21',
      referenceStateId: 13,
      telephone: '',
      mobile: '',
      email: ''
    },
    referenceEntityClientTypeId: '66',
    referenceStatusId: '1',
    supplierCode: '',
    isCreate
  };

  return supplierDetails;
};

export default {
  create,
  getContactLinkList,
  getContractPeriod,
  getContractPeriodForSupplier,
  getDropDownListSupplierProducts,
  getIncludeExclude,
  getReporting,
  getSuppliers,
  getSupplierDetail,
  getSupplierProducts,
  initialiseSupplier,
  loadDataFile,
  update
};
