import {
  defaultProductFilterPanelProps
} from 'src/ems/components/common/ProductFilterPanel/Models/ProductFilterPanelProps';
import { ProductFilterPanelProps } from 'src/ems/components/common/ProductFilterPanel/Models/ProductFilterPanelProps';
import { land_overrides_report_columns } from 'src/ems/models/agent_report_models/land_overrides_report_columns';
import land_override_parameters from 'src/ems/models/report_models/land_override_parameters';
import { convertArray } from 'src/ems/services/report.general.service';

export function getReportFilterPanel(defaultValues: land_override_parameters): ProductFilterPanelProps {
let preferenceData: land_override_parameters = {};
//const defaultValues: private_fare_parameters = await getPreference(role.RPT_PRIVATE_FARES, isDefault);

if (defaultValues !== null) {
    preferenceData = defaultValues;
}

console.log(preferenceData)
const reportFilterPanel: ProductFilterPanelProps = defaultProductFilterPanelProps();

  reportFilterPanel.productPanel.agent = { ...reportFilterPanel.productPanel.agent, showField: true, isAddAllOptions: true, defaultValues: convertArray(preferenceData.agent) }
  reportFilterPanel.productPanel.financialYear = { ...reportFilterPanel.productPanel.financialYear, showField: true, isAddAllOptions: true, defaultValues: convertArray(preferenceData.financialYear) }

  return reportFilterPanel;
}

export function getReportMeasures(columnPrefrence: land_overrides_report_columns) {
  let measures = [];

  measures.push({
      uniqueName: "agencyName",
      caption: `Agent Name`,
      active: columnPrefrence.agencyName
  });

  measures.push({
      uniqueName: "financialYear",
      caption: `Financial Year`,
      active: columnPrefrence.financialYear
  });

  measures.push({
      uniqueName: "supplierName",
      caption: `Supplier`,
      active: columnPrefrence.supplierName
  });

  measures.push({
    uniqueName: "incentivePeriod",
    caption: `Incentive Period`,
    active: columnPrefrence.incentivePeriod
});

  measures.push({
      uniqueName: "totalPay",
      format: "salesFormat",
      caption: `Elegible Sales`,
      active: columnPrefrence.totalPay
  });

  measures.push({
      uniqueName: "tierValue",
      caption: `Tier Earned`,
      active: columnPrefrence.tierValue
  });

  measures.push({
      uniqueName: "paymentPercent",
      caption: `Tier % Earned`,
      active: columnPrefrence.paymentPercent
  });

  measures.push({
      uniqueName: "paymentAmount",
      caption: `Tier $ Earned`,
      active: columnPrefrence.paymentAmount
  });

  measures.push({
      uniqueName: "paidOnFlagDescription",
      caption: `Paid On`,
      active: columnPrefrence.paidOnFlagDescription
  });

  measures.push({
      uniqueName: "tranParticulars",
      caption: `Description`,
      active: columnPrefrence.tranParticulars
  });

  return measures
}
