import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import ProductLandSalesService from 'src/ems/services/product.landfilestatus.service';
import ProductLandSalesContactPeriod from 'src/ems/models/product_land_sales_contract_period';

interface ProductLandSalesContactPeriodState {
  productLandSalesContactPeriod: ProductLandSalesContactPeriod[];
}

const initialState: ProductLandSalesContactPeriodState = {
  productLandSalesContactPeriod: []
};

const slice = createSlice({
  name: 'productLandSalesContractPeriod',
  initialState,
  reducers: {
    getProductLandSalesContractPeriod(
      state: ProductLandSalesContactPeriodState,
      action: PayloadAction<{
        productLandSalesContactPeriod: ProductLandSalesContactPeriod[];
      }>
    ) {
      const { productLandSalesContactPeriod } = action.payload;

      state.productLandSalesContactPeriod = productLandSalesContactPeriod;
    }
  }
});

export const reducer = slice.reducer;

export const getProductLandSalesContractPeriod =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    let { data } =
      await ProductLandSalesService.getProductLandSalesContractPeriodList();
    const productLandSalesContactPeriod = Array.isArray(data) ? data : [];
    dispatch(
      slice.actions.getProductLandSalesContractPeriod({
        productLandSalesContactPeriod
      })
    );
  };

export default slice;
