import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import ContactRefRole from 'src/ems/models/contact_models/contact_refrole';
import ContactRoleService from 'src/ems/services/contact.role.service';

interface RoleState {
  roles: ContactRefRole[];
}

const initialState: RoleState = {
  roles: []
};

const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    getRoles(
      state: RoleState,
      action: PayloadAction<{ roles: ContactRefRole[] }>
    ) {
      const { roles } = action.payload;

      state.roles = roles;
    }
  }
});

export const reducer = slice.reducer;

export const getRoles =
  (signal?: any): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      let { data } = await ContactRoleService.getRoles(signal);
      const roles = Array.isArray(data) ? data : [];
      dispatch(slice.actions.getRoles({ roles }));
    } catch (error) {
      if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
        console.error(error);
      }
    }
  };

export default slice;
