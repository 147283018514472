import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import ProductLandSalesService from 'src/ems/services/product.landfilestatus.service';
import ProductLandSalesFileStatus from 'src/ems/models/product_land_sales_file_status';

interface ProductLandSalesFileStatusState {
  productLandSalesFileStatus: ProductLandSalesFileStatus[];
}

const initialState: ProductLandSalesFileStatusState = {
  productLandSalesFileStatus: []
};

const slice = createSlice({
  name: 'productLandSalesFileStatus',
  initialState,
  reducers: {
    getProductLandSalesFileStatus(
      state: ProductLandSalesFileStatusState,
      action: PayloadAction<{
        productLandSalesFileStatus: ProductLandSalesFileStatus[];
      }>
    ) {
      const { productLandSalesFileStatus } = action.payload;

      state.productLandSalesFileStatus = productLandSalesFileStatus;
    }
  }
});

export const reducer = slice.reducer;

export const getProductLandSalesFileStatus =
  (periodId: number): AppThunk =>
  async (dispatch): Promise<void> => {
    let { data } =
      await ProductLandSalesService.getProductLandSalesFileStatusList(periodId);
    const productLandSalesFileStatus = Array.isArray(data) ? data : [];
    dispatch(
      slice.actions.getProductLandSalesFileStatus({
        productLandSalesFileStatus
      })
    );
  };

export default slice;
