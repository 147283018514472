import http from './http.service';
import ProductLandSalesContactPeriod from 'src/ems/models/product_land_sales_contract_period';
import ProductLandSalesFileStatus from 'src/ems/models/product_land_sales_file_status';

const apiEndpoint = '/product/land/filestatus';

export async function getProductLandSalesContractPeriodList() {
  const response = await http.get<ProductLandSalesContactPeriod[]>(apiEndpoint + '/calendarPeriods');
  return response;
}

export async function getProductLandSalesFileStatusList(periodId) {
    const response = await http.get<ProductLandSalesFileStatus[]>(apiEndpoint + '/updatefilestatus/' + periodId);
    return response;
}

export async function update(data: ProductLandSalesFileStatus,) {
    await http.post(apiEndpoint + '/setfilestatus', data );
  }

export function difference(obj1, obj2){
  const keys = Object.keys(obj1);
  let propName = "";
  const payload = {};

  keys.forEach((el) => {
    const first = obj1[el];
    const second = obj2[el];

    let check;
    if (first !== second) {
      if (first instanceof Object && !(first instanceof Array))
        check = difference(first, second);
      payload[el] = check || second;
      propName = el;
    }      
  });

  return getMonthName(propName, payload);

};

export function getMonthName(propName, payload={}){
  const values = Object.values(payload);
  if(propName === "jan"){
    return "January " + (values.length > 0 ? values[0].valueOf() : "");
  }else if(propName === "feb"){
    return "February " + (values.length > 0 ? values[0].valueOf() : "");
  }else if(propName === "mar"){
    return "March " + (values.length > 0 ? values[0].valueOf() : "");
  }else if(propName === "apr"){
    return "April " + (values.length > 0 ? values[0].valueOf() : "");
  }else if(propName === "may"){
    return "May " + (values.length > 0 ? values[0].valueOf() : "");
  }else if(propName === "jun"){
    return "June " + (values.length > 0 ? values[0].valueOf() : "");
  }else if(propName === "jul"){
    return "July " + (values.length > 0 ? values[0].valueOf() : "");
  }else if(propName === "aug"){
    return "August " + (values.length > 0 ? values[0].valueOf() : "");
  }else if(propName === "sep"){
    return "September " + (values.length > 0 ? values[0].valueOf() : "");
  }else if(propName === "oct"){
    return "October " + (values.length > 0 ? values[0].valueOf() : "");
  }else if(propName === "nov"){
    return "November " + (values.length > 0 ? values[0].valueOf() : "");
  }else if(propName === "dec"){
    return "December " + (values.length > 0 ? values[0].valueOf() : "");
  }
}

export default {
    getProductLandSalesContractPeriodList,
    getProductLandSalesFileStatusList,
    update,
    difference,
    getMonthName
  };
  