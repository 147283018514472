export enum StatusEnum {
  ACTIVE = 1,
  NOT_ACTIVE = 42,
  SUSPENDED = 43,
  NEW_LEAD = 450
}

export enum CountryEnum {
  AUSTRALIA = 21,
  NEW_ZEALAND = 22
}

export enum StateEnum {
  VIC = 13,
  NSW = 14,
  SA = 15,
  WA = 16,
  TAS = 17,
  NT = 18,
  QLD = 112,
  ACT = 228
}

export enum NetworkEnum {
  ALATUS = 680,
  ITA = 650,
  ITAC = 652,
  ITG = 125,
  ITGA = 69,
  ITT = 210,
  STG = 126,
  STGA = 68,
  WTS = 417
}

export enum JobTypeEnum {
  CONSULTANT = 419,
  MANAGER = 421,
  OWNER = 446
}

export enum SupplierTypeEnum {
  AIRLINE = 66,
  LAND = 67
}

export enum UserPaysTypeEnum {
  QuoteCloud = 776,
  eRoam = 777,
  Smarttrips = 778,
  SmarttripsAlatus = 779,
  AFTAATAS = 2681,
  CLIA = 2682,
  Virtuoso = 2683,
  Emplosure = 2684
}

export enum AuthTypeEnum {
  NO_AUTH = 2678,
  AUTHENTICATOR = 2679,
  EMAIL = 2680
}

export enum SystemExtendedReportTypeEnum {
  AIRLINE_ACCREDITATION = 1,
  PCC = 2,
  NDC = 3
}

export enum DocumentTypeReferenceId {
  AIRLINE_PRIVATE_FARE = 2685
}

export enum SupplierStatus {
  INCOMPLETE = 1,
  UPLOADED = 2,
  SUBMITTEDFORAPPROVAL = 3,
  PAYABLE = 4,
  PARTIALLYSUBMITTED = 5,
  INTENDTOPAY = 6,
  PAID = 7,
  TO_BE_ADJUSTED = 8,
  SUBMITTED_FOR_ADJUSTMENT_APPROVAL = 9,
  ADJUSTMENT_PAYABLE = 10,
  ADJUSTMENT_PARTIALLY_SUBMITTED = 11,
  INTEND_TO_PAY_ADJUSTMENT = 12,
  PAID_ADJUSTMENT = 13
}

export enum QuarterPeriod {
  FirstQuarter = 1,
  SecondQuarter = 2,
  ThirdQuarter = 3,
  FourthQuarter = 4
}

export enum SystemType {
  EMS = 92
}