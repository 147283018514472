import { contact_column_preference } from "../models/contact_preference_models/contact_column_preference";
import { contact_preference } from "../models/contact_preference_models/contact_preference";
import http from 'src/ems/services/http.service';

const apiEndpoint = '/contactpreference';

export async function savePreference(roleId: number, preferenceTypeId: number, parameters: any, isDefault: boolean) {
  const body: contact_preference = {
    preferenceTypeId: preferenceTypeId,
    roleId: roleId,
    parameters: parameters,
    isDefault: isDefault
  }
  const response = await http.post(apiEndpoint + '/saveFilter', body);
  return response.data
}

export async function getPreference(roleId: number, isDefault: boolean) {
  const body = {
    roleId: roleId,
    isDefault: isDefault
  }

  const response = await http.post(apiEndpoint + '/getPreference', body);
  return response.data
}


export async function saveColumnPreference(roleId: number, preferenceTypeId: number, columns: any, isDefault: boolean) {
  const body: contact_column_preference = {
    preferenceTypeId: preferenceTypeId,
    roleId: roleId,
    columns: columns,
    isDefault: isDefault
  }
  const response = await http.post(apiEndpoint + '/saveColumnFilter', body);
  return response.data
}

export async function saveScheduler(data: any) {
  const response = await http.post(apiEndpoint + '/saveScheduler', data);
  return response.data
}

export async function getColumnPreference(roleId: number, isDefault: boolean) {
  const body = {
    roleId: roleId,
    isDefault: isDefault
  }

  const response = await http.post(apiEndpoint + '/getColumnPreference', body);
  return response.data
}

export default {
  savePreference,
  getPreference,
  saveColumnPreference,
  saveScheduler
};
