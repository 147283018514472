import {
  Avatar,
  Box,
  Tooltip,
  Badge,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useScreenSize from 'src/ems/utils/useScreenSize';
import { getCurrentUser } from 'src/ems/services/auth.service';
import { useEffect, useState } from 'react';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 53px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function Logo() {
  const { t }: { t: any } = useTranslation();
  const [logoName, setLogoName] = useState<string>('hlo');
  //let logoName = 'hlo';
  const screenSize = useScreenSize();

  useEffect(() => {
    let userInfo = getCurrentUser();

    if (userInfo && typeof userInfo.primaryGroupSid === 'string') {
      setLogoName(userInfo.primaryGroupSid);
    }
    else {
      setLogoName('hlo');
    }
  }, []);

  return (
    /*     <TooltipWrapper
      title={t('Tokyo White React Typescript Admin Dashboard')}
      arrow
    > */
    <LogoWrapper
      style={{ justifyContent: screenSize.width < 1025 ? 'left' : 'center' }}
      to=""
    >
      <Avatar
        variant="square"
        sx={{ width: 180, height: 85 }}
        alt="EMS Logo"
        src={`/static/images/avatars/${logoName}.png`}
      />
    </LogoWrapper>
    /* </TooltipWrapper> */
  );
}

export default Logo;
