import http_report from './http.report.service';
import httpService from './http.service';
import { getCurrentUser } from './auth.service';
import report_parameters from '../models/report_models/report_parameters';
import CommonService from './common.service';

const apiEndpoint = '/report';

const GetStringPercentageFormulaNegatives = (currentField, pastField) =>
  `((sum('${currentField}')-sum('${pastField}'))/abs(sum('${pastField}'))) * 100`;

const GetStringPercentageFormulaPositiveValues = (currentField, pastField) =>
  `((sum('${currentField}')-sum('${pastField}'))/sum('${pastField}')) * 100`;

const GetStringPercentageFormulaNormalValues = (currentField, pastField) =>
  `if(sum('${currentField}') < 0 OR sum('${pastField}') < 0, ${GetStringPercentageFormulaNegatives(
    currentField,
    pastField
  )}, ${GetStringPercentageFormulaPositiveValues(currentField, pastField)})`;

export const getStringPercentageFormula = (currentField, pastField) =>
  `if(sum('${currentField}') - sum('${pastField}') == 0, 0, ${GetStringPercentageFormulaNormalValues(
    currentField,
    pastField
  )})`;

export function getReportParameters(data: any): report_parameters {
  let parameters: report_parameters = {};

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];

      if (
        Array.isArray(value) &&
        value.every((item) => item && typeof item.value === 'number')
      ) {
        parameters[key] = value.some((item) => item.value === 0)
          ? null
          : value.map((item: any) => item.value.toString());
      } else if (typeof value === 'number') {
        parameters[key] = value === 0 ? null : value;
      } else if (
        typeof value === 'string' &&
        CommonService.isDateString(value)
      ) {
        // If the value is a date string, extract only the date part.
        let date = new Date(value);
        parameters[key] = CommonService.formatDate(date);
      } else {
        parameters[key] = value;
      }
    }
  }

  parameters.userInfo = getCurrentUser();

  return parameters;
}

export async function getPccListReport(data: report_parameters) {
  const response = await httpService.post(apiEndpoint + '/PccListReport', data);

  return response.data;
}

export async function getAllFieldChangesForEntity(data: report_parameters) {
  const response = await httpService.post(
    apiEndpoint + '/FieldChangesForEntity',
    data
  );

  return response.data;
}

export function convertArray(arr) {
  if (arr === null || arr === undefined) { return null }
  var convertedArr = [];
  for (var i = 0; i < arr.length; i++) {
      convertedArr.push(parseInt(arr[i], 10)); // Using parseInt
      // convertedArr.push(+arr[i]); // Using the unary plus operator
  }
  
  return convertedArr;
}

export function applyCommonProperties(panel: any, property: string, defaultValues?: any) {
  panel[property] = {
    ...panel[property],
    showField: true,
    isAddAllOptions: true,
    ...(defaultValues !== null && { defaultValues: convertArray(defaultValues) })
  };
}

export function downloadExcelFile(blob: any, filename: string) {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename + '.xlsx'); // Specify the file name
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}

export default {
  getAllFieldChangesForEntity,
  getPccListReport,
  getReportParameters,
  convertArray,
  applyCommonProperties
};
