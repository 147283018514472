import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import Reference from 'src/ems/models/reference';
import ReferenceService from 'src/ems/services/reference.service';

interface ReferenceState {
  references: Reference[];
}

const initialState: ReferenceState = {
  references: []
};

const slice = createSlice({
  name: 'reference',
  initialState,
  reducers: {
    getReferences(
      state: ReferenceState,
      action: PayloadAction<{ references: Reference[] }>
    ) {
      const { references } = action.payload;

      state.references = references;
    }
  }
});

export const reducer = slice.reducer;

export const getReferences =
  (signal?: any): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      let { data } = await ReferenceService.getReferences(signal);
      const references = Array.isArray(data) ? data : [];
      dispatch(slice.actions.getReferences({ references }));
    } catch (error) {
      if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
        console.error(error);
      }
    }
  };

export default slice;
