import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as projectsBoardReducer } from 'src/slices/projects_board';
import { reducer as mailboxReducer } from 'src/slices/mailbox';
import { reducer as referenceReducer } from 'src/ems/slices/reference';
import { reducer as roleReducer } from 'src/ems/slices/role';
import { reducer as agentReducer } from 'src/ems/slices/agent';
import { reducer as entityReducer } from 'src/ems/slices/entity';
import { reducer as productLandSalesReducer } from 'src/ems/slices/productLandSalesData';
import { reducer as productLandSalesContractPeriodReducer } from 'src/ems/slices/productLandSalesContractPeriod';
import { reducer as productLandSalesFinancialReducer } from 'src/ems/slices/productLandSalesFinancialPeriod';
import { reducer as productLandSalesOverrideReducer } from 'src/ems/slices/productLandSalesOverride';
import { reducer as productLandSalesFileStatusReducer } from 'src/ems/slices/productLandSalesFileStatus';
import { reducer as contactReducer } from 'src/ems/slices/contact';
import { reducer as contactViewReducer } from 'src/ems/slices/contactView';
import { reducer as landContractReducer } from 'src/ems/slices/landContract';
import { reducer as contractSupportReducer } from 'src/ems/slices/contract/setttings/contractSupport';
import { reducer as supplierReducer } from 'src/ems/slices/supplier';
import { reducer as supplierProductReducer } from 'src/ems/slices/supplierProducts';
import { reducer as businessManagerReducer } from 'src/ems/slices/businessManager';
import { reducer as landProductManagerReducer } from 'src/ems/slices/landProductManager';
import { reducer as referenceDropDownReducer } from 'src/ems/slices/referenceDropDown';
import { reducer as airlinesReducer } from 'src/ems/slices/airlines';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  projectsBoard: projectsBoardReducer,
  mailbox: mailboxReducer,
  reference: referenceReducer,
  role: roleReducer,
  agent: agentReducer,
  entity: entityReducer,
  productLandSales: productLandSalesReducer,
  productLandSalesContractPeriod: productLandSalesContractPeriodReducer,
  productLandSalesFinancialPeriod: productLandSalesFinancialReducer,
  productLandSalesOverride: productLandSalesOverrideReducer,
  productLandSalesFileStatus: productLandSalesFileStatusReducer,
  supplier: supplierReducer,
  supplierProducts: supplierProductReducer,
  contact: contactReducer,
  contactView: contactViewReducer,
  landContract: landContractReducer,
  contractSupport: contractSupportReducer,
  businessManager: businessManagerReducer,
  landProductManager: landProductManagerReducer,
  referenceDropDown: referenceDropDownReducer,
  airlines: airlinesReducer
});

export default rootReducer;
