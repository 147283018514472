import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import FormDropDownProps from 'src/ems/models/form_dropdown_props';
import ReferenceService from 'src/ems/services/reference.service';

interface ReferenceDropDownState {
  references: FormDropDownProps[];
}

const initialState: ReferenceDropDownState = {
  references: []
};

const slice = createSlice({
  name: 'referenceDropDown',
  initialState,
  reducers: {
    getDropDownReferences(
      state: ReferenceDropDownState,
      action: PayloadAction<{ references: FormDropDownProps[] }>
    ) {
      const { references } = action.payload;

      state.references = references;
    }
  }
});

export const reducer = slice.reducer;

export const getDropDownReferences =
  (signal?: any): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      let { data } = await ReferenceService.getDropDownReferences(signal);
      const references = Array.isArray(data) ? data : [];
      dispatch(slice.actions.getDropDownReferences({ references }));
    } catch (error) {
      if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
        console.error(error);
      }
    }
  };

export default slice;
