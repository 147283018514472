import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import ProductLandSalesService from 'src/ems/services/product.landoverride.service';
import ProductLandSalesFinancialPeriod from 'src/ems/models/product_land_sales_financial_period';

interface ProductLandSalesFinancialPeriodState {
  productLandSalesFinancialPeriod: ProductLandSalesFinancialPeriod[];
}

const initialState: ProductLandSalesFinancialPeriodState = {
  productLandSalesFinancialPeriod: []
};

const slice = createSlice({
  name: 'productLandSalesFinancialPeriod',
  initialState,
  reducers: {
    getProductLandSalesFinancialPeriod(
      state: ProductLandSalesFinancialPeriodState,
      action: PayloadAction<{
        productLandSalesFinancialPeriod: ProductLandSalesFinancialPeriod[];
      }>
    ) {
      const { productLandSalesFinancialPeriod } = action.payload;

      state.productLandSalesFinancialPeriod = productLandSalesFinancialPeriod;
    }
  }
});

export const reducer = slice.reducer;

export const getProductLandSalesFinancialPeriod =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    let { data } =
      await ProductLandSalesService.getProductLandSalesFinancialPeriodList();
    const productLandSalesFinancialPeriod = Array.isArray(data) ? data : [];
    dispatch(
      slice.actions.getProductLandSalesFinancialPeriod({
        productLandSalesFinancialPeriod
      })
    );
  };

export default slice;
