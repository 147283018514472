import {
  AuthState,
  AuthProviderProps,
  InitializeAction,
  LoginAction,
  LogoutAction
} from 'src/models/auth';

type Action = InitializeAction | LoginAction | LogoutAction;

export const authReducer = (state: AuthState, action: Action): AuthState =>
    handlers[action.type] ? handlers[action.type](state, action) : state;
  
    
const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { auth } = action.payload;

    return {
      ...state,
      isAuthenticated: auth.isAuthenticated,
      authTypeId: auth.authTypeId,
      isInitialized: auth.isInitialized,
      isTwoFactorSetup: auth.isTwoFactorSetup,
      QRCode: auth.QRCode,
      manualCode: auth.manualCode,
      userId: auth.userId,
      user: auth.user,
      username: auth.username,
      isSessionEnded: auth.isSessionEnded
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { auth } = action.payload;
    return {
      ...state,
      isAuthenticated: auth.isAuthenticated,
      authTypeId: auth.authTypeId,
      isInitialized: auth.isInitialized,
      isTwoFactorSetup: auth.isTwoFactorSetup,
      QRCode: auth.QRCode,
      manualCode: auth.manualCode,
      userId: auth.userId,
      user: auth.user,
      username: auth.username,
      isSessionEnded: false
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    isSessionEnded: false,
    user: null
  })
};
