import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import type LandContractSummary from 'src/ems/models/contract_models/land/land_contract_summary';
import LandContractService from 'src/ems/services/contract/land/land.contract.service';

interface LandContractState {
  landContracts: LandContractSummary[];
}

const initialState: LandContractState = {
  landContracts: []
};

const slice = createSlice({
  name: 'landContract',
  initialState,
  reducers: {
    getLandContracts(
      state: LandContractState,
      action: PayloadAction<{ landContracts: LandContractSummary[] }>
    ) {
      const { landContracts } = action.payload;

      state.landContracts = landContracts;
    }
  }
});

export const reducer = slice.reducer;

export const getLandContracts =
  (signal?: any): AppThunk =>
  async (dispatch): Promise<void> => {
    let { data } = await LandContractService.getLandContracts(signal);
    const landContracts = Array.isArray(data) ? data : [];
    dispatch(slice.actions.getLandContracts({ landContracts }));
  };

export default slice;
