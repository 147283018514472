import React, { useState, useRef, useEffect } from 'react';
import ReportFilterPanel from 'src/ems/components/common/ReportFilterPanel';
import ReportTicketingService from 'src/ems/services/report.ticketing.service';
import ReportParameters from 'src/ems/models/report_models/report_parameters';
import * as FlexmonsterReact from 'react-flexmonster';
import 'flexmonster/flexmonster.css';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useSnackbar } from 'notistack';
import { getDashboardFilterPanel, getReportMeasures } from './services/Service';
import { flexmonsterKey } from 'src/config';
import { getAgencyByAirlineAnnualSalesReport } from 'src/ems/services/report.ticketing.service';
import { ReportFilterPanelProps } from 'src/ems/components/common/ReportFilterPanel/Models/ReportFilterPanelProps';
import { CustomFlexmonsterReport } from 'src/ems/components/common/Flexmonster/CustomFlexmonsterReport';
import { DashboardFilterPanelProps } from 'src/ems/components/common/DashboardFilterPanel/Models/DashboardFilterPanelProps';
import DashboardFilterPanel from 'src/ems/components/common/DashboardFilterPanel';
import DashboardParameters from 'src/ems/models/dashboard_models/dashboard_parameters';
import { getDashboardParameters } from 'src/ems/services/dashboard.service';
import { getColumnPreference, getPreference, saveColumnPreference, savePreference, saveScheduler } from 'src/ems/services/contact.preference.service';
import { role } from 'src/ems/enums/roles.enums';
import { ContactPreference } from 'src/ems/enums/contact.preference.enums';
import { defaultProductSalesReportColumns, product_sales_report_columns } from 'src/ems/models/agent_report_models/product_sales_report_columns';
import { scheduleDetails } from 'src/ems/components/common/CustomSchedulerButton/CustomSchedulerButton';
import { downloadExcelFile } from 'src/ems/services/report.general.service';

export const ProductSales: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
  const [isExpandPanel, setIsExpandPanel] = useState<boolean>(true);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [mapping, setMapping] = useState(null);
  const [data, setData] = useState(null);
  const [title] = useState<string>("Product Sales");
  const [reportExportFileName, setReportExportFileName] = useState<string>("Product Sales");
  const [measures, setMeasures] = useState(null);
  const [reportFilterPanelProps, setReportFilterPanelProps] = useState<DashboardFilterPanelProps>(null);
  const [isDefault, setIsDefault] = useState<boolean>(false)
  const [schedulerDetails, setSchedulerDetails] = useState(null)
  const [preferedColumns, setPreferedColumns] = useState<product_sales_report_columns>(defaultProductSalesReportColumns())

  useEffect(() => {
    const fetchData = async () => {
      if (reportFilterPanelProps === null) {
        await getPreference(role.RPT_GENERAL_PRODUCT_SALES, isDefault).then((response) => {
          const reportFilterPanelData = getDashboardFilterPanel(response.parameters);
          setReportFilterPanelProps(reportFilterPanelData);
          if (response.columns !== null) 
          {
            setPreferedColumns(response.columns)
          }
          if (response.scheduler !== null) 
          {
            setSchedulerDetails(response.scheduler)
          }
        });
      }
    };
  
    fetchData();
  }, [reportFilterPanelProps]);

  useEffect(() => {
    const newMeasures = getReportMeasures(preferedColumns)
    setMeasures(newMeasures)
  }, [preferedColumns]);

  const customizeCell = (cell, data) => {
    if (data.measure && data.type !== 'header' && data.measure.uniqueName === "note") {
      cell.text = `<span class="tooltipNote" title="${data.label}">${data.label}</span>`;
    }
  }

  const handleExpansion = (event, value) => {
    setIsExpandPanel(value)
  }

  const handleResetUserPreference = async () => {
    try {
        setIsDefault(true)
        setReportFilterPanelProps(null)
    } catch (error) {
        console.error('Failed to rest preferences:', error);
        enqueueSnackbar('Failed to rest your preferences!', { variant: 'error' });
    }
  };

  const handleSetDefaultPreference = async (data: ReportParameters) => {
    setData(null);
    const parameters: ReportParameters = ReportTicketingService.getReportParameters(data);

    try {
        await savePreference(role.RPT_GENERAL_PRODUCT_SALES, ContactPreference.DASHBOARD_PARAMETERS, parameters, true);
        enqueueSnackbar('Default preferences saved successfully!', { variant: 'success' });
    } catch (error) {
        console.error('Failed to save default preferences:', error);
        enqueueSnackbar('Failed to save your default preferences!', { variant: 'error' });
    }
  };

  const handleSaveUserPreference = async (data: ReportParameters) => {
    setData(null);
    const parameters: ReportParameters = ReportTicketingService.getReportParameters(data);

    try {
        await savePreference(role.RPT_GENERAL_PRODUCT_SALES, ContactPreference.DASHBOARD_PARAMETERS, parameters, false);
        enqueueSnackbar('Preferences saved successfully!', { variant: 'success' });
    } catch (error) {
        console.error('Failed to save preferences:', error);
        enqueueSnackbar('Failed to save your preferences!', { variant: 'error' });
    }
  };

  const handleSaveColumnUserPreference = async (data) => {
    try {
        await saveColumnPreference(role.RPT_GENERAL_PRODUCT_SALES, ContactPreference.DASHBOARD_PARAMETERS, data, false);
        setPreferedColumns(data)
        enqueueSnackbar('Column Preferences saved successfully!', { variant: 'success' });
    } catch (error) {
        console.error('Failed to save column preferences:', error);
        enqueueSnackbar('Failed to save your column preferences!', { variant: 'error' });
    }
  };

  const handleSetScheduler = async (data) => {
    try {
      let body: scheduleDetails = {
        ...data, 
        roleId: role.RPT_GENERAL_PRODUCT_SALES, 
        preferenceTypeId: ContactPreference.DASHBOARD_PARAMETERS
      }

      await saveScheduler(body);
      // setPreferedColumns(data);
      enqueueSnackbar('Scheduler set successfully!', { variant: 'success' });
    } catch (error) {
      console.error('Failed to set scheduler:', error);
      enqueueSnackbar('Failed to set scheduler!', { variant: 'error' });
    }
  };

  const handleDashboardSubmit = (data: DashboardParameters) => {
    setData(null);
    setIsLoadingReport(true);
    setIsExpandPanel(false);

    const parameters: DashboardParameters = getDashboardParameters(data);

    ReportTicketingService.getProductSalesReport(parameters)
      .then((a) => {
        enqueueSnackbar('Report Loaded Successfully!!', {
          variant: 'success'
        });
        setData(a);
        setReportExportFileName(title)
        setIsLoadingReport(false);
        setRefreshKey(prevKey => prevKey + 1);
      })
      .catch((error) => {
        console.log('Error -> ', error);
        enqueueSnackbar(error, {
          variant: 'error'
        });
        setIsLoadingReport(false);
        setIsExpandPanel(true);
      });
  };

  const handleQuickExport = async (data: ReportParameters) => {

    let dateFrom = new Date(data.dateFrom).toLocaleDateString('en-AU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    let dateTo = new Date(data.dateTo).toLocaleDateString('en-AU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

    setData(null);
    setIsLoadingReport(true);
    setIsExpandPanel(false);
    
    const parameters: DashboardParameters = getDashboardParameters(data);
    let reportFileName = title + ' - ' + dateFrom + ' to ' + dateTo;

    //Start downloadReport in the background
    ReportTicketingService.downloadReportEMS(null, parameters, null, null, role.RPT_GENERAL_PRODUCT_SALES)
      .then((downloadResponse) => {
        downloadExcelFile(downloadResponse, reportFileName);
        setIsLoadingReport(false);
        setIsExpandPanel(true);
      })
      .catch((error) => {
        console.error('Failed to download report:', error);
        enqueueSnackbar('Failed to download the report!', { variant: 'error' });
        setIsLoadingReport(false);
        setIsExpandPanel(true);
      });
  };

  return (
    <>
      {reportFilterPanelProps !== null && <div>
        <DashboardFilterPanel
          dashboardPanelTitle='Report Filters'
          onSubmit={handleDashboardSubmit}
          onQuickExport={handleQuickExport}
          reportTitle={title}
          isLoadingReport={isLoadingReport}
          isExpandPanel={isExpandPanel}
          reportFilters={reportFilterPanelProps}
          columnPrefrence={preferedColumns}
          schedulerPreference={schedulerDetails}
          showSetScheduler={false}
          handelExpansion={handleExpansion}
          onSaveUserPreference={handleSaveUserPreference}
          onResetUserPreference={handleResetUserPreference}
          onSetDefaultPreference={handleSetDefaultPreference}
          onSetUserColumnPreference={handleSaveColumnUserPreference}
          onSetScheduler={handleSetScheduler}
        />
      </div>}
      {isLoadingReport ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '0 20px'
          }}
        >
          <LinearProgress sx={{ width: '100%' }} />
        </Box>
      ) : null}
      <div style={{ margin: '15px'}}>
        {data ? (
          <CustomFlexmonsterReport
            title={title} 
            data={data}
            refreshKey={refreshKey}
            measures={measures}
            fileName={reportExportFileName}
            reportType={'flat'}
            customizeCell={customizeCell}
          />
        ) : null}
      </div>
    </>
  );
}
