import EntitySummary from 'src/ems/models/entity_summary';
import http from './http.service';

const apiEndpoint = '/agent';

export async function getEntities() {
  const response = await http.get<EntitySummary[]>(apiEndpoint + '/entitylist');
  return response;
}

export default {
  getEntities
};