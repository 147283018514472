import VersionList from '../models/version_list';
import http from './http.service';

const apiEndpoint = '/admin/version';

export async function getVersionList() {
  const response = await http.get<VersionList[]>(
    apiEndpoint + '/list'
  );

  return response;
}

export async function saveVersionDetail(data) {
  const response = await http.post(
    apiEndpoint + '/save', data
  );

  return response;
}

export async function updateVersionDetail(data) {
  const response = await http.post(
    apiEndpoint + '/update', data
  );

  return response;
}

export async function deleteVersionDetail(id) {
  const response = await http.post(
    apiEndpoint + '/delete/'+id
  );

  return response;
}

export async function getActiveVersionDetail() {
  const response = await http.get<VersionList>(
    apiEndpoint + '/activeversion'
  );

  return response;
}

export default {
  getVersionList
};
