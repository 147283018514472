import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails, Grid, Button } from '@mui/material';
import { PrivateFaresPanel } from './Panels/PrivateFaresPanel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PrivateFaresFilterPanelProps } from './Models/PrivateFaresFilterPanelProps';
import SettingsIcon from '@mui/icons-material/Settings';
import RestoreIcon from '@mui/icons-material/Restore';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { checkUserHasRole } from 'src/ems/services/auth.service';
import { role } from 'src/ems/enums/roles.enums';
import CustomButtonDropdown from '../CustomButtonDropdown';
import CustomSchedulerButton, { scheduleDetails } from '../CustomSchedulerButton/CustomSchedulerButton';

interface ReportOptionsProps {
  onSubmit: (data: any) => void,
  onQuickExport?: (data: any) => void
  handelExpansion?: (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void,
  reportTitle?: string;
  isLoadingReport: boolean,
  isExpandPanel: boolean
  reportFilters: PrivateFaresFilterPanelProps
  isReportType?: boolean,
  columnPrefrence?: any,
  schedulerPreference?: scheduleDetails,
  isShowScheduler?: boolean,
  onSaveUserPreference?: (data: any) => void
  onSetUserColumnPreference?: (data: any) => void
  onResetUserPreference?: () => void
  onSetDefaultPreference?: (data: any) => void
  onSetScheduler?: (data: any) => void
}

export const PrivateFaresFilterPanel: React.FC<ReportOptionsProps> = ({
  onSubmit,
  onQuickExport,
  reportTitle,
  handelExpansion,
  isLoadingReport,
  isExpandPanel,
  reportFilters,
  isReportType = true,
  columnPrefrence = null,
  schedulerPreference= null,
  isShowScheduler= true,
  onSaveUserPreference,
  onSetUserColumnPreference,
  onResetUserPreference,
  onSetDefaultPreference,
  onSetScheduler
}) => {
  const methods = useForm()
  const [isAllowSetDefault] = useState(checkUserHasRole(role.SET_DEFAULT_PREFERENCE))
  const [isAllowSetScheduler] = useState(checkUserHasRole(role.RPT_SET_SCHEDULER) && isShowScheduler)

  const {privateFaresPanel} = reportFilters

  const reportTypeOptions = [
    {
      value: 'classic',
      label: 'Classic',
      tooltip: 'Try Classic if you want to group the report'
    },
    {
      value: 'flat',
      label: 'Flat',
      tooltip: 'Try Flat if you get error : to large dataset'
    }
  ]

  const handleFormSubmit = (data: any) => {
    onSubmit(data);
  };

  const handleQuickExport = () => {
    const data = methods.getValues();
    onQuickExport(data);
  };

  const handleSavePreference = () => {
    const data = methods.getValues();
    onSaveUserPreference(data);
  };

  const handleScheduler = (data) => {
    onSetScheduler(data)
  }

  const handleReset = () => {
    onResetUserPreference();
  };

  const handleSetDefaultFilter = () => {
    const data = methods.getValues();
    onSetDefaultPreference(data);
  };

  const handleSaveDropdown = (dropdownData: { [key: string]: boolean }) => {
    onSetUserColumnPreference(dropdownData)
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleFormSubmit)} style={{ margin: '8px' }}>
          <Accordion sx={{ margin: '10px' }} expanded={isExpandPanel} onChange={(e, expanded) => { handelExpansion(e, expanded); }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h3" gutterBottom>
                {reportTitle}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ margin: 2 }}>
              <Card variant="outlined" style={{ margin: '10px' }}>
                <CardContent>
                <Grid container justifyContent="flex-end" spacing={1} padding={1}>
                    {isAllowSetDefault && <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<AdminPanelSettingsIcon />}
                        onClick={handleSetDefaultFilter}
                      >
                        Set Default Filter
                      </Button>
                    </Grid>}
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<RestoreIcon />}
                        onClick={handleReset}
                      >
                        Reset Filter
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<SettingsIcon />}
                        onClick={handleSavePreference}
                      >
                        Save Filter
                      </Button>
                    </Grid>
                    {columnPrefrence && <Grid item>
                      <CustomButtonDropdown
                        data={columnPrefrence}
                        onSave={handleSaveDropdown}
                        title='Select Report Fields'
                        buttonName='Save Columns'
                      />
                    </Grid>}
                    {isAllowSetScheduler && <Grid item>
                      <CustomSchedulerButton
                        onSave={handleScheduler}
                        title='Select Scheduler'
                        buttonName='Set Scheduler'
                        initialDetails={schedulerPreference}
                        onCancle={handleScheduler}
                      />
                    </Grid>}
                  </Grid>
                  <PrivateFaresPanel 
                    headerGroup={privateFaresPanel.headerGroup}
                    subGroup={privateFaresPanel.subGroup}
                    agent={privateFaresPanel.agent}
                    network={privateFaresPanel.network}
                    airline={privateFaresPanel.airline}
                    fareType={privateFaresPanel.fareType}
                    accountCode={privateFaresPanel.accountCode}
                    tourCode={privateFaresPanel.tourCode}
                  />
                  <div>
                  <Grid container justifyContent="center">
                      <Grid item xs={12} md={12} sm={12} lg={12} justifyContent={"center"} display={"flex"}>
                        {onQuickExport && <Button sx={{ marginRight: 2 }} variant="contained" type="submit" disabled={isLoadingReport} onClick={handleQuickExport}>Quick Export</Button>}
                        <Button sx={{ marginRight: 2 }} variant="contained" type="submit" disabled={isLoadingReport}>View</Button>
                      </Grid>
                    </Grid>
                  </div>                  
                </CardContent>
              </Card>
            </AccordionDetails>
          </Accordion>
        </form>
      </FormProvider>
    </>
  );
};
