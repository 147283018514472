import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Box, styled, Tooltip } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

interface CustomRadioGroupProps<T> {
  name: string;
  label?: string;
  options: { value: T; label: string; icon?: React.ReactElement; tooltip?: string }[];
  orientation?: 'horizontal' | 'vertical';
  size?: 'small' | 'medium';
  defaultValue?: T;
  handleChange?: (...event: any[]) => void;
  sx?: React.CSSProperties;
}

// Style for selected FormControlLabel
const BoldFormControlLabel = styled(FormControlLabel)({
  fontWeight: 'bold',
});

export const CustomRadioGroup: React.FC<CustomRadioGroupProps<any>> = ({
  name,
  label,
  options,
  orientation = 'horizontal',
  size = 'small',
  defaultValue = options[0].value,
  handleChange = null,
  sx = {},
}) => {
  const { control, setValue } = useFormContext();
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedValue(defaultValue);
    setValue(name, defaultValue);
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange }, fieldState }) => (
        <FormControl component="fieldset" sx={sx}>
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup
            row={orientation === 'horizontal'}
            value={value || selectedValue}
            onChange={(event, newValue) => {
              onChange(newValue);
              if (handleChange !== null) {
                handleChange(newValue);
              }
            }}
          >
            {options.map((option) => (
              <Tooltip key={option.value} title={option.tooltip || ''} arrow>
                <BoldFormControlLabel
                  value={option.value.toString()}
                  control={
                    <Radio
                      size={size}
                      icon={option.icon ? <Box marginRight={1}>{option.icon}</Box> : undefined}
                      checkedIcon={option.icon ? <Box marginRight={1}>{option.icon}</Box> : undefined}
                    />
                  }
                  label={option.label}
                />
              </Tooltip>
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};
