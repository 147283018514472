import React, { useState } from 'react';
import * as FlexmonsterReact from 'react-flexmonster';
import 'flexmonster/flexmonster.css';
import { flexmonsterKey } from 'src/config';
import {
  getFlexmonsterConditions,
  getFlexmonsterFormats,
  getFlexmonsterToolbar
} from './FlexmonsterService/FlexmonsterConditions';

interface CustomFlexmonsterReportProps {
  title?: string;
  mapping?: any;
  refreshKey: any;
  measures?: any;
  slice_rows?: any;
  data: any;
  conditions?: string;
  showToolBar?: boolean;
  height?: number;
  reportType?: string;
  showGrandTotal?: boolean;
  showIndividualTotal?: boolean;
  fileName?: string;
  customizeCell?: (cell, data) => void;
}

export const CustomFlexmonsterReport: React.FC<
  CustomFlexmonsterReportProps
> = ({
  title,
  fileName,
  mapping = null,
  refreshKey,
  measures = null,
  slice_rows = null,
  data,
  conditions = null,
  showToolBar = true,
  height = 800,
  reportType = 'classic',
  showGrandTotal = true,
  showIndividualTotal = false,
  customizeCell = null
}) => {
  const [customCondition] = useState(getFlexmonsterConditions(conditions));
  const [customFormats] = useState(getFlexmonsterFormats());

  const customizeToolbar = (toolbar) => {
    getFlexmonsterToolbar(toolbar, title, fileName);
  };

  return (
    <>
      <FlexmonsterReact.Pivot
        licenseKey={flexmonsterKey.flexmonster_key}
        key={refreshKey}
        toolbar={showToolBar}
        height={height}
        customizeCell={customizeCell}
        beforetoolbarcreated={customizeToolbar}
        global={{
          localization: {
            grid: {
              blankMember: ''
            }
          }
        }}
        report={{
          dataSource: {
            type: 'json',
            data: data,
            mapping: mapping
          },
          formats: customFormats,
          conditions: customCondition,
          slice: {
            rows: slice_rows,
            expands: {
              expandAll: true
            },
            measures: measures
          },
          options: {
            expandExecutionTimeout: 10000,
            grid: {
              title: title,
              type: reportType,
              showGrandTotals: showGrandTotal ? 'on' : 'off',
              grandTotalsPosition: 'bottom',
              showTotals: showIndividualTotal ? 'on' : 'off'
            },
            defaultDateType: 'date string',
            showAggregationLabels: false
          }
        }}
      />
    </>
  );
};
